import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { pricingData } from "./pricingData.js";

const SearchOption = ({ onNext, formData, setFormData, onFormDataChange, onValidationChange, attemptedSubmit }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);

    const options = [
        {
            id: "no-search",
            title: "Exclude Preliminary Search",
            description:
                "I don't want to include a preliminary search, and I understand that this increases the risk of receiving a likelihood of confusion rejection during examination.",
        },
        {
            id: "search",
            title: "Include Preliminary Search",
            description:
                "This is our standard filing package. Our firm will conduct a preliminary search for similar trademarks before filing your application to help mitigate a likelihood of confusion rejection during the application examination.",
        },
    ];

    // Find the relevant category
    const applicationPricing = pricingData.find(
        (category) => category.category === "Trademark Applications"
    )?.services;

    // Map options to pricing (match based on service name)
    const optionPricing = {
        search: applicationPricing?.find(service =>
            service.service.includes("Knockout Search"))?.attyFee || "N/A",
        "no-search": applicationPricing?.find(service =>
            service.service.includes("without search"))?.attyFee || "N/A"
    };

    // Function to handle option selection
    const handleOptionSelect = (optionId) => {
        setSelectedOption(optionId);
        setIsFormValid(true);

        // Strip non-numeric characters (like $) before storing the value
        const strippedFee = optionPricing[optionId].replace(/[^0-9.]/g, '');

        // Update formData with the selected attorney fee
        setFormData((prevData) => ({
            ...prevData,
            attyFee: strippedFee
        }));
    };

    // Notify the parent component when validation state changes
    useEffect(() => {
        if(isFormValid !== null) {
            onValidationChange(isFormValid);
        }
    }, [isFormValid, onValidationChange]);

    const WarningModal = ({ isOpen, onCancel, onContinue }) => {
        if (!isOpen) return null;

        return (
            <div className='warning-modal'>
                <div className='warning-modal-content'>
                    <h2>WARNING:</h2>
                    <p>You are 
                    </p>
                </div>
                <div className='warning-modal-buttons'>
                    <button onClick={onContinue} className='warning-modal-button'>Continue</button>
                    <button onClick={onCancel} className='warning-modal-button'>Cancel</button>
                </div>
            </div>
        );
    };

    return (
        <div className="container search-option-container">
            <h3 className="text-center mb-3">Select Your Trademark Filing Option</h3>
            <div className="row justify-content-center d-flex gap-5">
                {options.map((option) => (
                    <div
                        key={option.id}
                        className={`col-md-5 col-12 option-box ${selectedOption === option.id ? "selected" : ""
                            }`}
                        onClick={() => handleOptionSelect(option.id)}
                    >
                        <h5>{option.title}</h5>
                        <p>{option.description}</p>
                        <h6>Attorney Fee: {optionPricing[option.id]}</h6>
                    </div>
                ))}
            </div>
            {/* <div className="text-center mt-3">
                <Button
                    variant="primary"
                    onClick={() => onNext(selectedOption)}
                    disabled={!selectedOption}
                >
                    Next
                </Button>
            </div> */}
        </div>
    );
};

export default SearchOption;
