import React, { useState, useEffect, useMemo, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import UseSelection from "./useBox.js";
import { pricingData } from "./pricingData.js";

// const parseRejectionString = (description) => {
//     // Split into rejection type and class details
//     const [type, ...classDetails] = description.split(/\s\{/); // Split by " {" while preserving the rejection type

//     // Normalize the type
//     const normalizedType = type.trim();

//     // Extract class details
//     const classInfo = classDetails
//         .map((detail) => {
//             const match = detail.match(/^class (\d+):\s(.*)\}$/); // Match "class xxx: details}"
//             return match
//                 ? {
//                     classNumber: match[1].padStart(3, "0"), // Class number with leading zeros
//                     description: match[2].trim(), // Class description
//                 }
//                 : null;
//         })
//         .filter(Boolean); // Remove null entries

//     return { type: normalizedType, classInfo };
// };

const parseRejectionString = (description) => {
    const [type, ...classDetails] = description.split(/\s\{/); // Split by " {" while preserving the rejection type

    const normalizedType = type.trim();

    const classInfo = classDetails
        .map((detail) => {
            const match = detail.match(/^class (\d+):\s(.*?)(\sNEW CLASS)?\}$/); // Match "class xxx: details NEW CLASS}"
            return match
                ? {
                    classNumber: match[1].padStart(3, "0"), // Class number with leading zeros
                    description: match[2].trim(), // Class description
                    isNewClass: Boolean(match[3]), // Boolean flag for new classes
                }
                : null;
        })
        .filter(Boolean);

    return { type: normalizedType, classInfo };
};


const OfficeActionResponseSubModal = ({
    selectedFile,
    selectedDeadline,
    setResponseType,
    onFormSubmit,
    setTotalFee,
    onClose,
    setEmailBody,
    setShowPaymentModal,
    isPaymentSuccessful,
}) => {
    const { summary, description, start_at } = selectedDeadline;
    const [expandedRejectionIndex, setExpandedRejectionIndex] = useState(null);
    const [classBlocks, setClassBlocks] = useState([]);
    const [selectedUse, setSelectedUse] = useState({});
    const [filePreviews, setFilePreviews] = useState([]);
    const filesMap = useRef({});
    const [totalAttyFee, setTotalAttyFee] = useState(0);
    const [totalGovtFee, setTotalGovtFee] = useState(0);
    const [classDescriptions, setClassDescriptions] = useState({});
    const [gsBlocks, setGsBlocks] = useState([]);
    const [selectedResponse, setSelectedResponse] = useState({});
    const [isCollapsed, setIsCollapsed] = useState({});
    const [approvedDescriptions, setApprovedDescriptions] = useState({});

    setResponseType('OAR');

    const parsedRejections = useMemo(() => {
        if (!description) return [];
        console.log('selectedEvent description: ', description);
        // Split the description by "|" to handle multiple rejections
        return description.split("|").map(parseRejectionString);

    }, [description]);

    useEffect(() => {
        console.log("Rendering file previews:", filePreviews);
        console.log("Current classBlocks:", classBlocks);
        console.log('selectedDeadline: ', selectedDeadline);
    }, [filePreviews, classBlocks]);

    useEffect(() => {
        if (isPaymentSuccessful) {
            processFinalUpdates();
        }
    }, [isPaymentSuccessful]);

    // // useEffect for 'audit' rejection
    // useEffect(() => {
    //     const auditRejection = parsedRejections.find((rejection) => rejection.type === "Audit");
    //     console.log("Audit Rejection:", auditRejection);

    //     if (auditRejection) {
    //         const blocks = auditRejection.classInfo.map((info) => ({
    //             id: info.classNumber, // Ensure `id` matches `blockId`
    //             className: info,
    //             url: "",
    //             specimens: [],
    //         }));
    //         console.log("Populating classBlocks for 'audit':", blocks);
    //         setClassBlocks(blocks);
    //     } else {
    //         console.log("No audit rejection found in parsedRejections:", parsedRejections);
    //     }
    // }, [parsedRejections]);

    //useEffect for 'audit' and 'specimen' rejections
    useEffect(() => {
        const auditRejection = parsedRejections.find((rejection) => rejection.type === "Audit");
        const specimenRejection = parsedRejections.find((rejection) => rejection.type === "Specimen Rejection");

        const newBlocks = []; // Initialize an array for new class blocks
        const seenClasses = new Set(); // Track unique class numbers

        // Process Audit Rejections
        if (auditRejection?.classInfo) {
            const auditBlocks = auditRejection.classInfo.filter((info) => {
                if (seenClasses.has(info.classNumber)) {
                    return false; // Skip duplicates
                }
                seenClasses.add(info.classNumber);
                return true; // Add unique class
            }).map((info) => ({
                id: info.classNumber, // Extracted class number
                className: info, // Full class information
                url: "", // Default URL
                specimens: [], // Empty specimens array
            }));
            console.log("Populating classBlocks for 'Audit':", auditBlocks);
            newBlocks.push(...auditBlocks);
        }

        // Process Specimen Rejections
        if (specimenRejection?.classInfo) {
            const specimenBlocks = specimenRejection.classInfo.filter((info) => {
                if (seenClasses.has(info.classNumber)) {
                    return false; // Skip duplicates
                }
                seenClasses.add(info.classNumber);
                return true; // Add unique class
            }).map((info) => ({
                id: info.classNumber, // Extracted class number
                className: info, // Full class information
                url: "", // Default URL
                specimens: [], // Empty specimens array
            }));
            console.log("Populating classBlocks for 'Specimen Rejection':", specimenBlocks);
            newBlocks.push(...specimenBlocks);
        }

        // Update the state with all relevant blocks
        if (newBlocks.length > 0) {
            setClassBlocks(newBlocks);
        } else {
            console.log("No relevant 'Audit' or 'Specimen Rejection' found in parsedRejections:", parsedRejections);
        }
    }, [parsedRejections]);




    useEffect(() => {
        console.log("Updated classBlocks:", classBlocks);
    }, [classBlocks]);

    //useEffect for updating the total fee
    useEffect(() => {
        setTotalFee(totalAttyFee + totalGovtFee); // Pass the calculated total fee to the parent
    }, [totalAttyFee, totalGovtFee]); // Triggered when totalAtty or totalGovt changes


    // // useEffect for 'identification of goods & services' rejection
    // useEffect(() => {
    //     const identificationRejection = parsedRejections.find(
    //         (rejection) =>
    //             rejection.type.toLowerCase() === "identification of goods & services".toLowerCase()
    //     );

    //     console.log("Identification Rejection:", identificationRejection);

    //     if (identificationRejection) {
    //         const blocks = selectedFile.custom_field_values.reduce((result, field) => {
    //             const classMatch = field.field_name.match(/^Class (\d+)$/);
    //             if (classMatch) {
    //                 const index = classMatch[1];
    //                 const descriptionField = selectedFile.custom_field_values.find(
    //                     (descField) => descField.field_name === `Description ${index}`
    //                 );

    //                 const description = descriptionField ? descriptionField.value : ""; // Extract the description safely
    //                 result.push({
    //                     classNumber: field.value.padStart(3, "0"), // Class number with leading zeros
    //                     description, // Class description
    //                     id: descriptionField ? descriptionField.id : "", // Description Field ID
    //                     hasCurlyBrackets: /\{.*?\}/.test(description), // Check for curly brackets in the description
    //                 });
    //             }
    //             return result;
    //         }, []);

    //         console.log("Populating gsBlocks for 'Identification of Goods & Services':", blocks);
    //         setGsBlocks(blocks);

    //         // Initialize approval state for blocks without curly brackets
    //         const initialApprovals = blocks.reduce((acc, block) => {
    //             if (!block.hasCurlyBrackets) {
    //                 acc[block.classNumber] = false; // Not approved by default
    //             }
    //             return acc;
    //         }, {});
    //         setApprovedDescriptions(initialApprovals);
    //     }
    // }, [parsedRejections, selectedFile.custom_field_values]);

    useEffect(() => {
        const identificationRejection = parsedRejections.find(
            (rejection) =>
                rejection.type.toLowerCase() === "identification of goods & services".toLowerCase()
        );

        console.log("Identification Rejection:", identificationRejection);

        if (identificationRejection) {
            const blocks = selectedFile.custom_field_values.reduce((result, field) => {
                const classMatch = field.field_name.match(/^Class (\d+)$/);
                if (classMatch) {
                    const index = classMatch[1];
                    const descriptionField = selectedFile.custom_field_values.find(
                        (descField) => descField.field_name === `Description ${index}`
                    );

                    let description = descriptionField ? descriptionField.value : ""; // Extract the description safely
                    const isNewClass = description.includes("NEW CLASS"); // Check if this class is newly added
                    description = description.replace("NEW CLASS", "").trim(); // Remove "NEW CLASS" from the displayed description

                    result.push({
                        classNumber: field.value.padStart(3, "0"), // Class number with leading zeros
                        description, // Class description without "NEW CLASS"
                        id: descriptionField ? descriptionField.id : "", // Description Field ID
                        isNewClass, // Boolean flag for new classes
                        hasCurlyBrackets: /\{.*?\}/.test(description), // Check for curly brackets in the description
                    });
                }
                return result;
            }, []);

            console.log("Populating gsBlocks for 'Identification of Goods & Services':", blocks);
            setGsBlocks(blocks);

            // Initialize approval state for blocks without curly brackets
            const initialApprovals = blocks.reduce((acc, block) => {
                if (!block.hasCurlyBrackets) {
                    acc[block.classNumber] = false; // Not approved by default
                }
                return acc;
            }, {});
            setApprovedDescriptions(initialApprovals);
        }
    }, [parsedRejections, selectedFile.custom_field_values]);


    useEffect(() => {
        calculateCosts();
    }, [classBlocks]);

    const handleGsDescriptionChange = (classNumber, newValue) => {
        setClassDescriptions((prev) => ({ ...prev, [classNumber]: newValue }));
    };

    const rejectionTypeToPricingMap = {
        "Likelihood of Confusion": "Likelihood of Confusion",
        "Merely Descriptive": "Merely Descriptive",
        "Geographically Descriptive": "Geographically Descriptive",
        "Identification of Goods & Services": "Objection to Description of Goods/Services",
        "Specimen Rejection": "Rejection of Specimen",
        "Objection to Mark Description": "Objection to Mark Description",
        "Primarily a Surname": "Primarily a Surname",
        "Disclaimer": "Disclaimer",
        "Translation": "Translation",
        "Audit": "Audit",

        // Add other mappings as needed
    };


    // const calculateCosts = () => {
    //     let totalAtty = 0;
    //     let totalGovt = 0;

    //     parsedRejections.forEach(({ type }) => {
    //         const mappedType = rejectionTypeToPricingMap[type] || type; // Map the type if it exists
    //         const pricingCategory = pricingData.find(
    //             (category) => category.category === "Trademark Office Action"
    //         );

    //         // Check if the rejection is descriptive and has a selected response
    //         if (type.toLowerCase().includes("descriptive")) {
    //             const selected = selectedResponse[type]?.feeKey; // Get the selected response
    //             if (selected) {
    //                 const responsePricing = pricingCategory?.services.find(
    //                     (service) => service.service.toLowerCase() === selected.toLowerCase()
    //                 );
    //                 if (responsePricing) {
    //                     const attyFee = parseFloat(responsePricing.attyFee.replace("$", "").replace(",", ""));
    //                     const govtFee = parseFloat(responsePricing.govtFee.replace("$", "").replace(",", "")) || 0;

    //                     totalAtty += attyFee;
    //                     totalGovt += govtFee;
    //                 }
    //             }
    //         } else {
    //             // For non-descriptive rejections, calculate fees as before
    //             const pricing = pricingCategory?.services.find(
    //                 (service) => service.service.toLowerCase() === mappedType.toLowerCase()
    //             );

    //             if (pricing) {
    //                 const attyFee = parseFloat(pricing.attyFee.replace("$", "").replace(",", ""));
    //                 const govtFee = parseFloat(pricing.govtFee.replace("$", "").replace(",", "")) || 0;

    //                 totalAtty += attyFee;
    //                 totalGovt += govtFee;
    //             }
    //         }
    //     });

    //     setTotalAttyFee(totalAtty);
    //     setTotalGovtFee(totalGovt);
    // };

    const calculateCosts = () => {
        let totalAtty = 0;
        let totalGovt = 0;
        let hasObjectionToDescription = false; // Track if we have an "Objection to Description of Goods/Services"
    
        parsedRejections.forEach(({ type, classInfo }) => {
            const mappedType = rejectionTypeToPricingMap[type] || type;
            const pricingCategory = pricingData.find(
                (category) => category.category === "Trademark Office Action"
            );
    
            if (!pricingCategory) return;
    
            const pricing = pricingCategory.services.find(
                (service) => service.service.toLowerCase() === mappedType.toLowerCase()
            );
    
            if (pricing) {
                let attyFee = parseFloat(pricing.attyFee.replace("$", "").replace(",", "")) || 0;
                let govtFee = parseFloat(pricing.govtFee.replace("$", "").replace(",", "")) || 0;
    
                if (mappedType === "Objection to Description of Goods/Services") {
                    hasObjectionToDescription = true; // Mark that this type exists
                    (Array.isArray(gsBlocks) ? gsBlocks : []).forEach(block => {
                        if (block.isNewClass) {
                            totalGovt += 350; // Charge $350 per new class
                        }
                    });
                } else {
                    (Array.isArray(classInfo) ? classInfo : []).forEach((info) => {
                        totalAtty += attyFee; // Charge attorney fee per rejection type
    
                        // Retrieve matching class from gsBlocks safely
                        const matchingClass = (Array.isArray(gsBlocks) ? gsBlocks : []).find(
                            block => block.classNumber === info.classNumber
                        );
    
                        if (mappedType === "Objection to Description of Goods/Services" && matchingClass?.isNewClass) {
                            totalGovt += 350; // Charge $350 per new class
                        }
                    });
                }
            }
        });
    
        // Ensure only **one** attorney fee for "Objection to Description of Goods/Services"
        if (hasObjectionToDescription) {
            const pricingCategory = pricingData.find(
                (category) => category.category === "Trademark Office Action"
            );
            const pricing = pricingCategory?.services.find(
                (service) => service.service.toLowerCase() === "objection to description of goods/services"
            );
    
            if (pricing) {
                totalAtty += parseFloat(pricing.attyFee.replace("$", "").replace(",", "")) || 0;
            }
        }
    
        console.log("Total Attorney Fee (Before Set):", totalAtty);
        console.log("Total Government Fee (Before Set):", totalGovt);
    
        setTotalAttyFee(totalAtty);
        setTotalGovtFee(totalGovt);
    };
    
    
    
    // Ensure this runs after gsBlocks updates
    useEffect(() => {
        if (Array.isArray(gsBlocks) && gsBlocks.length > 0) {
            console.log("Calculating costs..."); // Debugging log
            calculateCosts(); 
        }
    }, [gsBlocks]); // Only runs when `gsBlocks` updates
    

    const totalCost = totalAttyFee + totalGovtFee;

    const rejectionDescriptions = {
        "Likelihood of Confusion": "This refusal occurs when the USPTO believes that the applied-for trademark is too similar to a registered mark in appearance, sound, meaning, or commercial impression.",
        "Disclaimer": "A disclaimer is required when a portion of the applied-for mark is not inherently distinctive and must be disclaimed as it cannot be exclusively owned.",
        "Merely Descriptive": "This refusal occurs when the applied-for mark merely describes an ingredient, quality, characteristic, function, feature, purpose, or use of the specified goods or services.",
        "Geographically Descriptive": "This refusal occurs when the mark is primarily geographically descriptive of the origin of the goods or services.",
        "audit": "An audit requires submission of additional specimens for specific classes.",
        "Translation": "The USPTO requires a translation of the non-English terms in the applied-for mark.",
        "Specimen Rejection": "The submitted specimen does not show the applied-for mark as actually used in commerce.",
        "Identification of Goods & Services": "This rejection requires clarification or amendment of the goods/services listed under specific classes.",
        // Add more rejection types as needed
    };

    const handleUseSelectionChange = (blockId, value) => {
        setSelectedUse((prevUse) => ({ ...prevUse, [blockId]: value }));

        setClassBlocks((prevBlocks) =>
            prevBlocks.map((block) => {
                if (block.id !== blockId) return block;

                const updatedBlock = { ...block };
                if (value === "web") {
                    updatedBlock.specimens = [];
                    setFilePreviews((prevPreviews) =>
                        prevPreviews.filter((preview) => preview.blockId !== blockId)
                    );
                } else if (value === "use") {
                    updatedBlock.url = "";
                }
                return updatedBlock;
            })
        );
    };

    const handleInputChange = (blockId, field, value) => {
        setClassBlocks((prevBlocks) =>
            prevBlocks.map((block) =>
                block.id === blockId ? { ...block, [field]: value } : block
            )
        );
    };

    const handleSpecimenFileChange = (blockId, files) => {
        const fileArray = Array.from(files);
        const updatedFilePreviews = [...filePreviews];

        const block = classBlocks.find((block) => block.id === blockId);
        if (!block) {
            console.error("Block not found for blockId:", blockId);
            return;
        }

        const classNumber = block.id.padStart(3, "0");

        fileArray.forEach((file, index) => {
            const specimenNumber = (block.specimens?.length || 0) + index + 1;
            const standardizedFileName = `Class ${classNumber} - Specimen ${specimenNumber}.${file.name.split('.').pop()}`;

            const fileId = `${standardizedFileName}-${Date.now()}`;

            // Check if the file already exists
            if (updatedFilePreviews.some((preview) => preview.name === standardizedFileName)) {
                console.warn(`Duplicate file detected: ${standardizedFileName}`);
                return;
            }

            const newFile = new File([file], standardizedFileName, { type: file.type });

            filesMap.current[fileId] = newFile;

            const reader = new FileReader();

            reader.onloadend = () => {
                const fileInfo = {
                    id: fileId,
                    blockId,
                    name: standardizedFileName,
                    url: reader.result,
                };

                updatedFilePreviews.push(fileInfo);
                setFilePreviews([...updatedFilePreviews]);

                setClassBlocks((prevBlocks) =>
                    prevBlocks.map((block) =>
                        block.id === blockId
                            ? { ...block, specimens: [...(block.specimens || []), fileInfo] }
                            : block
                    )
                );
            };

            reader.readAsDataURL(newFile);
        });
    };

    const removeFilePreview = (fileId) => {
        setFilePreviews((prevPreviews) => prevPreviews.filter((preview) => preview.id !== fileId));
        setClassBlocks((prevBlocks) =>
            prevBlocks.map((block) => ({
                ...block,
                specimens: block.specimens.filter((specimen) => specimen.id !== fileId),
            }))
        );
    };

    const handleResponseChange = (type, response, feeKey) => {
        setSelectedResponse((prev) => {
            const previousResponse = prev[type]?.feeKey || null;

            // Retrieve pricing data for the previous and new selections
            const pricingCategory = pricingData.find(
                (category) => category.category === "Trademark Office Action"
            );

            const previousPricing = previousResponse
                ? pricingCategory?.services.find(
                    (service) => service.service.toLowerCase() === previousResponse.toLowerCase()
                )
                : null;

            const newPricing = pricingCategory?.services.find(
                (service) => service.service.toLowerCase() === feeKey.toLowerCase()
            );

            const previousAttyFee = previousPricing
                ? parseFloat(previousPricing.attyFee.replace("$", "").replace(",", ""))
                : 0;

            const previousGovtFee = previousPricing
                ? parseFloat(previousPricing.govtFee.replace("$", "").replace(",", "")) || 0
                : 0;

            const newAttyFee = newPricing
                ? parseFloat(newPricing.attyFee.replace("$", "").replace(",", ""))
                : 0;

            const newGovtFee = newPricing
                ? parseFloat(newPricing.govtFee.replace("$", "").replace(",", "")) || 0
                : 0;

            // Adjust the total fees
            setTotalAttyFee((prev) => prev - previousAttyFee + newAttyFee);
            setTotalGovtFee((prev) => prev - previousGovtFee + newGovtFee);

            // Update collapse state for this type
            setIsCollapsed((prev) => ({
                ...prev,
                [type]: feeKey === "Amendment to Supplemental Register",
            }));

            // Update the response selection state
            return {
                ...prev,
                [type]: { response, feeKey },
            };
        });
    };

    // Check for session token
    const checkSession = async () => {
        const authData = localStorage.getItem('sntmAuth');
        if (authData) {
            const parsedData = JSON.parse(authData);

            const user = parsedData.data?.user;
            const session = parsedData.data?.session;

            if (session && session.access_token) {
                const isValidSession = await verifySession(session.access_token);
                if (isValidSession) {
                    return session.access_token;
                } else {
                    alert('Session is expired. Please login again.');
                }
            } else {
                console.error('Session or access token is undefined');
            }
        } else {
            console.log('Did not find sntmAuth cookie!');
        }
    };

    const verifySession = async (token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/verify-session`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.ok;
        } catch (error) {
            return false;
        }
    };

    const clioFileUpload = async (selectedDeadline, token) => {
        let uploadPromises = [];

        // Get folder ID from the selectedEvent's custom_properties
        const folderIdField = selectedDeadline.external_properties.find((folderId) => folderId.name === 'folderID');
        const folderId = folderIdField.value;
        if (!folderId) {
            console.error('Folder ID not found in custom_properties for the selected event.');
            return;
        }

        // Check if there are files to upload based on file previews
        if (filePreviews.length === 0) {
            console.log('No files to upload.');
            return;
        }

        // Handle file previews for upload
        for (const preview of filePreviews) {
            const fileId = preview.id;
            const file = filesMap.current[fileId]; // Retrieve the File object

            if (!file) {
                console.error('File not found for ID:', fileId);
                continue;
            }

            // Upload the file to Clio
            const uploadPromise = uploadSpecimenToClio(file, folderId, token);
            uploadPromises.push(uploadPromise);
        }

        try {
            await Promise.all(uploadPromises);
            console.log('All specimens uploaded successfully!');
        } catch (error) {
            console.log('Error uploading files to Clio:', error);
        }
    };


    const uploadSpecimenToClio = async (file, folderId, token) => {
        const url = `${process.env.REACT_APP_DOMAIN}/api/create-and-upload-clio-document`;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('folderId', folderId);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    // Do not set 'Content-Type'; the browser will set it automatically
                },
                body: formData,
            });

            if (!response.ok) throw new Error('Failed to upload specimen to Clio');

            const result = await response.json();
            console.log('Specimen uploaded successfully:', result);
        } catch (error) {
            console.error('Error uploading specimen to Clio:', error);
        }
    };

    const validateForm = () => {
        let isValid = true;
        let validationErrors = [];

        // Validation for Identification of Goods & Services rejection
        const identificationRejection = parsedRejections.find(
            (rejection) =>
                rejection.type.toLowerCase() === "identification of goods & services".toLowerCase()
        );

        if (identificationRejection) {
            gsBlocks.forEach((block) => {
                const descriptionText = classDescriptions[block.classNumber] || block.description;

                // Check if curly brackets are still present
                const hasCurlyBrackets = /\{.*?\}/.test(descriptionText);

                // Ensure either approval checkbox is checked OR there are no curly brackets
                if (!approvedDescriptions[block.classNumber] && hasCurlyBrackets) {
                    isValid = false;
                    validationErrors.push(`Class ${block.classNumber}: Please approve the description or replace text within {curly brackets}.`);
                }
            });
        }

        // Validation for Audit or Specimen Rejection
        const auditOrSpecimenRejections = parsedRejections.filter(
            (rejection) => ["audit", "specimen rejection"].includes(rejection.type.toLowerCase())
        );

        if (auditOrSpecimenRejections.length > 0) {
            classBlocks.forEach((block) => {
                const uploadedSpecimens = filePreviews.filter(preview => preview.blockId === block.id);
                if (uploadedSpecimens.length === 0) {
                    isValid = false;
                    validationErrors.push(`Class ${block.id}: At least one specimen file must be uploaded.`);
                }
            });
        }

        // Validation for Merely Descriptive or Geographically Descriptive Rejection
        const descriptiveRejection = parsedRejections.find(
            (rejection) => rejection.type.toLowerCase().includes("descriptive")
        );

        if (descriptiveRejection) {
            Object.keys(selectedResponse).forEach(type => {
                if (type.toLowerCase().includes("descriptive")) {
                    const response = selectedResponse[type]?.feeKey;
                    if (!response) {
                        isValid = false;
                        validationErrors.push(`You must select either "Amendment to Supplemental Register" or "Submit Arguments in Favor of Registration" for the '${type}' rejection.`);
                    }
                }
            });
        }

        if (!isValid) {
            alert("Form validation failed:\n" + validationErrors.join("\n"));
        }

        return isValid;
    };

    const generateAuthorizationEmail = (parsedRejections, approvedDescriptions, selectedResponse, filePreviews, totalAttyFee, totalGovtFee, setEmailBody) => {
        let messageBody = `
            <html>
            <head>
                <style>
                    body { font-family: Times, serif; line-height: 1.6; }
                    h2 { color: #333; }
                    table { width: 100%; border-collapse: collapse; margin-top: 10px; }
                    th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                    th { background-color: #f2f2f2; }
                    .highlight { font-weight: bold; color: #2c3e50; }
                </style>
            </head>
            <body>
                <h2>Office Action Response Submission</h2>
                <h3>Rejections and Responses</h3>
                <table>
                    <tr>
                        <th>Rejection Type</th>
                        <th>User Response</th>
                    </tr>`;
    
        parsedRejections.forEach(({ type }) => {
            const response = selectedResponse[type]?.response || "No response selected";
            messageBody += `
                    <tr>
                        <td>${type}</td>
                        <td>${response}</td>
                    </tr>`;
        });
    
        messageBody += `
                </table>
                <h3>Approved Descriptions</h3>
                <table>
                    <tr>
                        <th>Class</th>
                        <th>Description</th>
                        <th>Approved</th>
                    </tr>`;
    
        Object.entries(approvedDescriptions).forEach(([classNumber, approved]) => {
            const description = gsBlocks.find(block => block.classNumber === classNumber)?.description || "N/A";
            messageBody += `
                    <tr>
                        <td>${classNumber}</td>
                        <td>${description}</td>
                        <td>${approved ? "✅ Approved" : "❌ Not Approved"}</td>
                    </tr>`;
        });
    
        messageBody += `
                </table>
                <h3>Uploaded Specimens</h3>
                <table>
                    <tr>
                        <th>Class</th>
                        <th>File Name</th>
                    </tr>`;
    
        filePreviews.forEach(({ blockId, name }) => {
            messageBody += `
                    <tr>
                        <td>${blockId}</td>
                        <td>${name}</td>
                    </tr>`;
        });
    
        messageBody += `
                </table>
                <h3>Total Fees</h3>
                <p><strong>Attorney Fee:</strong> $${totalAttyFee.toFixed(2)}</p>
                <p><strong>Government Fee:</strong> $${totalGovtFee.toFixed(2)}</p>
                <p class="highlight"><strong>Total Cost:</strong> $${(totalAttyFee + totalGovtFee).toFixed(2)}</p>
            </body>
            </html>
        `;
    
        setEmailBody(messageBody);
    };     

    const handleAuthorizeClick = async () => {
        // console.log('Authorize Response button clicked!'); // Debug log

        // Validate the form before proceeding
        if (!validateForm()) {
            return;
        }

        //Open the payment modal
        setShowPaymentModal(true);

        // let url = `${process.env.REACT_APP_DOMAIN}/api/authorizeGSUpdate`;

        // // Get the session token for verification
        // const token = await checkSession();
        // if (!token) {
        //     alert('Authorization failed. Please log in again.');
        //     return;
        // }

        // const identificationRejection = parsedRejections.find(
        //     (rejection) =>
        //         rejection.type.toLowerCase() === "identification of goods & services".toLowerCase()
        // );

        // if (identificationRejection) {
        //     console.log("Preparing to update descriptions of goods and services...");

        //     // Prepare the body with all updates
        //     const customFieldValues = gsBlocks.map((block) => ({
        //         id: block.id, // Description ID from Clio
        //         value: classDescriptions[block.classNumber] || block.description, // Use the user-provided updated description
        //     }));

        //     try {
        //         // Send a single API call with the complete body
        //         const response = await fetch(url, {
        //             method: "POST",
        //             headers: {
        //                 "Authorization": `Bearer ${token}`,
        //                 "Content-Type": "application/json",
        //             },
        //             body: JSON.stringify({ matterId: selectedFile.id, customFieldValues: customFieldValues }),
        //         });

        //         if (!response.ok) {
        //             throw new Error(`Error: ${response.statusText}`);
        //         }

        //         console.log("Descriptions updated successfully!");
        //     } catch (error) {
        //         console.error("Error updating descriptions:", error);
        //         alert("Failed to update descriptions. Please try again.");
        //         return; // Stop further execution if the update fails
        //     }
        // }

        // // Step 2: Upload specimen files to Clio
        // console.log("Preparing to upload specimen files...");
        // try {
        //     await clioFileUpload(selectedDeadline, token); // Pass selectedDeadline and token
        // } catch (error) {
        //     console.error("Error uploading specimens:", error);
        //     alert("Failed to upload specimen files. Please try again.");
        //     return;
        // }

        // generateAuthorizationEmail(
        //     parsedRejections, 
        //     approvedDescriptions, 
        //     selectedResponse, 
        //     filePreviews, 
        //     totalAttyFee, 
        //     totalGovtFee,
        //     setEmailBody // Pass the function to update state
        // );

        // onFormSubmit(); // Trigger the parent function
    };

    const processFinalUpdates = async () => {
        console.log("Payment successful. Now processing final updates...");

        let url = `${process.env.REACT_APP_DOMAIN}/api/authorizeGSUpdate`;

        // Get the session token for verification
        const token = await checkSession();
        if (!token) {
            alert('Authorization failed. Please log in again.');
            return;
        }

        const identificationRejection = parsedRejections.find(
            (rejection) =>
                rejection.type.toLowerCase() === "identification of goods & services".toLowerCase()
        );

        if (identificationRejection) {
            console.log("Preparing to update descriptions of goods and services...");

            // Prepare the body with all updates
            const customFieldValues = gsBlocks.map((block) => ({
                id: block.id, // Description ID from Clio
                value: classDescriptions[block.classNumber] || block.description, // Use the user-provided updated description
            }));

            try {
                // Send a single API call with the complete body
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ matterId: selectedFile.id, customFieldValues: customFieldValues }),
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }

                console.log("Descriptions updated successfully!");
            } catch (error) {
                console.error("Error updating descriptions:", error);
                alert("Failed to update descriptions. Please try again.");
                return; // Stop further execution if the update fails
            }
        }

        // Step 2: Upload specimen files to Clio
        console.log("Preparing to upload specimen files...");
        try {
            await clioFileUpload(selectedDeadline, token); // Pass selectedDeadline and token
        } catch (error) {
            console.error("Error uploading specimens:", error);
            alert("Failed to upload specimen files. Please try again.");
            return;
        }

        generateAuthorizationEmail(
            parsedRejections, 
            approvedDescriptions, 
            selectedResponse, 
            filePreviews, 
            totalAttyFee, 
            totalGovtFee,
            setEmailBody // Pass the function to update state
        );

        onFormSubmit(); // Trigger the parent function
    }

    return (
        <Modal show onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Authorize Office Action Response</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h5>Office Action Details</h5>
                    <p>
                        <strong>Summary:</strong> {summary || "N/A"}
                    </p>
                    <p>
                        <strong>Deadline:</strong> {new Date(start_at).toLocaleDateString() || "N/A"}
                    </p>
                </div>
                <hr />
                <div>
                    <h5>Rejection Types in this Office Action</h5>
                    {parsedRejections.map(({ type, classInfo }, index) => (
                        <div key={index} className="accordion-item authorizeSOU-classBlock-header">
                            <div
                                className="accordion-header"
                                onClick={() =>
                                    setExpandedRejectionIndex(
                                        expandedRejectionIndex === index ? null : index
                                    )
                                }
                            >
                                <h2>{type}</h2>
                            </div>
                            {expandedRejectionIndex === index && (
                                <div className="accordion-content authorizeSOU-classBlock-content">
                                    <p>
                                        {rejectionDescriptions[type.trim()] ||
                                            ""}
                                    </p>
                                    {type.toLowerCase() === "identification of goods & services" &&
                                        gsBlocks.map((block, idx) => (
                                            <div key={idx} className="class-block">
                                                <label>
                                                    <strong>Class {block.classNumber}:</strong>
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    rows="3"
                                                    value={
                                                        classDescriptions[block.classNumber] ||
                                                        block.description
                                                    }
                                                    onChange={(e) =>
                                                        handleGsDescriptionChange(
                                                            block.classNumber,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {!block.hasCurlyBrackets && (
                                                    <div style={{ margin: "0.25rem 0 1rem 0" }}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={approvedDescriptions[block.classNumber] || false}
                                                                style={{ marginRight: '1rem' }}
                                                                onChange={(e) =>
                                                                    setApprovedDescriptions((prev) => ({
                                                                        ...prev,
                                                                        [block.classNumber]: e.target.checked,
                                                                    }))
                                                                }
                                                            />
                                                            I approve this description as written
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        ))}

                                    {(type.toLowerCase() === "audit" || type.toLowerCase() === "specimen rejection") &&
                                        classBlocks.map((block, idx) => (
                                            <div key={idx} className="class-block">
                                                <h4>Class {block.id}</h4>
                                                <p>{block.className.description}</p>
                                                <UseSelection
                                                    value={selectedUse[block.id]}
                                                    onChange={(value) => handleUseSelectionChange(block.id, value)}
                                                    excludeOptions={["itu", "foreignApp", "foreignReg"]}
                                                />

                                                {selectedUse[block.id] === "web" && (
                                                    <div>
                                                        <label>Enter URL:</label>
                                                        <input
                                                            type="text"
                                                            value={block.url || ""}
                                                            onChange={(e) =>
                                                                handleInputChange(block.id, "url", e.target.value)
                                                            }
                                                            className="form-control"
                                                        />
                                                    </div>
                                                )}

                                                {selectedUse[block.id] === "use" && (
                                                    <div>
                                                        <label>Upload Specimens:</label>
                                                        <input
                                                            type="file"
                                                            multiple
                                                            onChange={(e) =>
                                                                handleSpecimenFileChange(block.id, e.target.files)
                                                            }
                                                        />
                                                        <div className="file-previews">
                                                            {filePreviews
                                                                .filter((preview) => preview.blockId === block.id)
                                                                .map((preview) => (
                                                                    <div key={preview.id} className="file-preview">
                                                                        <img
                                                                            src={preview.url}
                                                                            alt={preview.name}
                                                                            className="img-thumbnail"
                                                                            style={{
                                                                                width: "100px",
                                                                                height: "100px",
                                                                                objectFit: "cover",
                                                                                margin: "10px",
                                                                            }}
                                                                        />
                                                                        <p>{preview.name}</p>
                                                                        <Button
                                                                            variant="danger"
                                                                            size="sm"
                                                                            onClick={() => removeFilePreview(preview.id)}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}

                                    {type.toLowerCase().includes("descriptive") && (
                                        <div className="response-options">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={`response-${type}`}
                                                    value="arguments"
                                                    checked={selectedResponse[type]?.feeKey === "Merely Descriptive"}
                                                    onChange={() =>
                                                        handleResponseChange(
                                                            type,
                                                            "arguments",
                                                            "Merely Descriptive"
                                                        )
                                                    }
                                                />
                                                Submit Arguments in Favor of Registration
                                            </label>
                                            <br />
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={`response-${type}`}
                                                    value="supplemental"
                                                    checked={
                                                        selectedResponse[type]?.feeKey ===
                                                        "Amendment to Supplemental Register"
                                                    }
                                                    onChange={() =>
                                                        handleResponseChange(
                                                            type,
                                                            "supplemental",
                                                            "Amendment to Supplemental Register"
                                                        )
                                                    }
                                                />
                                                Amend the Application to Supplemental Register
                                            </label>
                                            {/* Collapsible content */}
                                            {isCollapsed[type] && (
                                                <div
                                                    style={{
                                                        marginTop: "1rem",
                                                        padding: "1rem",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <h5>Registration on the Supplemental Register Provides the following advantages and disadvantages:</h5>
                                                    <h6>Advantages:</h6>
                                                    <ul>
                                                        <li>You can bring a suit for infringement in federal court and federal law governs.</li>
                                                        <li>
                                                            Your mark can be used by the Trademark Office to cite against future applications of
                                                            confusingly similar marks for registration on either the Principal or Supplemental Registers.
                                                        </li>
                                                        <li>
                                                            Your mark is searchable by the public and will appear in search reports of others that are
                                                            seeking clearance of their mark.
                                                        </li>
                                                        <li>You can use the R in a circle symbol to indicate federal registration.</li>
                                                        <li>Your mark is not subject to challenge by an Opposition proceeding.</li>
                                                    </ul>
                                                    <h6>Disadvantages:</h6>
                                                    <ul>
                                                        <li>
                                                            While a Principal Registration is evidence of your exclusive right to the mark, a
                                                            Supplemental Registration has no such evidentiary effect.
                                                        </li>
                                                        <li>
                                                            Principal Registration is constructive notice of claim of ownership to eliminate a defense of
                                                            good faith, but a Supplemental Registration has no such effect.
                                                        </li>
                                                        <li>
                                                            A Principal Registration becomes incontestable after five years. A Supplemental Registration
                                                            does not become incontestable. However, after five years of registration on the Supplemental
                                                            Register, you can file for registration on the Principal Register.
                                                        </li>
                                                        <li>
                                                            Registration on the Supplemental Register cannot be used as a basis for the Department of the
                                                            Treasury to stop importation of infringing goods into the United States.
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {/* Cost Summary */}
                <div className="cost-summary">
                    <h3>Cost Summary</h3>
                    <p>Attorney Fee: ${totalAttyFee.toFixed(0)}</p>
                    <p>Government Fee: ${totalGovtFee.toFixed(0)}</p>
                    <h4>Total Cost: ${totalCost.toFixed(0)}</h4>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                {/* <Button
                    variant="primary"
                    onClick={() => onFormSubmit({ classBlocks, filePreviews })}
                >
                    Authorize Response
                </Button> */}
                <button onClick={handleAuthorizeClick} className="form-control">Authorize Response</button>
                <button onClick={onClose} className="form-control">Cancel</button>
            </Modal.Footer>
        </Modal>
    );
};

export default OfficeActionResponseSubModal;
