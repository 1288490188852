import React, { useState, useEffect } from 'react';
import StepIndicator from './stepIndicator.js';
import ContactContact from './contactContact.js';
import OwnerContact from './ownerContact.js';
import AppType from './appType.js';
import MarkClasses from './markClasses.js';
import UsageInfo from './useInfo.js';
import SearchModal from './searchModal.js';
import { supabaseConflictCheck } from './supabase.js';
import ClioContactQuery from './clioContactQuery.js';
import { debounce, random } from 'lodash';
import SubmissionStatus from './submissionStatus.js';
import { cloneDeep } from 'lodash';
import { isDisabled } from '@testing-library/user-event/dist/utils/index.js';
import CompanyType from './companyType.js';
import EngagementLetter from './engagementLetter.js';
import { Form } from 'react-router-dom';
import JSZip from 'jszip';
import FormDownload, { generateFormContent } from './formDownload.js';
import { createClientEmail } from './createClientEmail.js';
import UserRegistration from './userRegistration.js';
import SearchOption from './searchOption.js';
import PaymentPage from './paymentPage.js';
import { pricingData } from './pricingData.js';


const PricingCalculator = ({ toggleForm }) => {
    const initialFormData = {
        clioMatterID: '',
        contactID: '',
        contactFirstName: '',
        contactMiddleName: '',
        contactLastName: '',
        contactCompany: '',
        contactCompanyID: '',
        contactAddress1: '',
        contactAddress2: '',
        contactCity: '',
        contactState: '',
        contactZip: '',
        contactCountry: '',
        contactEmail: '',
        ownerID: '',
        ownerFirstName: '',
        ownerMiddleName: '',
        ownerLastName: '',
        ownerCompany: '',
        ownerCompanyID: '',
        ownerCompanyCountry: '',
        ownerCompanyStateIncorporation: '',
        ownerCompanyType: '',
        ownerAddress1: '',
        ownerAddress2: '',
        ownerCity: '',
        ownerState: '',
        ownerZip: '',
        ownerCountry: '',
        ownerEmail: '',
        applicationType: '',
        wordMark: '',
        markLiteralElement: '',
        markColorClaim: '',
        designMarkFile: [],
        soundMarkDescription: '',
        soundMarkFile: [],
        // classBlocks: [{
        //   id: 0, 
        //   classNo: '', 
        //   goodsServices: '', 
        //   goodsServicesRaw:'',
        //   descriptions:[],
        //   additionalInputs:{},
        //   dateFirstUse: '', 
        //   dateFirstUseInCommerce: '', 
        //   url: '', 
        //   specimens: [],
        //   foreignAppCountry: '',
        //   foreignAppNo: '',
        //   foreignAppFilingDate: '',
        //   foreignRegNo: '',
        //   foreignRegDate: '',
        //   foreignRegRenewedDate: '',
        //   foreignExpirationDate: '',
        //   foreignRegCertificate: '',
        //  }],
        // classBlocks:[{
        // clioID:1008725,
        // clioClassID: 16752904,
        // clioDescriptionID: 16590184,
        // clioDateOfFirstUseID: 16628284,
        // clioDateOfFirstUseInCommerce: 16628299,
        // clioURLID: 17455355,
        // clioForeignAppCountryID: 17400410,
        // clioForeignAppNoID: 17400440,
        // clioForeignAppFilingDateID: 17400455,
        // clioForeignRegCountryID: 17407700,
        // clioForeignRegNoID: 17407880,
        // clioForeignRegDateID: 17408030,
        // clioForeignRegRenewedDateID: 17408180,
        // clioForeignRegExpirationDateID: 17408330,
        //   classNo: '',
        //   desciptions:[],
        //   goodsServices: '',
        //   dateFirstUse: '',
        //   dateFirstUseInCommerce: '',
        //   url: '',
        //   specimenFile: '',
        // }],
        classBlocks: [],
        potentialConflicts: [],
        version: 0,
        teasPlus: true,
        attyFee: '',
    }
    const [formData, setFormData] = useState(initialFormData);
    const [currentStep, setCurrentStep] = useState(1);
    const [transferData, setTransferData] = useState(false);
    const [isTransferChecked, setIsTransferChecked] = useState(false);
    const [selectedOwnerType, setSelectedOwnerType] = useState('');
    const [classBlocks, setClassBlocks] = useState([{ id: 0, classValue: '' }])
    const [ownerType, setOwnerType] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [isCountryDisabled, setIsCountryDisabled] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({
        conflictCheck: 'pending',
        createContactProfile: 'pending',
        createNewMatter: 'pending',
        uploadingDocuments: 'pending',
        submissionComplete: 'pending',
    });
    const [showSubmissionStatus, setShowSubmissionStatus] = useState(false);
    const [isEngagementLetterSigned, setIsEngagementLetterSigned] = useState(false);
    const [showEngagementLetter, setShowEngagementLetter] = useState(false);
    const [showFormDownload, setShowFormDownload] = useState(false);
    const [stepIsValid, setStepIsValid] = useState({});
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [engagementContent, setEngagementContent] = useState('');
    const [signatureText, setSignatureText] = useState('');
    const [signatureTime, setSignatureTime] = useState('');
    const [engagementLetterUrl, setEngagementLetterUrl] = useState('');
    const [capturedFormData, setCapturedFormData] = useState('');
    const [totalFee, setTotalFee] = useState('');
    const [totalEstimatedFee, setTotalEstimatedFee] = useState('');
    // const [totalAmount, setTotalAmount] = useState('');
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [feeDetails, setFeeDetails] = useState({
        totalFee: '0.00',
        totalFilingFee: '0.00',
        filingFeePerClass: '0.00',
        surchargeAmount: '0.00',
        surchargeApplies: false,
    });


    const handleFormDataChange = (newData) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            ...newData,
        }));
    };

    // Handler to update validation state for the current step
    const handleValidationChange = (isValid) => {
        setStepIsValid(prevState => ({
            ...prevState,
            [currentStep]: isValid
        }));
    };

    useEffect(() => {
        if (selectedState) {
            setSelectedCountry("United States");
            setIsCountryDisabled(true);
        } else {
            setIsCountryDisabled(false);
        }
    }, [selectedState]);

    const addClassBlock = () => {
        setFormData(prevFormData => {
            if (prevFormData.classBlocks.length >= 10) {
                alert('It looks like you\'re trying to file an application with a large number of classes. Please contact us to discuss filing options in greater detail.');
                return prevFormData;
            }
            const clioIdMapping = {
                1: 964309, // "Class 2"
                2: 964324, // "Class 3"
                3: 964339, // "Class 4"
                4: 964354, // "Class 5"
                5: 964369, // "Class 6"
                6: 964384, // "Class 7"
                7: 964399, // "Class 8"
                8: 964414, // "Class 9"
                9: 964429, // "Class 10"
            };

            const clioClassIdMapping = {
                1: 16752934, //ID for "Class 2"
                2: 16752949, //ID for "Class 3"
                3: 16752964, //ID for "class 4"
                4: 16752979, //ID for "class 5"
                5: 16752994, //ID for "class 6"
                6: 16753009, //ID for "class 7"
                7: 16753024, //ID for "class 8"
                8: 16753039, //ID for "class 9"
                9: 16753054, //ID for "class 10"
            };

            const clioDescriptionMapping = {
                1: 16590469, //ID for "Description 2"
                2: 16590484, //ID for "Description 3"
                3: 16590499, //ID for "Description 4"
                4: 16590514, //ID for "Description 5"
                5: 16590529, //ID for "Description 6"
                6: 16590544, //ID for "Description 7"
                7: 16590559, //ID for "Description 8"
                8: 16590574, //ID for "Description 9"
                9: 16590589, //ID for "Description 10"
            };

            const clioDateofFirstUseMapping = {
                1: 16628314, //ID for "Date of First Use 2"
                2: 16628344, //ID for "Date of First Use 3"
                3: 16628374, //ID for "Date of First Use 4"
                4: 16628404, //ID for "Date of First Use 5"
                5: 16628434, //ID for "Date of First Use 6"
                6: 16628464, //ID for "Date of First Use 7"
                7: 16628494, //ID for "Date of First Use 8"
                8: 16628524, //ID for "Date of First Use 9"
                9: 16628554, //ID for "Date of First Use 10"
            };

            const clioDateofFirstUseInCommerceMapping = {
                1: 16628329, //ID for "Date of First Use in Commerce 2"
                2: 16628359, //ID for "Date of First Use in Commerce 3"
                3: 16628389, //ID for "Date of First Use in Commerce 4"
                4: 16628419, //ID for "Date of First Use in Commerce 5"
                5: 16628449, //ID for "Date of First Use in Commerce 6"
                6: 16628479, //ID for "Date of First Use in Commerce 7"
                7: 16628509, //ID for "Date of First Use in Commerce 8"
                8: 16628539, //ID for "Date of First Use in Commerce 9"
                9: 16628569, //ID for "Date of First Use in Commerce 10"
            };

            const clioURLMapping = {
                1: 17455355, //ID for "URL 1"
                2: 17455370, //ID for "URL 2"
                3: 17455385, //ID for "URL 3"
                4: 17455400, //ID for "URL 4"
                5: 17455415, //ID for "URL 5"
                6: 17455430, //ID for "URL 6"
                7: 17455445, //ID for "URL 7"
                8: 17455460, //ID for "URL 8"
                9: 17455475, //ID for "URL 9"
                10: 17455490, //ID for "URL 10"
            };

            const clioForeignAppCountryMapping = {
                1: 17400410, //ID for "Foreign Application Country 1"
                2: 17400470, //ID for "Foreign Application Country 2"
                3: 17400530, //ID for "Foreign Application Country 3"
                4: 17400560, //ID for "Foreign Application Country 4"
                5: 17400605, //ID for "Foreign Application Country 5"
                6: 17400650, //ID for "Foreign Application Country 6"
                7: 17400695, //ID for "Foreign Application Country 7"
                8: 17400740, //ID for "Foreign Application Country 8"
                9: 17400785, //ID for "Foreign Application Country 9"
                10: 17400845, //ID for "Foreign Application Country 10"
            };

            const clioForeignAppMapping = {
                1: 17400440, //ID for "Foreign App Serial Number 1"
                2: 17400485, //ID for "Foreign App Serial Number 2"
                3: 17400515, //ID for "Foreign App Serial Number 3"
                4: 17400575, //ID for "Foreign App Serial Number 4"
                5: 17400620, //ID for "Foreign App Serial Number 5"
                6: 17400665, //ID for "Foreign App Serial Number 6"
                7: 17400710, //ID for "Foreign App Serial Number 7"
                8: 17400755, //ID for "Foreign App Serial Number 8"
                9: 17400800, //ID for "Foreign App Serial Number 9"
                10: 17400830, //ID for "Foreign App Serial Number 10"
            };

            const clioForeignAppFilingDateMapping = {
                1: 17400455, //ID for "Foreign App Filing Date 1"
                2: 17400500, //ID for "Foreign App Filing Date 2"
                3: 17400545, //ID for "Foreign App Filing Date 3"
                4: 17400590, //ID for "Foreign App Filing Date 4"
                5: 17400635, //ID for "Foreign App Filing Date 5"
                6: 17400680, //ID for "Foreign App Filing Date 6"
                7: 17400725, //ID for "Foreign App Filing Date 7"
                8: 17400770, //ID for "Foreign App Filing Date 8"
                9: 17400815, //ID for "Foreign App Filing Date 9"
                10: 17400860, //ID for "Foreign App Filing Date 10"
            };

            const clioRegFieldSetMapping = {
                1: 1006655, // "Foreign Registration 1"
                2: 1006670, // "Foreign Registration 2"
                3: 1006685, // "Foreign Registration 3"
                4: 1006700, // "Foreign Registration 4"
                5: 1006715, // "Foreign Registration 5"
                6: 1006730, // "Foreign Registration 6"
                7: 1006745, // "Foreign Registration 7"
                8: 1006760, // "Foreign Registration 8"
                9: 1006775, // "Foreign Registration 9"
                10: 1006790, // "Foreign Registration 10"
            };

            const clioForeignRegCountryMapping = {
                1: 17407700, //ID for "Foreign Reg Country 1"
                2: 17407715, //ID for "Foreign Reg Country 2"
                3: 17407730, //ID for "Foreign Reg Country 3"
                4: 17407760, //ID for "Foreign Reg Country 4"
                5: 17407775, //ID for "Foreign Reg Country 5"
                6: 17407790, //ID for "Foreign Reg Country 6"
                7: 17407820, //ID for "Foreign Reg Country 7"
                8: 17407835, //ID for "Foreign Reg Country 8"
                9: 17407850, //ID for "Foreign Reg Country 9"
                10: 17407865, //ID for "Foreign Reg Country 10"
            };

            const clioForeignRegMapping = {
                1: 17407880, //ID for "Foreign Reg Number 1"
                2: 17407895, //ID for "Foreign Reg Number 2"
                3: 17407910, //ID for "Foreign Reg Number 3"
                4: 17407925, //ID for "Foreign Reg Number 4"
                5: 17407940, //ID for "Foreign Reg Number 5"
                6: 17407955, //ID for "Foreign Reg Number 6"
                7: 17407970, //ID for "Foreign Reg Number 7"
                8: 17407985, //ID for "Foreign Reg Number 8"
                9: 17408000, //ID for "Foreign Reg Number 9"
                10: 17408015, //ID for "Foreign Reg Number 10"
            };

            const clioForeignRegDateMapping = {
                1: 17408030, //ID for "Foreign Reg Date 1"
                2: 17408045, //ID for "Foreign Reg Date 2"
                3: 17408060, //ID for "Foreign Reg Date 3"
                4: 17408075, //ID for "Foreign Reg Date 4"
                5: 17408090, //ID for "Foreign Reg Date 5"
                6: 17408105, //ID for "Foreign Reg Date 6"
                7: 17408120, //ID for "Foreign Reg Date 7"
                8: 17408135, //ID for "Foreign Reg Date 8"
                9: 17408150, //ID for "Foreign Reg Date 9"
                10: 17408165, //ID for "Foreign Reg Date 10"
            };

            const clioForeignRegRenewedMapping = {
                1: 17408180, //ID for "Foreign Reg Renewed Date 1"
                2: 17408195, //ID for "Foreign Reg Renewed Date 2"
                3: 17408210, //ID for "Foreign Reg Renewed Date 3"
                4: 17408225, //ID for "Foreign Reg Renewed Date 4"
                5: 17408240, //ID for "Foreign Reg Renewed Date 5"
                6: 17408255, //ID for "Foreign Reg Renewed Date 6"
                7: 17408270, //ID for "Foreign Reg Renewed Date 7"
                8: 17408285, //ID for "Foreign Reg Renewed Date 8"
                9: 17408300, //ID for "Foreign Reg Renewed Date 9"
                10: 17408315, //ID for "Foreign Reg Renewed Date 10"
            };

            const clioForeignRegExpirationMapping = {
                1: 17408330, //ID for "Foreign Reg Expiration Date 1"
                2: 17408345, //ID for "Foreign Reg Expiration Date 2"
                3: 17408360, //ID for "Foreign Reg Expiration Date 3"
                4: 17408375, //ID for "Foreign Reg Expiration Date 4"
                5: 17408390, //ID for "Foreign Reg Expiration Date 5"
                6: 17408405, //ID for "Foreign Reg Expiration Date 6"
                7: 17408420, //ID for "Foreign Reg Expiration Date 7"
                8: 17408435, //ID for "Foreign Reg Expiration Date 8"
                9: 17408450, //ID for "Foreign Reg Expiration Date 9"
                10: 17408465, //ID for "Foreign Reg Expiration Date 10"
            };

            // Calculate the new block's index in the array
            const newBlockIndex = prevFormData.classBlocks.length;

            // Use the index to get the corresponding Clio ID from the mapping
            const clioID = clioIdMapping[newBlockIndex] || null; // Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio class ID from the mapping
            const clioClassID = clioClassIdMapping[newBlockIndex] || null; // Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio description ID from the mapping
            const clioDescriptionID = clioDescriptionMapping[newBlockIndex] || null; // Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio date of first use ID from the mapping
            const clioDateOfFirstUseID = clioDateofFirstUseMapping[newBlockIndex] || null; // Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio date of first use in commerce ID from the mapping
            const clioDateOfFirstUseInCommerce = clioDateofFirstUseInCommerceMapping[newBlockIndex] || null; // Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio URL ID from the mapping
            const clioURLID = clioURLMapping[newBlockIndex + 1] || null; // Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio foreign application country ID from the mapping
            const clioForeignAppCountryID = clioForeignAppCountryMapping[newBlockIndex + 1] || null; //Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio foreign application serial number ID from the mapping
            const clioForeignAppNoID = clioForeignAppMapping[newBlockIndex + 1] || null; //Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio foreign application filing date ID from the mapping
            const clioForeignAppFilingDateID = clioForeignAppFilingDateMapping[newBlockIndex + 1] || null; //Fallback to null if no ID found (for safety)


            // Use the index to get the corresponding Clio foreign registration country ID from the mapping
            const clioForeignRegCountryID = clioForeignRegCountryMapping[newBlockIndex + 1] || null; //Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio foreign registration number ID from the mapping
            const clioForeignRegNoID = clioForeignRegMapping[newBlockIndex + 1] || null; //Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio foreign registration date ID from the mapping
            const clioForeignRegDateID = clioForeignRegDateMapping[newBlockIndex + 1] || null; //Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio foreign registration renewed date ID from the mapping
            const clioForeignRegRenewedDateID = clioForeignRegRenewedMapping[newBlockIndex + 1] || null; //Fallback to null if no ID found (for safety)

            // Use the index to get the corresponding Clio foreign registration expiration date ID from the mapping
            const clioForeignRegExpirationDateID = clioForeignRegExpirationMapping[newBlockIndex + 1] || null; //Fallback to null if no ID found (for safety)

            // Proceed to add a new block if the first block is filled
            const newBlock = {
                id: newBlockIndex,
                clioID: clioID,
                clioClassID: clioClassID,
                clioDescriptionID: clioDescriptionID,
                clioDateOfFirstUseID: clioDateOfFirstUseID,
                clioDateOfFirstUseInCommerce: clioDateOfFirstUseInCommerce,
                clioURLID: clioURLID,
                clioForeignAppCountryID: clioForeignAppCountryID,
                clioForeignAppNoID: clioForeignAppNoID,
                clioForeignAppFilingDateID: clioForeignAppFilingDateID,
                clioForeignRegCountryID: clioForeignRegCountryID,
                clioForeignRegNoID: clioForeignRegNoID,
                clioForeignRegDateID: clioForeignRegDateID,
                clioForeignRegRenewedDateID: clioForeignRegRenewedDateID,
                clioForeignRegExpirationDateID: clioForeignRegExpirationDateID,
                classNo: '',
                goodsServices: '',
                dateFirstUse: '',
                dateFirstUseInCommerce: '',
                url: '',
                // specimenFile: ''
                specimens: [],
                surcharge: 0,
            };

            return {
                ...prevFormData,
                classBlocks: [...prevFormData.classBlocks, newBlock]
            };
        });
    };

    // Function to remove a class block
    const removeClassBlock = (classBlockId) => {
        setFormData(prevFormData => {
            const updatedClassBlocks = prevFormData.classBlocks.filter(block => block.id !== classBlockId);
            return { ...prevFormData, classBlocks: updatedClassBlocks };
        });
    };

    const handleClassChange = (id, field, value) => {
        setClassBlocks(classBlocks => classBlocks.map(block => {
            if (block.id === id) {
                return { ...block, [field]: value };
            }
            return block;
        }));
    };

    const handleClassBlockChange = (id, field, value) => {
        setFormData(prevFormData => {
            const updatedClassBlocks = prevFormData.classBlocks.map(block => {
                if (block.id === id) {
                    // Check if the field targets a nested property (i.e., contains a dot indicating a path)
                    if (field.includes(".")) {
                        const [topLevelField, nestedField] = field.split(".");
                        // Specifically handle updates within additionalInputs
                        if (topLevelField === "additionalInputs") {
                            return {
                                ...block,
                                additionalInputs: {
                                    ...block.additionalInputs,
                                    [nestedField]: value,
                                },
                            };
                        }
                        // Add other conditions here if there are more nested fields to handle
                    } else {
                        // Handle top-level field updates
                        return { ...block, [field]: value };
                    }
                }
                return block;
            });

            return { ...prevFormData, classBlocks: updatedClassBlocks };
        });
    };

    const updateClassBlockInfo = (updatedBlock) => {
        setFormData((prevFormData) => {
            // Map through existing classBlocks to find and update the specific block
            const updatedClassBlocks = prevFormData.classBlocks.map(block => {
                if (block.id === updatedBlock.id) {
                    // Merge the updated fields into the current block
                    return { ...block, ...updatedBlock };
                }
                return block; // Return the block unchanged if it's not the one being updated
            });

            // Return the updated formData with the merged classBlocks
            return {
                ...prevFormData,
                classBlocks: updatedClassBlocks,
            };
        });
    };

    const onUpdateClassBlocks = (blockId, fileInfo) => {
        setFormData(prevFormData => {
            const updatedClassBlocks = prevFormData.classBlocks.map(block => {
                if (block.id === blockId) {
                    // Check if fileInfo is an array, if not make it an array
                    const newFileInfoArray = Array.isArray(fileInfo) ? fileInfo : [fileInfo];
                    // Add the new fileInfo to the specimens array of the matching block
                    const updatedSpecimens = [...block.specimens, ...newFileInfoArray];
                    return { ...block, specimens: updatedSpecimens };
                }
                return block;
            });

            return { ...prevFormData, classBlocks: updatedClassBlocks };
        });
    };

    const onDeleteSpecimens = (blockId, updatedBlock) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            classBlocks: prevFormData.classBlocks.map(block =>
                block.id === blockId ? updatedBlock : block
            ),
        }));
    };

    const handleAddClassDescription = (classId, coreSegments, additionalInputs = {}) => {
        if (!classId) return;

        // Ensure coreSegments is always an array
        // If coreSegments is not already an array, wrap it in an array
        coreSegments = Array.isArray(coreSegments) ? coreSegments : [coreSegments];

        // Construct a new description object
        const newDescription = {
            id: Date.now(),
            core: coreSegments, // This is now guaranteed to be an array
            additionalInputs: additionalInputs // Additional inputs remain as an object
        };

        setFormData(prevFormData => {
            if (prevFormData.classBlocks.length >= 10) {
                alert('It looks like you\'re trying to file an application with a large number of classes. Please contact us to discuss filing options in greater detail.');
                return prevFormData;
            }

            let updated = false;

            // Iterate over classBlocks to find the matching class block or update the first blank block
            const updatedClassBlocks = prevFormData.classBlocks.map(block => {
                if (block.classNo === classId || (!block.classNo && !block.goodsServices)) {
                    updated = true;

                    // Construct the updated block with the new description
                    return {
                        ...block,
                        descriptions: [...block.descriptions, newDescription],
                        classNo: block.classNo || classId,
                        goodsServices: block.goodsServices ? `${block.goodsServices}; ${coreSegments.join(', ')}` : coreSegments.join(', '),
                        goodsServicesRaw: block.goodsServicesRaw ? `${block.goodsServicesRaw}; ${Object.values(additionalInputs).join(', ')}` : Object.values(additionalInputs).join(', '),
                        clioID: 1008725,
                        clioClassID: 16752904,
                        clioDescriptionID: 16590184,
                        clioDateOfFirstUseID: 16628284,
                        clioDateOfFirstUseInCommerce: 16628299,
                        clioURLID: 17455355,
                        clioForeignAppCountryID: 17400410,
                        clioForeignAppNoID: 17400440,
                        clioForeignAppFilingDateID: 17400455,
                        clioForeignRegCountryID: 17407700,
                        clioForeignRegNoID: 17407880,
                        clioForeignRegDateID: 17408030,
                        clioForeignRegRenewedDateID: 17408180,
                        clioForeignRegExpirationDateID: 17408330,
                        _version: block._version ? block._version + 1 : 1,
                        specimens: [],
                        surcharge: 0,
                    };
                }
                return block;
            });

            // If no existing block was updated, add a new block
            if (!updated) {
                // Get Clio mappings for the new class block

                const {
                    clioID,
                    clioClassID,
                    clioDescriptionID,
                    clioDateOfFirstUseID,
                    clioDateOfFirstUseInCommerceID,
                    clioURLID,
                    clioForeignAppCountryID,
                    clioForeignAppNoID,
                    clioForeignAppFilingDateID,
                    clioForeignRegCountryID,
                    clioForeignRegNoID,
                    clioForeignRegDateID,
                    clioForeignRegRenewedDateID,
                    clioForeignRegExpirationDateID,
                } = getClioMappingsForNewClassBlock(prevFormData.classBlocks.length);
                updatedClassBlocks.push({
                    id: Date.now(), // Using Date.now() to generate a unique ID
                    classNo: classId,
                    goodsServices: coreSegments.join(', '),
                    goodsServicesRaw: Object.values(additionalInputs).join(', '),
                    descriptions: [newDescription],
                    dateFirstUse: '',
                    dateFirstUseInCommerce: '',
                    url: '',
                    // specimenFile: '',
                    specimens: [],
                    surcharge: 0,
                    _version: 1,
                    clioID: clioID,
                    clioClassID: clioClassID,
                    clioDescriptionID: clioDescriptionID,
                    clioDateOfFirstUseID: clioDateOfFirstUseID,
                    clioDateOfFirstUseInCommerce: clioDateOfFirstUseInCommerceID,
                    clioURLID,
                    clioForeignAppCountryID,
                    clioForeignAppNoID,
                    clioForeignAppFilingDateID,
                    clioForeignRegCountryID,
                    clioForeignRegNoID,
                    clioForeignRegDateID,
                    clioForeignRegRenewedDateID,
                    clioForeignRegExpirationDateID,
                });
            }
            return { ...prevFormData, classBlocks: updatedClassBlocks };
        });
    };

    const getClioMappingsForNewClassBlock = (index) => {
        const clioIdMapping = {
            1: 964309, // "Goods and Services 2"
            2: 964324, // "Goods and Services 3"
            3: 964339, // "Goods and Services 4"
            4: 964354, // "Goods and Services 5"
            5: 964369, // "Goods and Services 6"
            6: 964384, // "Goods and Services 7"
            7: 964399, // "Goods and Services 8"
            8: 964414, // "Goods and Services 9"
            9: 964429, // "Goods and Services 10"
        };

        const clioClassIdMapping = {
            1: 16752934, //ID for "Class 2"
            2: 16752949, //ID for "Class 3"
            3: 16752964, //ID for "class 4"
            4: 16752979, //ID for "class 5"
            5: 16752994, //ID for "class 6"
            6: 16753009, //ID for "class 7"
            7: 16753024, //ID for "class 8"
            8: 16753039, //ID for "class 9"
            9: 16753054, //ID for "class 10"
        };

        const clioDescriptionMapping = {
            1: 16590469, //ID for "Description 2"
            2: 16590484, //ID for "Description 3"
            3: 16590499, //ID for "Description 4"
            4: 16590514, //ID for "Description 5"
            5: 16590529, //ID for "Description 6"
            6: 16590544, //ID for "Description 7"
            7: 16590559, //ID for "Description 8"
            8: 16590574, //ID for "Description 9"
            9: 16590589, //ID for "Description 10"
        };

        const clioDateofFirstUseMapping = {
            1: 16628314, //ID for "Date of First Use 2"
            2: 16628344, //ID for "Date of First Use 3"
            3: 16628374, //ID for "Date of First Use 4"
            4: 16628404, //ID for "Date of First Use 5"
            5: 16628434, //ID for "Date of First Use 6"
            6: 16628464, //ID for "Date of First Use 7"
            7: 16628494, //ID for "Date of First Use 8"
            8: 16628524, //ID for "Date of First Use 9"
            9: 16628554, //ID for "Date of First Use 10"
        };

        const clioDateofFirstUseInCommerceMapping = {
            1: 16628329, //ID for "Date of First Use in Commerce 2"
            2: 16628359, //ID for "Date of First Use in Commerce 3"
            3: 16628389, //ID for "Date of First Use in Commerce 4"
            4: 16628419, //ID for "Date of First Use in Commerce 5"
            5: 16628449, //ID for "Date of First Use in Commerce 6"
            6: 16628479, //ID for "Date of First Use in Commerce 7"
            7: 16628509, //ID for "Date of First Use in Commerce 8"
            8: 16628539, //ID for "Date of First Use in Commerce 9"
            9: 16628569, //ID for "Date of First Use in Commerce 10"
        };

        const clioURLMapping = {
            1: 17455355, //ID for "URL 1"
            2: 17455370, //ID for "URL 2"
            3: 17455385, //ID for "URL 3"
            4: 17455400, //ID for "URL 4"
            5: 17455415, //ID for "URL 5"
            6: 17455430, //ID for "URL 6"
            7: 17455445, //ID for "URL 7"
            8: 17455460, //ID for "URL 8"
            9: 17455475, //ID for "URL 9"
            10: 17455490, //ID for "URL 10"
        };

        const clioForeignAppCountryMapping = {
            1: 17400410, //ID for "Foreign Application Country 1"
            2: 17400470, //ID for "Foreign Application Country 2"
            3: 17400530, //ID for "Foreign Application Country 3"
            4: 17400560, //ID for "Foreign Application Country 4"
            5: 17400605, //ID for "Foreign Application Country 5"
            6: 17400650, //ID for "Foreign Application Country 6"
            7: 17400695, //ID for "Foreign Application Country 7"
            8: 17400740, //ID for "Foreign Application Country 8"
            9: 17400785, //ID for "Foreign Application Country 9"
            10: 17400845, //ID for "Foreign Application Country 10"
        };

        const clioForeignAppMapping = {
            1: 17400440, //ID for "Foreign App Serial Number 1"
            2: 17400485, //ID for "Foreign App Serial Number 2"
            3: 17400515, //ID for "Foreign App Serial Number 3"
            4: 17400575, //ID for "Foreign App Serial Number 4"
            5: 17400620, //ID for "Foreign App Serial Number 5"
            6: 17400665, //ID for "Foreign App Serial Number 6"
            7: 17400710, //ID for "Foreign App Serial Number 7"
            8: 17400755, //ID for "Foreign App Serial Number 8"
            9: 17400800, //ID for "Foreign App Serial Number 9"
            10: 17400830, //ID for "Foreign App Serial Number 10"
        };

        const clioForeignAppFilingDateMapping = {
            1: 17400455, //ID for "Foreign App Filing Date 1"
            2: 17400500, //ID for "Foreign App Filing Date 2"
            3: 17400545, //ID for "Foreign App Filing Date 3"
            4: 17400590, //ID for "Foreign App Filing Date 4"
            5: 17400635, //ID for "Foreign App Filing Date 5"
            6: 17400680, //ID for "Foreign App Filing Date 6"
            7: 17400725, //ID for "Foreign App Filing Date 7"
            8: 17400770, //ID for "Foreign App Filing Date 8"
            9: 17400815, //ID for "Foreign App Filing Date 9"
            10: 17400860, //ID for "Foreign App Filing Date 10"
        };

        const clioRegFieldSetMapping = {
            1: 1006655, // "Foreign Registration 1"
            2: 1006670, // "Foreign Registration 2"
            3: 1006685, // "Foreign Registration 3"
            4: 1006700, // "Foreign Registration 4"
            5: 1006715, // "Foreign Registration 5"
            6: 1006730, // "Foreign Registration 6"
            7: 1006745, // "Foreign Registration 7"
            8: 1006760, // "Foreign Registration 8"
            9: 1006775, // "Foreign Registration 9"
            10: 1006790, // "Foreign Registration 10"
        };

        const clioForeignRegCountryMapping = {
            1: 17407700, //ID for "Foreign Reg Country 1"
            2: 17407715, //ID for "Foreign Reg Country 2"
            3: 17407730, //ID for "Foreign Reg Country 3"
            4: 17407760, //ID for "Foreign Reg Country 4"
            5: 17407775, //ID for "Foreign Reg Country 5"
            6: 17407790, //ID for "Foreign Reg Country 6"
            7: 17407820, //ID for "Foreign Reg Country 7"
            8: 17407835, //ID for "Foreign Reg Country 8"
            9: 17407850, //ID for "Foreign Reg Country 9"
            10: 17407865, //ID for "Foreign Reg Country 10"
        };

        const clioForeignRegMapping = {
            1: 17407880, //ID for "Foreign Reg Number 1"
            2: 17407895, //ID for "Foreign Reg Number 2"
            3: 17407910, //ID for "Foreign Reg Number 3"
            4: 17407925, //ID for "Foreign Reg Number 4"
            5: 17407940, //ID for "Foreign Reg Number 5"
            6: 17407955, //ID for "Foreign Reg Number 6"
            7: 17407970, //ID for "Foreign Reg Number 7"
            8: 17407985, //ID for "Foreign Reg Number 8"
            9: 17408000, //ID for "Foreign Reg Number 9"
            10: 17408015, //ID for "Foreign Reg Number 10"
        };

        const clioForeignRegDateMapping = {
            1: 17408030, //ID for "Foreign Reg Date 1"
            2: 17408045, //ID for "Foreign Reg Date 2"
            3: 17408060, //ID for "Foreign Reg Date 3"
            4: 17408075, //ID for "Foreign Reg Date 4"
            5: 17408090, //ID for "Foreign Reg Date 5"
            6: 17408105, //ID for "Foreign Reg Date 6"
            7: 17408120, //ID for "Foreign Reg Date 7"
            8: 17408135, //ID for "Foreign Reg Date 8"
            9: 17408150, //ID for "Foreign Reg Date 9"
            10: 17408165, //ID for "Foreign Reg Date 10"
        };

        const clioForeignRegRenewedMapping = {
            1: 17408180, //ID for "Foreign Reg Renewed Date 1"
            2: 17408195, //ID for "Foreign Reg Renewed Date 2"
            3: 17408210, //ID for "Foreign Reg Renewed Date 3"
            4: 17408225, //ID for "Foreign Reg Renewed Date 4"
            5: 17408240, //ID for "Foreign Reg Renewed Date 5"
            6: 17408255, //ID for "Foreign Reg Renewed Date 6"
            7: 17408270, //ID for "Foreign Reg Renewed Date 7"
            8: 17408285, //ID for "Foreign Reg Renewed Date 8"
            9: 17408300, //ID for "Foreign Reg Renewed Date 9"
            10: 17408315, //ID for "Foreign Reg Renewed Date 10"
        };

        const clioForeignRegExpirationMapping = {
            1: 17408330, //ID for "Foreign Reg Expiration Date 1"
            2: 17408345, //ID for "Foreign Reg Expiration Date 2"
            3: 17408360, //ID for "Foreign Reg Expiration Date 3"
            4: 17408375, //ID for "Foreign Reg Expiration Date 4"
            5: 17408390, //ID for "Foreign Reg Expiration Date 5"
            6: 17408405, //ID for "Foreign Reg Expiration Date 6"
            7: 17408420, //ID for "Foreign Reg Expiration Date 7"
            8: 17408435, //ID for "Foreign Reg Expiration Date 8"
            9: 17408450, //ID for "Foreign Reg Expiration Date 9"
            10: 17408465, //ID for "Foreign Reg Expiration Date 10"
        };

        return {
            clioID: clioIdMapping[index] || 1008725,
            clioClassID: clioClassIdMapping[index] || 16752904,
            clioDescriptionID: clioDescriptionMapping[index] || 16590184,
            clioDateOfFirstUseID: clioDateofFirstUseMapping[index] || 16628284,
            clioDateOfFirstUseInCommerceID: clioDateofFirstUseInCommerceMapping[index] || 16628299,
            clioURLID: clioURLMapping[index + 1] || 17455355,
            clioForeignAppCountryID: clioForeignAppCountryMapping[index + 1] || 17400410,
            clioForeignAppNoID: clioForeignAppMapping[index + 1] || 17400440,
            clioForeignAppFilingDateID: clioForeignAppFilingDateMapping[index + 1] || 17400455,
            clioForeignRegCountryID: clioForeignRegCountryMapping[index + 1] || 17407700,
            clioForeignRegNoID: clioForeignRegMapping[index + 1] || 17407880,
            clioForeignRegDateID: clioForeignRegDateMapping[index + 1] || 17408030,
            clioForeignRegRenewedDateID: clioForeignRegRenewedMapping[index + 1] || 17408180,
            clioForeignRegExpirationDateID: clioForeignRegExpirationMapping[index + 1] || 17408330,
        };
    };

    const removeDescription = (classBlockId, descId) => {
        setFormData(prevFormData => {
            const classBlockIndex = prevFormData.classBlocks.findIndex(block => block.id === classBlockId);
            if (classBlockIndex === -1) {
                console.error('ClassBlock not found');
                return prevFormData; // Return unchanged state if class block not found
            }

            const classBlock = prevFormData.classBlocks[classBlockIndex];
            const updatedDescriptions = classBlock.descriptions.filter(desc => desc.id !== descId);

            // If there are no descriptions left after filtering, remove the entire class block
            if (updatedDescriptions.length === 0) {
                const updatedClassBlocks = prevFormData.classBlocks.filter((_, index) => index !== classBlockIndex);
                return { ...prevFormData, classBlocks: updatedClassBlocks };
            } else {
                // Otherwise, update the descriptions of the class block
                const updatedClassBlock = { ...classBlock, descriptions: updatedDescriptions };
                const updatedClassBlocks = [...prevFormData.classBlocks];
                updatedClassBlocks[classBlockIndex] = updatedClassBlock;
                return { ...prevFormData, classBlocks: updatedClassBlocks };
            }
        });
    };

    const handleOwnerTypeChange = (selectedOwnerType) => {
        setOwnerType(selectedOwnerType);
        // if(selectedOwnerType === 'previous') {
        //   setIsTransferChecked(true);
        // } else {
        //   setIsTransferChecked(false);
        // }
    };

    const handleAppTypeUpdate = (updatedFields) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            ...updatedFields,
        }));
    };

    //Function to advance form step
    const nextStep = () => {
        // Set attemptedSubmit to true to indicate a submission attempt has been made
        setAttemptedSubmit(true);

        if (stepIsValid[currentStep]) {
            if (currentStep < 6) {
                setCurrentStep(currentStep + 1);
                // Set attemptedSubmit to false for next form step
                setAttemptedSubmit(false);
            }
        }
    };

    //Function to decrease form step
    const previousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const hasSpecimenFiles = (formData) => {
        return formData.classBlocks.some(classBlock => classBlock.specimens.length > 0);
    };

    const hasUploadedDocuments = () => {
        const hasDesignOrSoundFiles = formData.designMarkFile.length > 0 || formData.soundMarkFile.length > 0;
        const hasSpecimens = hasSpecimenFiles(formData);
        return hasDesignOrSoundFiles || hasSpecimens;
    };

    // //Function to caclute total estimated filing fee based on number of classes, and attorney fee based on selected search option
    // const calculateTotalFee = () => {

    //     let attyFee = parseFloat(formData.attyFee.replace('$', '')) || 0;
    //     let filingFeePerClass = 0;

    //     // Find the correct service fee based on the attorney fee amount
    //     const selectedService = pricingData.find(category => category.category === "Trademark Applications")?.services
    //         .find(service => formData.attyFee.includes("375") ? service.service.includes("Knockout Search") : service.service.includes("without search"));

    //     if (selectedService) {
    //         // Extract the numerical value from the government fee string (e.g., "$350 (per class)")
    //         filingFeePerClass = parseFloat(selectedService.govtFee.replace(/[^0-9.]/g, '')) || 0;
    //     }

    //     // Calculate the total filing fee including surcharges
    //     let totalFilingFee = formData.classBlocks.reduce((acc, block) => {
    //         let surcharge = parseFloat(block.surcharge) || 0;
    //         return acc + filingFeePerClass + surcharge;
    //     }, 0);

    //     // Total fee includes attorney fee + calculated filing fees
    //     let totalFee = attyFee + totalFilingFee;

    //     // setTotalEstimatedFee(totalFee);

    //     return {
    //         totalFee: totalFee.toFixed(2),
    //         // totalFilingFee: totalFilingFee.toFixed(2),
    //         // filingFeePerClass: filingFeePerClass.toFixed(2)
    //     };
    // };

    // Revised function to caclute total estimated filing fee based on number of classes, and attorney fee based on selected search option
    // This function will apply a surcharge to ALL class blocks if there is a surcharge applicable to ANY of the class blocks, per filing experience on 02/24/2025
    const calculateTotalFee = () => {
        const attyFee = parseFloat(formData.attyFee?.replace('$', '')) || 0;
        let filingFeePerClass = 0;

        const attyFeeString = String(formData.attyFee || '');
        const selectedService = pricingData
            .find(category => category.category === "Trademark Applications")
            ?.services.find(service =>
                attyFeeString.includes("375")
                    ? service.service.includes("Knockout Search")
                    : service.service.includes("without search")
            );

        if (selectedService) {
            filingFeePerClass = parseFloat(selectedService.govtFee.replace(/[^0-9.]/g, '')) || 0;
        }

        const classCount = formData.classBlocks.length;

        // Check if any class has a surcharge
        const surchargeApplies = formData.classBlocks.some(
            block => parseFloat(block.surcharge || 0) > 0
        );

        // Find the per-class surcharge (apply to all if any)
        const surchargePerClass = surchargeApplies
            ? Math.max(...formData.classBlocks.map(block => parseFloat(block.surcharge || 0)))
            : 0;

        const baseFees = classCount * filingFeePerClass;
        const totalSurcharge = classCount * surchargePerClass;
        const totalFilingFee = baseFees + totalSurcharge;
        const totalFee = attyFee + totalFilingFee;

        // console.log('--- FEE DEBUG ---');
        // console.log('classCount:', classCount);
        // console.log('filingFeePerClass:', filingFeePerClass);
        // console.log('surchargeApplies:', surchargeApplies);
        // console.log('All surcharges:', formData.classBlocks.map(b => b.surcharge));
        // console.log('surchargePerClass:', surchargePerClass);
        // console.log('baseFees:', baseFees);
        // console.log('totalSurcharge:', totalSurcharge);
        // console.log('totalFilingFee:', totalFilingFee);
        // console.log('attorneyFee:', attyFee);
        // console.log('totalFee:', totalFee);
        // console.log('------------------');
        return {
            totalFee: totalFee.toFixed(2),
            totalFilingFee: totalFilingFee.toFixed(2),
            filingFeePerClass: filingFeePerClass.toFixed(2),
            surchargeAmount: totalSurcharge.toFixed(2),
            surchargePerClass: surchargePerClass.toFixed(2),
            surchargeApplies
        };
    };

    const formatCurrency = (amount) => {
        const num = parseFloat(amount);
        if (isNaN(num)) return "$0.00";
        return num.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
        });
    };

    const handleAgree = () => {
        setIsEngagementLetterSigned(true);
        setShowEngagementLetter(false);

        //Calculate total amount for payment
        const { totalFee } = calculateTotalFee();

        //Trigger PaymentPage
        setShowPaymentModal(true);

        //submitForm();  // Call submitForm again to proceed with the submission after the letter is signed
    };

    useEffect(() => {
        if (isPaymentSuccessful) {
            submitForm();
        }
    }, [isPaymentSuccessful]);  // Triggers only when payment is successful


    const submitForm = async () => {
        setAttemptedSubmit(true);
        // if(!stepIsValid[currentStep]) {
        //   return;
        // }

        if (!isEngagementLetterSigned) {
            setShowEngagementLetter(true);
            return;
        }

        if (isEngagementLetterSigned && !isPaymentSuccessful) {
            setShowPaymentModal(true);
            return;
        }

        if (!isPaymentSuccessful) {
            return;
        } else {
            setShowPaymentModal(false);
        }

        //Display submission form
        setShowSubmissionStatus(true);

        //Set status for conflict check
        await setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'inProcess' }));

        // Generate the PDF of the signed engagement letter
        const pdfFile = await generateEngagementPDF();

        // Add the PDF file to formData and proceed only after ensuring formData is updated
        await new Promise((resolve) => {
            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData, pdfFile };
                console.log('Updated formData w/ Engagement Letter: ', updatedFormData);
                resolve();
                return updatedFormData;
            });
        });

        //Check for conflicts found during previous check
        if (formData.potentialConflicts.length > 0) {

            // await setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'conflictFound'}));

        } else {
            //'Run' conflicts check
            const randomInterval = Math.floor(Math.random() * (6 - 2 + 1)) + 3;
            await wait(randomInterval * 1000);
            await setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'complete' }));
        }

        // Provide a download link to the client
        const pdfDownloadUrl = URL.createObjectURL(pdfFile);
        setEngagementLetterUrl(pdfDownloadUrl);

        // Capture form content
        const formContent = generateFormContent(formData);
        setCapturedFormData(formContent);

        const formPDF = await generateFormPDF(formContent);

        // Provide a download link to the client
        const formContentDownloadURL = URL.createObjectURL(formPDF);

        // Check conditions for creating a company
        let ownerCompanyID;
        let contactID;
        let contactCompanyID;
        let ownerID;
        let matterId;
        let matterNo;
        let specimensFolder;
        let USPTOFolder;
        let logosFolder;


        //REVISED LOGIC TO USE CLIENT LOGIN FOR CONTACT INFORMATION

        // //Create contact company Clio contact if needed
        // if(formData.contactCompany && !formData.contactCompanyID) {
        //   if(formData.potentialConflicts.length === 0) {
        //     await setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'inProcess'}));
        //   }
        //   try {
        //     contactCompanyID = await createClioCompany();

        //     // Now call createClioPerson with the returned companyID if necessary
        //     if(!formData.contactID || formData.contactID === null) {
        //       contactID = await createClioPerson(contactCompanyID);
        //     }
        //   } catch (error) {
        //     console.error("Error in creating contacts:", error);
        //   }
        // } else {
        //   // If company creation isn't needed, just create person if necessary
        //   if(!formData.contactID || formData.contactID === null) {
        //     contactID = await createClioPerson(contactCompanyID);
        //   } 
        // }



        //Create owner company Clio contact if needed
        // if ((formData.ownerCompany !== '' || formData.ownerCompany !== null) && (formData.ownerCompanyID !== '' || formData.ownerCompanyID !== null)) {
        if (formData.ownerCompany && !formData.ownerCompanyID) {
            if (formData.ownerCompany !== formData.contactCompany && formData.ownerCompanyID !== formData.contactCompanyID) {
                try {
                    ownerCompanyID = await createClioOwnerCompany();
                    // Now call createClioOwnerPerson with the returned companyID if there is a first name value in the "Owner Contact" field and contact does not exist in Clio
                    if (formData.ownerFirstName && !formData.ownerID) {
                        if (formData.contactFirstName === formData.ownerFirstName && formData.contactLastName === formData.ownerLastName) {
                            ownerID = contactID;
                        } else {
                            ownerID = await createClioOwnerPerson(ownerCompanyID);
                        }
                    }
                } catch (error) {
                    console.error("Error in creating contacts:", error);
                }
            } else {
                ownerCompanyID = contactCompanyID;
            }
        } else {
            // If company creation isn't needed, just create person if necessary
            if (formData.ownerFirstName && !formData.ownerID) {
                if (formData.contactFirstName === formData.ownerFirstName && formData.contactLastName === formData.ownerLastName) {
                    ownerID = contactID;
                } else {
                    ownerID = await createClioOwnerPerson(ownerCompanyID);
                }
            }
        }

        // Brief delay to wait for contactID & ownerID, if necessary
        if (!formData.contactID || !contactID || !formData.ownerID || !ownerID) {
            await wait(1000); //wait for 1 second
        }

        // if(contactID || contactCompanyID || ownerID || ownerCompanyID) {
        //   setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'complete'}));
        // }

        if (submissionStatus.createContactProfile === 'inProcess') {
            setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'complete' }));
        }

        if (formData.potentialConflicts.length === 0) {
            await setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'complete' }));
            setSubmissionStatus(prevStatus => ({ ...prevStatus, createNewMatter: 'inProcess' }));
        }

        //Create new Clio matter
        try {
            const { matterId: newMatterId, matterNo: newMatterNo } = await createClioMatter(ownerID, ownerCompanyID, contactID, contactCompanyID);
            matterId = newMatterId;
            matterNo = newMatterNo;
        } catch (error) {
            console.error("Error creating Clio matter:", error);
        }

        //Set status for uplodading documents, if any need to be uploaded
        if (formData.designMarkFile || formData.soundMarkFile || hasSpecimenFiles(formData)) {
            if (formData.potentialConflicts.length === 0) {
                await setSubmissionStatus(prevStatus => ({ ...prevStatus, uploadingDocuments: 'inProcess' }));
            }
        }

        //Add appropriate document storage folders to newly created matter
        if (matterId) {
            try {
                specimensFolder = await createClioMatterFolders(matterId, "Specimens");
            } catch (error) {
                console.error("Error adding Specimens folder to new Clio matter:", error);
            }

            try {
                USPTOFolder = await createClioMatterFolders(matterId, "USPTO Filing");
            } catch (error) {
                console.error("Error adding USPTO Filing folder to new Clio matter:", error);
            }

            if (formData.applicationType === 'design') {
                try {
                    logosFolder = await createClioMatterFolders(matterId, "Design Logos");
                } catch (error) {
                    console.error("Error adding Design Logos folder to new Clio matter:", error);
                }
            }

            await wait(1000);
            if (formData.potentialConflicts.length === 0) {
                setSubmissionStatus(prevStatus => ({ ...prevStatus, createNewMatter: 'complete' }));
            }
        } else {
            if (formData.potentialConflicts.length === 0) {
                setSubmissionStatus(prevStatus => ({ ...prevStatus, createNewMatter: 'complete' }));
                setSubmissionStatus(prevStatus => ({ ...prevStatus, uploadingDocuments: 'complete' }));
                setIsFormSubmitted(true);
                setSubmissionStatus(prevStatus => ({ ...prevStatus, submissionComplete: 'complete' }));
                return;
            }
        }

        // Upload files to Clio
        try {
            await clioFileUpload(matterId, specimensFolder, USPTOFolder, logosFolder, pdfFile,);
        } catch (error) {
            console.error('Error uploading documents to Clio:', error);
        }

        if (formData.potentialConflicts.length === 0 && (formData.designMarkFile || formData.soundMarkFile || hasSpecimenFiles(formData))) {
            setSubmissionStatus(prevStatus => ({ ...prevStatus, uploadingDocuments: 'complete' }));
        }

        // if(formData.designMarkFile || formData.soundMarkFile || hasSpecimenFiles(formData)) {

        //   //Upload design logo/sound file to new matter, if applicable
        //   if(formData.designMarkFile || formData.soundMarkFile){
        //     try {
        //       await clioFileUpload(matterId, specimensFolder, USPTOFolder, logosFolder);
        //     } catch (error) {
        //       console.error("Error uploading documents to Clio:", error);
        //     }
        //   } else {
        //     //Upload specimen files for word mark application, if applicable
        //     try {
        //       await clioFileUpload(matterId, specimensFolder, USPTOFolder);
        //     } catch (error) {
        //       console.error("Error uploading documents to Clio:", error);
        //     }
        //   }
        //   if(formData.potentialConflicts.length === 0) {
        //     setSubmissionStatus(prevStatus => ({ ...prevStatus, uploadingDocuments: 'complete'}));
        //   }
        // } else {
        //   try {
        //     await clioFileUpload(matterId);
        //   } catch (error) {
        //     console.error("Error uploading engagement letter to Clio:", error);
        //   }
        // }

        // //Reset form data
        // setFormData(initialFormData);
        if (formData.potentialConflicts.length === 0) {
            setIsFormSubmitted(true);
            setSubmissionStatus(prevStatus => ({ ...prevStatus, submissionComplete: 'complete' }));
            sendEmail('tmApplication', matterNo)
        } else {
            setIsFormSubmitted(true);
            sendEmail('tmApplication', matterNo)
            const randomInterval = Math.floor(Math.random() * (6 - 2 + 1)) + 3;
            await wait(randomInterval * 1000);
            await setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'conflictFound' }));
        }
        console.log('now attempting to send confirmation email to user re new trademark application submission');
        reportUserEmail(formPDF, pdfFile);
    };

    function needsContactProfileCreation(formData) {
        return !formData.contactID && !formData.contactCompanyID && !formData.ownerID;
    }

    //Close submission form
    const closeSubmission = () => {
        setShowSubmissionStatus(false);

        //Reset form data
        setFormData(initialFormData);

        toggleForm();
    }

    // Function to perform conflict check
    const fetchConflictCheck = async () => {
        try {
            // Determine the search term based on form data
            let searchTerm = '';
            if (formData.wordMark.length > 0) {
                searchTerm = formData.wordMark;
            } else if (formData.markLiteralElement.length > 0) {
                searchTerm = formData.markLiteralElement;
            }

            // Only proceed if there is a search term
            if (searchTerm.length > 0) {
                const { data, error } = await supabaseConflictCheck
                    .from('sow_matters')
                    .select('*')
                    .ilike('title', searchTerm); //search for exact match

                if (error) throw error;

                // Update the formData with potential conflicts
                setFormData(prevFormData => ({
                    ...prevFormData,
                    potentialConflicts: data,
                }));
                // console.log(data);
            } else {
                // Handle the case where there is no search term
                console.log("No search term provided.");
                // Optionally update the state to reflect that no data was fetched
            }
        } catch (error) {
            console.error('Error fetching conflict data for step 3:', error.message);
        }
    };

    // useEffect to trigger the fetch conflict check on step 4
    useEffect(() => {
        if (currentStep === 4) {
            fetchConflictCheck();
        }
    }, [currentStep]);

    // // Debounce function to delay fetching the contact info
    // const debounceFetchContactInfo = debounce(() => {
    //   fetchClioContact(formData.contactEmail, 'contactID');
    //   fetchClioContact(formData.ownerEmail, 'ownerID');
    // }, 1000); // 500 ms delay

    const fetchClioContact = async (emailField, idField) => {

        const encodedEmail = encodeURIComponent(formData[emailField]);
        // const url = `http://localhost:5000/api/clio/contacts?query=${encodedEmail}`;
        const url = `${process.env.REACT_APP_DOMAIN}/api/clio/contacts?query=${encodedEmail}`;
        // console.log('Starting fetchClioContact:\n', 'Domain:', `${process.env.REACT_APP_DOMAIN}`, '\nSearching for: ', `${encodedEmail}`);
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const jsonResponse = await response.json();

                if (jsonResponse.data && jsonResponse.data.length > 0) {
                    let companyContact = jsonResponse.data.find(contact => contact.type === "Company");
                    let personContact = jsonResponse.data.find(contact => contact.type === "Person");

                    // Initialize an object to hold updates
                    let updates = {};

                    if (companyContact) {
                        updates.contactCompanyID = companyContact.id;
                    }

                    if (personContact) {
                        updates.contactID = personContact.id;
                    }

                    // Apply updates to formData if any key-value pairs exist in the updates object
                    if (Object.keys(updates).length > 0) {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            ...updates
                        }));
                        setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'complete' }));
                    } else {
                        // Handle case where no appropriate contacts are found
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            [idField]: null,
                        }));
                    }
                } else {
                    // No contacts found
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        [idField]: null,
                    }));
                }
            } else {
                console.error('Failed to fetch contacts from Clio.', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching contact from Clio:', error);
        }
    };

    //Removing this useEffect due to implementation of client login to submit applications.
    // // useEffect to trigger the fetch clioContactQuery on step 2
    // useEffect(() => {
    //     const debouncedFetchContact = debounce(async () => {
    //         if (currentStep === 2 && formData.contactEmail) {
    //             // console.log("contactID:",formData.contactID);
    //             fetchClioContact('contactEmail', 'contactID');
    //         }
    //     }, 500);
    //     debouncedFetchContact();
    //     return () => debouncedFetchContact.cancel();
    // }, [currentStep, formData.contactEmail]); // Removed setFormData from the dependency array to avoid warnings

    const fetchClioOwner = async (emailField) => {
        const encodedEmail = encodeURIComponent(formData[emailField]);
        const url = `${process.env.REACT_APP_DOMAIN}/api/clio/contacts?query=${encodedEmail}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const jsonResponse = await response.json();
                if (jsonResponse.data && jsonResponse.data.length > 0) {
                    let companyContact = jsonResponse.data.find(contact => contact.type === "Company");
                    let personContact = jsonResponse.data.find(contact => contact.type === "Person");

                    // Update formData based on whether a company or person contact is found
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        ownerID: personContact ? personContact.id : null, // Update with Person ID if found
                        ownerCompanyID: companyContact ? companyContact.id : null, // Update with Company ID if found
                    }));
                } else {
                    // No contacts found, reset both IDs
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        ownerID: null,
                        ownerCompanyID: null,
                    }));
                }
            } else {
                console.error('Failed to fetch contacts from Clio.');
            }
        } catch (error) {
            console.error('Error fetching contact from Clio:', error);
        }
    };

    //Removing this useEffect due to implementation of client login to submit applications.
    // // useEffect to trigger the fetch clioContactQuery on step 3 - check for existing owner contact
    // useEffect(() => {
    //     const debouncedFetchOwner = debounce(async () => {
    //         if (currentStep === 3 && formData.ownerEmail) {
    //             // console.log("ownerID:",formData.ownerID);
    //             fetchClioOwner('ownerEmail', 'ownerID');
    //         }
    //     }, 1500);
    //     debouncedFetchOwner();

    //     return () => debouncedFetchOwner.cancel();
    // }, [currentStep, formData.ownerEmail]); // Removed setFormData from the dependency array to avoid warnings

    const createClioPerson = async (companyID) => {
        const contactInfo = {
            firstName: formData.contactFirstName,
            middleName: formData.contactMiddleName,
            lastName: formData.contactLastName,
            company: formData.contactCompanyID,
            companyID: companyID ? companyID : '',
            address1: formData.contactAddress1,
            address2: formData.contactAddress2,
            city: formData.contactCity,
            state: formData.contactState,
            zip: formData.contactZip,
            country: formData.contactCountry,
            email: formData.contactEmail,
        };

        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
        const clioContact = {
            data: {
                "addresses": [
                    {
                        "name": "Billing",
                        "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
                        "city": `${contactInfo.city}`,
                        "province": `${contactInfo.state}`,
                        "postal_code": `${contactInfo.zip}`,
                        "country": `${contactInfo.country}`,
                    }
                ],
                "clio_connect_email": `${contactInfo.email}`,
                "company": {
                    "id": contactInfo.companyID ? contactInfo.companyID : ''
                },
                "email_addresses": [
                    {
                        "name": contactInfo.companyID ? "Work" : "Home",
                        "address": `${contactInfo.email}`,
                        "default_email": true,
                    }
                ],
                "first_name": `${contactInfo.firstName}`,
                "last_name": `${contactInfo.lastName}`,
                "middle_name": `${contactInfo.middleName}`,
                "type": "Person",
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(clioContact)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data && data.data.id) {
                setFormData(prevFormData => ({ ...prevFormData, contactID: data.data.id }));
            }

            // return data & data.data.id ? data.data.id : null;
            return data.data.id;

        } catch (error) {
            console.error('Error creating new Clio contact:', error);
        }
    };

    const createClioOwnerPerson = async (ownerCompanyID) => {
        const contactInfo = {
            firstName: formData.ownerFirstName,
            middleName: formData.ownerMiddleName,
            lastName: formData.ownerLastName,
            companyID: ownerCompanyID ? ownerCompanyID : '',
            address1: formData.ownerAddress1,
            address2: formData.ownerAddress2,
            city: formData.ownerCity,
            state: formData.ownerState,
            zip: formData.ownerZip,
            country: formData.ownerCountry,
            email: formData.ownerEmail,
        };

        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
        const clioContact = {
            data: {
                "addresses": [
                    {
                        "name": "Billing",
                        "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
                        "city": `${contactInfo.city}`,
                        "province": `${contactInfo.state}`,
                        "postal_code": `${contactInfo.zip}`,
                        "country": `${contactInfo.country}`,
                    }
                ],
                "clio_connect_email": `${contactInfo.email}`,
                "company": {
                    "id": contactInfo.companyID ? contactInfo.companyID : ''
                },
                "email_addresses": [
                    {
                        "name": contactInfo.companyID ? "Work" : "Home",
                        "address": `${contactInfo.email}`,
                        "default_email": true,
                    }
                ],
                "first_name": `${contactInfo.firstName}`,
                "last_name": `${contactInfo.lastName}`,
                "middle_name": `${contactInfo.middleName}`,
                "type": "Person",
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(clioContact)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data && data.data.id) {
                setFormData(prevFormData => ({ ...prevFormData, contactID: data.data.id }));
            }

            return data && data.data.id ? data.data.id : null
        } catch (error) {
            console.error('Error creating new Clio contact:', error);
        }
    };

    const createClioCompany = async () => {
        const contactInfo = {
            firstName: formData.contactFirstName,
            middleName: formData.contactMiddleName,
            lastName: formData.contactLastName,
            company: formData.contactCompany,
            address1: formData.contactAddress1,
            address2: formData.contactAddress2,
            city: formData.contactCity,
            state: formData.contactState,
            zip: formData.contactZip,
            country: formData.contactCountry,
            email: formData.contactEmail,
        };

        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
        const clioContact = {
            data: {
                "addresses": [
                    {
                        "name": "Billing",
                        "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
                        "city": `${contactInfo.city}`,
                        "province": `${contactInfo.state}`,
                        "postal_code": `${contactInfo.zip}`,
                        "country": `${contactInfo.country}`,
                    }
                ],
                "clio_connect_email": `${contactInfo.email}`,
                "email_addresses": [
                    {
                        "name": contactInfo.company ? "Work" : "Home",
                        "address": `${contactInfo.email}`,
                        "default_email": true,
                    }
                ],
                "name": `${contactInfo.company}`,
                "type": "Company",
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(clioContact)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data && data.data.id) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    contactCompanyID: data.data.id
                }));
            }

            return data && data.data.id ? data.data.id : null;

        } catch (error) {
            console.error('Error creating new Clio company contact:', error);
        }
    };

    const createClioOwnerCompany = async () => {
        const contactInfo = {
            firstName: formData.ownerFirstName,
            middleName: formData.ownerMiddleName,
            lastName: formData.ownerLastName,
            company: formData.ownerCompany,
            address1: formData.ownerAddress1,
            address2: formData.ownerAddress2,
            city: formData.ownerCity,
            state: formData.ownerState,
            zip: formData.ownerZip,
            country: formData.ownerCountry,
            email: formData.ownerEmail,
            CompanyType: formData.ownerCompanyType,
            StateIncorporated: formData.ownerCompanyStateIncorporation,
            companyCountry: formData.ownerCompanyCountry,
        };

        const customFieldValues = [];

        if (contactInfo.CompanyType) {
            customFieldValues.push(
                {
                    "value": contactInfo.CompanyType, //Owner company type
                    "custom_field":
                    {
                        "id": 17192195 //"Company Type" - contact custom field
                    }
                },
                {
                    "value": contactInfo.StateIncorporated, //Owner State of Incorporation
                    "custom_field":
                    {
                        "id": 17757455 //"State of Incorporation" - contact custom field
                    }
                },
                {
                    "value": contactInfo.companyCountry, //Owner State of Incorporation
                    "custom_field":
                    {
                        "id": 18363890 //"Country of Incorporation" - contact custom field
                    }
                },
            )
        };

        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
        const clioContact = {
            data: {
                "addresses": [
                    {
                        "name": "Billing",
                        "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
                        "city": `${contactInfo.city}`,
                        "province": `${contactInfo.state}`,
                        "postal_code": `${contactInfo.zip}`,
                        "country": `${contactInfo.country}`,
                    }
                ],
                "clio_connect_email": `${contactInfo.email}`,
                "email_addresses": [
                    {
                        "name": contactInfo.company ? "Work" : "Home",
                        "address": `${contactInfo.email}`,
                        "default_email": true,
                    }
                ],
                "name": `${contactInfo.company}`,
                "type": "Company",
                "custom_field_values": customFieldValues,
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(clioContact)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data && data.data.id) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    ownerCompanyID: data.data.id
                }));
            }

            return data && data.data.id ? data.data.id : null;

        } catch (error) {
            console.error('Error creating new Clio company contact:', error);
        }
    };

    const createClioMatter = async (ownerID, ownerCompanyID, contactID, contactCompanyID) => {
        let relationships = []; //Initialize relationships array

        const customFieldSetAssociations = [
            {
                "display_order": 0,
                "custom_field_set": {
                    "id": formData.applicationType === 'word' ? 964264 : formData.applicationType === 'design' ? 964279 : null
                }
            },
        ];

        // const firstFormattedDescription = formatDescriptions(formData.classBlocks[0].descriptions); // Apply formatting to 1st description
        const customFieldValues = [
            {
                "value": 9416269, //"Principal"
                "custom_field":
                {
                    "id": 16055509 //Register Type
                }
            },
            {
                "value": 9416659, //"Application Requested"
                "custom_field":
                {
                    "id": 16055884 //Trademark Status
                }
            },
            {
                "value": formData.teasPlus ? 9892024 : 9892039, //TEAS Plus if "teasPlus" true, TEAS Standard if "teasPlus" is false
                "custom_field":
                {
                    "id": 16962034 //TEAS Type
                }
            },
        ]

        if (formData.applicationType === 'word') {
            customFieldValues.push(
                {
                    "value": 9417019, //"Word Mark"
                    "custom_field":
                    {
                        "id": 16055959 //Mark Type
                    }
                },
                {
                    "value": `${formData.wordMark}`, //"Word Mark"
                    "custom_field":
                    {
                        "id": 16055974 //Mark
                    }
                }
            )
        } else if (formData.applicationType === 'design') {
            customFieldValues.push(
                {
                    "value": 9417034, //"Design Logo"
                    "custom_field":
                    {
                        "id": 16055959 //Mark Type
                    }
                },
                {
                    "value": `${formData.markLiteralElement}`, //"Literal Element"
                    "custom_field":
                    {
                        "id": 16628179 //Literal Element
                    }
                },
                {
                    "value": `${formData.markColorClaim}`, //"Color Claim"
                    "custom_field":
                    {
                        "id": 16628194 //Color Claim
                    }
                },
            )
        }

        if (((formData.contactID || contactID) && (formData.contactCompanyID || contactCompanyID)) && (formData.contactID || contactID) !== (formData.contactCompanyID || contactCompanyID)) {
            relationships.push({
                "description": "Contact",
                "contact":
                    { "id": formData.contactID || contactID }
            });
        }

        // Determine if there's a distinct owner that is neither the contact person nor the contact company.
        const isOwnerDistinct = ((ownerID || formData.ownerID) && ![(contactID || formData.contactID), (contactCompanyID || formData.contactCompanyID)].includes((ownerID || formData.ownerID))) ||
            ((ownerCompanyID || formData.ownerCompanyID) && ![(contactID || formData.contactID), (contactCompanyID || formData.contactCompanyID)].includes((ownerCompanyID || formData.ownerCompanyID)));

        if (isOwnerDistinct) {
            // Determine the appropriate owner ID to use (prefer the company ID over the individual's ID if both are provided and distinct).
            // const distinctOwnerID = ownerCompanyID && ownerCompanyID !== ownerID ? ownerCompanyID : ownerID;
            let distinctOwnerID = ownerCompanyID || formData.ownerCompanyID || ownerID || formData.ownerID;

            relationships.push({
                "description": "Trademark Owner",
                "contact": { "id": distinctOwnerID }
            });
        }

        // Dynamically add class blocks
        formData.classBlocks.forEach((block, index) => {
            customFieldSetAssociations.push({
                // "display_order": index,
                "custom_field_set": {
                    "id": block.clioID,
                }
            });

            const formattedDescriptions = formatDescriptions(block.descriptions); // Apply formatting to descriptions

            customFieldValues.push(
                {
                    "value": block.classNo,
                    "custom_field":
                    {
                        "id": block.clioClassID,
                    }
                },
                {
                    "value": formattedDescriptions,
                    "custom_field":
                    {
                        "id": block.clioDescriptionID,
                    }
                },
                {
                    "value": block.dateFirstUse,
                    "custom_field":
                    {
                        "id": block.clioDateOfFirstUseID,
                    }
                },
                {
                    "value": block.dateFirstUseInCommerce,
                    "custom_field":
                    {
                        "id": block.clioDateOfFirstUseInCommerce,
                    }
                },
                {
                    "value": block.url,
                    "custom_field":
                    {
                        "id": block.clioURLID,
                    }
                },
                {
                    "value": block.foreignAppNo,
                    "custom_field":
                    {
                        "id": block.clioForeignAppNoID
                    }
                },
                {
                    "value": block.foreignAppNo ? block.foreignCountry : '',
                    "custom_field":
                    {
                        "id": block.clioForeignAppCountryID
                    }
                },
                {
                    "value": block.foreignAppFilingDate,
                    "custom_field":
                    {
                        "id": block.clioForeignAppFilingDateID
                    }
                },
                {
                    "value": block.foreignRegNo,
                    "custom_field":
                    {
                        "id": block.clioForeignRegNoID
                    }
                },
                {
                    "value": block.clioForeignRegNo ? block.foreignCountry : '',
                    "custom_field":
                    {
                        "id": block.clioForeignRegCountryID
                    }
                },
                {
                    "value": block.foreignRegDate,
                    "custom_field":
                    {
                        "id": block.clioForeignRegDateID
                    }
                },
                {
                    "value": block.foreignRegRenewedDate,
                    "custom_field":
                    {
                        "id": block.clioForeignRegRenewedDateID
                    }
                },
                {
                    "value": block.foreignExpirationDate,
                    "custom_field":
                    {
                        "id": block.clioForeignRegExpirationDateID
                    }
                },
            )
        });

        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/matters/`;
        const matterInfo = {
            data: {
                "billable": true,
                "client": { "id": formData.contactCompanyID || formData.contactID || contactCompanyID || contactID },
                "client_reference": formData.refNo ? formData.refNo : '',
                "custom_field_set_associations": customFieldSetAssociations,
                "custom_field_values": customFieldValues,
                "description": formData.wordMark || formData.markLiteralElement || 'Design Logo',
                "practice_area": { "id": 51889324 },
                "relationships": relationships
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(matterInfo)
            });

            if (!response.ok) {
                await fallBackMethod();
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            try {
                const data = await response.json();
                if (data && data.data.id) {
                    setFormData({ ...formData, matterNo: data.data.display_number }); //update formData
                }
                // return data && data.data.id ? data.data.id : null;
                return data?.data?.id
                    ? { matterId: data.data.id, matterNo: data.data.display_number }
                    : null;
            } catch {
                // console.log('Matter creation failed, moving to fallback method...');
                await fallBackMethod();
            }


        } catch (error) {
            console.error('Error creating new Clio matter:', error);
            // console.log('Create Matter response NOT ok! Going to fallback method');
            await fallBackMethod();
            return null;
        }
    };

    const createClioMatterFolders = async (matterId, folderName) => {
        // console.log('Now creating new matter folders:\nMatter ID:,', matterId, '\nFolder Name:', folderName);
        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/folders/`;
        const folders = {
            data: {
                "name": folderName,
                "parent": { "id": matterId, "type": "Matter" },
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(folders)
            });

            if (!response.ok) {
                await fallBackMethod();
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data && data.data.id) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    ownerCompanyID: data.data.id
                }));
            }

            return data && data.data.id ? data.data.id : null;

        } catch (error) {
            console.error('Error creating folders in new Clio matter:', error);
        }
    };

    const clioFileUpload = async (matterId, specimensFolder, USPTOFolder, logosFolder, pdfFile) => {
        let uploadPromises = [];

        //Upload design or sound mark file, if present
        if (formData.designMarkFile) {
            const designMetaData = await createClioDocument(formData.designMarkFile, logosFolder);
            console.log('clioFileUpload designMetaData:', designMetaData);
            uploadPromises.push(uploadClioDocument(formData.designMarkFile, designMetaData, logosFolder));
        } else if (formData.soundMarkFile) {
            const soundMetaData = await createClioDocument(formData.soundMarkFile, logosFolder);
            uploadPromises.push(uploadClioDocument(formData.soundMarkFile, soundMetaData, logosFolder));
        }

        // Upload engagement letter
        if (formData.pdfFile || pdfFile) {
            const engagementLetter = await createClioDocument(pdfFile, matterId);
            uploadPromises.push(uploadClioDocument(pdfFile, engagementLetter, matterId));
        } else {
            console.log('clioFileUpload Function - No engagement letter found in the formData!');
        }



        // Handle specimen files upload
        for (const block of formData.classBlocks) {
            if (block.specimens && block.specimens.length > 0) {
                for (const specimenFile of block.specimens) {
                    const specimenMetaData = await createClioDocument(specimenFile.file, specimensFolder);
                    uploadPromises.push(uploadClioDocument(specimenFile.file, specimenMetaData, specimensFolder));
                }
            }
        }

        try {
            await Promise.all(uploadPromises);
        } catch (error) {
            await fallBackMethod();
        }

    };

    const createClioDocument = async (file, folderId) => {

        const url = `${process.env.REACT_APP_DOMAIN}/api/v4/documents/`;
        const fileName = file.name;

        const doc = {
            data: {
                "name": fileName,
                "parent": { "id": folderId, "type": fileName === "Engagement Letter.pdf" ? "Matter" : "Folder" },
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(doc)
            });

            if (!response.ok) {
                await fallBackMethod();
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('createClioDocument data:', data);

            return data;

        } catch (error) {
            console.error('Error creating new document in Clio:', error);
            await fallBackMethod();
        }
    }

    function getFileExtension(file) {
        // Extract the file extension from the file name
        const fileName = file.name;
        const extension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2); // This handles cases without an extension as well
        return extension;
    }

    const uploadClioDocument = async (file, uploadMetaData, folderId) => {
        // const file = formData.designMarkFile;
        console.log('uploadClioDocument Uploading:', file);

        const { put_url, put_headers } = uploadMetaData.data.latest_document_version;

        const uploadFormData = new FormData();
        uploadFormData.append('file', file);
        uploadFormData.append('folderId', folderId);
        uploadFormData.append('putUrl', put_url);
        uploadFormData.append('putHeaders', JSON.stringify(put_headers)); // Serialize put_headers before appending

        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/upload-document`, {
                method: 'POST',
                body: uploadFormData,
            });

            // if (!response.ok) throw new Error('Failed to upload document via server');
            if (!response.ok) await fallBackMethod();

            const result = await response.json();
            // console.log(result.message);
            await markUploadedDocumentComplete(uploadMetaData);
        } catch (error) {
            console.error('Error uploading document through server:', error);
            await fallBackMethod();
        }
    };

    const markUploadedDocumentComplete = async (uploadMetaData) => {
        const uuid = uploadMetaData.data.latest_document_version.uuid;
        const docId = uploadMetaData.data.id;
        const url = `${process.env.REACT_APP_DOMAIN}/api/complete-document-upload/${docId}`;

        const doc = {
            data: {
                "uuid": uuid,
                "fully_uploaded": true,
            }
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(doc)
            });

            if (!response.ok) {
                await fallBackMethod();
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            return data;

        } catch (error) {
            console.error('Error marking document as fully uploaded in Clio:', error);
            await fallBackMethod();
        }
    }

    const performSearch = async (term) => {
        setLoading(true);
        const url = `${process.env.REACT_APP_DOMAIN}/api/search?searchTerm=${term}`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const docs = data.docs;

            // Convert the object of objects into an array of objects, then sort it
            const resultsArray = Object.keys(docs).map(key => ({
                ...docs[key],
                unique_key: `${docs[key].class_id}-${docs[key].id}` // Fix the typo here
            }));

            // Sort the results by class_id in ascending order
            // const sortedResults = resultsArray.sort((a, b) => parseInt(a.class_id, 10) - parseInt(b.class_id, 10));

            // Set the sorted results
            // setSearchResults(sortedResults);

            setSearchResults(resultsArray)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAdditionalInputChange = (id, key, value) => {
        setFormData(prevFormData => {
            const updatedClassBlocks = prevFormData.classBlocks.map(block => {
                if (block.id === id) {
                    // Ensure additionalInputs exists
                    const additionalInputs = block.additionalInputs ? { ...block.additionalInputs } : {};
                    // Update the specific key within additionalInputs
                    additionalInputs[key] = value;
                    return { ...block, additionalInputs: additionalInputs };
                }
                return block;
            });

            return { ...prevFormData, classBlocks: updatedClassBlocks };
        });
    };

    useEffect(() => {
        console.log('Updated formData:', formData);
        // if((formData.contactID || formData.contactCompanyID) && (formData.ownerID || formData.ownerCompanyID)) {
        //   setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'complete'}))
        // } else {
        //   setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'inProcess'}))
        // }
    }, [formData]);

    useEffect(() => {
        if (formData && (formData.attyFee || formData.classBlocks.length > 0)) {
            // setTotalEstimatedFee(calculateTotalFee().totalFee);
            // console.log('totalEstimatedFee: ', totalEstimatedFee);
            const calculated = calculateTotalFee();
            setTotalEstimatedFee(calculated.totalFee);
            setFeeDetails(calculated);
        }
    }, [formData, formData.classBlocks]); // Runs whenever formData changes


    //ORIGINAL FUNCTION BEFORE CALCULATING FILING FEE PER CLASS AND ACCOUNTING FOR FREE-FORM DESCRIPTION SURCHARGE AND STRING LENGTH SURCHARGE
    // const updateDescriptionInFormData = (classBlockId, descriptionId, updatedDescription) => {
    //   setFormData((prevFormData) => {
    //     const updatedClassBlocks = prevFormData.classBlocks.map((block) => {
    //       if (block.id === classBlockId) {
    //         const updatedDescriptions = block.descriptions.map((desc) => {
    //           if (desc.id === descriptionId) {
    //             // Assuming updatedDescription contains both core and additionalInputs
    //             return { 
    //               ...desc, 
    //               core: updatedDescription.core, 
    //               additionalInputs: updatedDescription.additionalInputs 
    //             };
    //           }
    //           return desc;
    //         });
    //         return { ...block, descriptions: updatedDescriptions };
    //       }
    //       return block;
    //     });

    //     return { ...prevFormData, classBlocks: updatedClassBlocks };
    //   });
    // };

    const updateDescriptionInFormData = (classBlockId, descriptionId, updatedDescription) => {
        setFormData((prevFormData) => {
            const updatedClassBlocks = prevFormData.classBlocks.map((block) => {
                if (block.id === classBlockId) {
                    // Update descriptions
                    const updatedDescriptions = block.descriptions.map((desc) => {
                        if (desc.id === descriptionId) {
                            return {
                                ...desc,
                                core: updatedDescription.core,
                                additionalInputs: updatedDescription.additionalInputs
                            };
                        }
                        return desc;
                    });

                    // Determine surcharge based on modifications
                    let surcharge = 0;

                    // // Check if any description in the block has been modified
                    // const isCustomDescription = updatedDescriptions.some(desc =>
                    //     desc.core !== block.goodsServices // If core description differs from the predefined goodsServices
                    // );

                    const isCustomDescription = updatedDescriptions.some(desc => {
                        const coreString = Array.isArray(desc.core) ? desc.core.join(' ').trim() : desc.core.trim();
                        return coreString !== block.goodsServices.trim();
                    });

                    // Check if total description length exceeds 1,000 characters
                    const totalDescriptionLength = updatedDescriptions.reduce((total, desc) => {
                        return total + desc.core.length + (desc.additionalInputs ? desc.additionalInputs.length : 0);
                    }, 0);

                    if (isCustomDescription) {
                        surcharge = 200;
                    }

                    if (totalDescriptionLength > 1000) {
                        surcharge += 200;
                    }

                    return {
                        ...block,
                        descriptions: updatedDescriptions,
                        surcharge // Update the surcharge
                    };
                }
                return block;
            });

            return { ...prevFormData, classBlocks: updatedClassBlocks };
        });
    };


    const handleSaveDescriptionInput = (classId, updatedDescription, additionalInputs) => {
        setFormData(prevFormData => {
            let updated = false;

            const newDescription = {
                id: Date.now(), // Or use a more reliable ID generation strategy
                text: updatedDescription,
                additionalInputs: additionalInputs,
            };

            const updatedClassBlocks = prevFormData.classBlocks.map((block, index) => {
                // If the first block is blank, fill it in first
                if (index === 0 && !block.classNo && !block.goodsServices) {
                    updated = true;

                    const descriptions = block.descriptions ? [...block.descriptions] : [];
                    descriptions.push(newDescription);
                    return {
                        ...block,
                        descriptions,
                        classNo: classId,
                        goodsServices: updatedDescription,
                        goodsServicesRaw: updatedDescription
                    };

                } else if (block.classNo === classId) {
                    // Update existing block if classId matches
                    updated = true;

                    const descriptions = block.descriptions ? [...block.descriptions] : [];
                    descriptions.push(newDescription);
                    return {
                        ...block,
                        descriptions,
                        goodsServices: `${block.goodsServices}; ${updatedDescription}`,
                        goodsServicesRaw: `${block.goodsServicesRaw}; ${updatedDescription}`
                    };

                }
                return block;
            });

            if (!updated) {
                // If no block was updated (including the first block being blank), add a new block
                const newBlock = {
                    id: prevFormData.classBlocks.length,
                    classNo: classId,
                    goodsServices: updatedDescription,
                    goodsServicesRaw: updatedDescription,
                    descriptions: [newDescription],
                    dateFirstUse: '',
                    dateFirstUseInCommerce: '',
                    url: '',
                    specimenFile: ''
                };
                return { ...prevFormData, classBlocks: [...prevFormData.classBlocks, newBlock] };
            } else {
                // Update the state with the modified or new blocks
                return { ...prevFormData, classBlocks: updatedClassBlocks };
            }
        });
    };

    //Function to format description strings including core descriptions and any accompanying additional input
    const formatDescriptions = (descriptions) => {
        return descriptions.map(desc => {
            const coreDescription = desc.core; // Assuming desc.core is the core description string
            const additionalInputsStr = Object.entries(desc.additionalInputs || {})
                .map(([key, value]) => `${value}`).join(', ');
            return `${coreDescription}${additionalInputsStr ? ` ${additionalInputsStr}` : ''}`;
        }).join('; ');
    };

    const handleTeasPlusEligibilityChange = (isEligible) => {
        // Update formData or a specific state to reflect the change in TEAS Plus eligibility
        setFormData((prevFormData) => ({
            ...prevFormData,
            teasPlus: isEligible,
        }));
    };

    //fallback method for retaining user information. In case of failure to send to Clio, send information to supabase 'fallBack' bucket
    const fallBackMethod = async () => {
        sendEmail('failed');

        // console.log('Attempting fallback method!');
        const url = `${process.env.REACT_APP_DOMAIN}/api/upload`;
        const data = new FormData();

        //Append the existing formData as a JSON string
        data.append('formData', JSON.stringify(formData));

        // Append files from designMarkFile and soundMarkFile if they exist
        if (formData.designMarkFile) { // Assuming designMarkFile is a File object
            data.append('designMarkFile', formData.designMarkFile);
        }

        if (formData.soundMarkFile) { // Assuming soundMarkFile is a File object
            data.append('soundMarkFile', formData.soundMarkFile);
        }

        // Append specimen files if they exist
        formData.classBlocks.forEach(block => {
            if (block.specimens) {
                block.specimens.forEach(specimen => {
                    if (specimen.file) { // Assuming `file` is the file object in each specimen
                        data.append('specimens', specimen.file);
                    }
                });
            }
        });

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: data,
            });

            if (response.ok) {
                const result = await response.json();
                // console.log('Success:', result);
            } else {
                throw new Error('Network response was not ok.');
            }

        } catch (error) {
            console.error('Error:', error);
            // Handle errors here, e.g., alert or set state
        }
    };

    //  Intentionally commented handleRefreshTokens function. This is for manual testing.
    const handleRefreshTokens = async () => {
        const keyName = 'Application Review'; // Adjust based on your needs
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/refresh-token/${encodeURIComponent(keyName)}`, {
                method: 'POST',
            });
            if (!response.ok) {
                throw new Error(`Failed to refresh token: ${response.statusText}`);
            }
            const result = await response.json();
            // alert(result.message); // Show success message received from the server
            // console.log(response.data);
        } catch (error) {
            console.error('Error refreshing token:', error);
            alert(`Error refreshing token: ${error.message}`);
        }
    };

    // Trigger PDF generation and download
    const generateEngagementPDF = async () => {

        const url = `${process.env.REACT_APP_DOMAIN}/api/generate-pdf`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                html: engagementContent,
                signature: {
                    text: signatureText,
                    font: "Lucida Handwriting"
                },
                dateTime: signatureTime
            }),
        });

        // if (response.ok) {
        //   // const blob = await response.blob();
        //   // const url = window.URL.createObjectURL(blob);
        //   // const a = document.createElement('a');
        //   // a.href = url;
        //   // a.download = 'engagement-letter.pdf';
        //   // document.body.appendChild(a);
        //   // a.click();
        //   // a.remove();
        //   const pdfBuffer = await response.arrayBuffer();
        //   return pdfBuffer;
        // } else {
        //   console.error('Failed to generate PDF');
        //   throw new Error('Failed to generate PDF');
        // }

        if (!response.ok) {
            throw new Error('Failed to generate PDF');
        }

        const pdfBuffer = await response.arrayBuffer();

        // Convert PDF buffer to File and save it to formData
        const pdfFile = new File([pdfBuffer], 'Engagement Letter.pdf', { type: 'application/pdf' });

        // return await response.arrayBuffer();
        return pdfFile;
    };

    const sendEmail = async (submissionType, matterNo) => {
        const subjectLines = {
            tmApplication: 'New Trademark Application Requested',
            tmOAR: 'Trademark Office Action Response Requested',
            tmSearch: 'Trademark Search Requested',
            tmRenewal: 'Trademark Renewal Requested',
            tmInternational: 'International Trademark Requested',
            failed: 'FAILED New Trademark Application Requested',
        };

        let emailSubject;
        if (formData.potentialConflicts.length === 0) {
            emailSubject = subjectLines[submissionType] || 'San Novus Trademark Inquiry';
        } else {
            emailSubject = `POTENTIAL CONFLICT FOUND | ${subjectLines[submissionType]}`;
        }

        const msgType = 'newTM';
        const emailHtml = createEmailHtml(formData, matterNo); // Generate HTML on the front-end

        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ emailHtml, emailSubject, msgType }),
            });
            const result = await response.json();
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    // Function to create email body for notification about new website submission
    const createEmailHtml = (formData, matterNo) => {
        const markType = formData.wordMark ? 'word mark' : formData.designMarkFile ? 'design logo' : 'sound mark';
        const mark = formData.wordMark || (formData.designMarkFile ? (formData.markLiteralElement || 'design logo') : 'sound mark');
        const totalFee = calculateTotalFee().totalFee;

        return `
    <html>
    <head>
      <style>
        body { font-family: Times-New-Roman, serif; }
        h2 { color: #333; }
        p { margin: 0; }
        ul { padding-left: 20px; }
        li { margin-bottom: 5px; }
      </style>
    </head>
    <body>
      <h2>Matter Number: ${matterNo}</h2>
      <br>
      <h2><u>Potential Conflicts:</u></h2>
      <ul>
        ${formData.potentialConflicts && formData.potentialConflicts.length > 0 ?
                formData.potentialConflicts.map(potentialConflict => `
          <li>
            Matter Code: ${potentialConflict.mat_code}<br>
            Title: ${potentialConflict.title}
          </li>`).join('') :
                '<li>None</li>'
            }
      </ul>
      <br>
      <h2><u>Contact Information:</u></h2>
      <p>First Name: ${formData.contactFirstName}</p>
      <p>Middle Name: ${formData.contactMiddleName}</p>
      <p>Last Name: ${formData.contactLastName}</p>
      <p>Company Name: ${formData.contactCompany}</p>
      <p>Address 1: ${formData.contactAddress1}</p>
      <p>Address 2: ${formData.contactAddress2}</p>
      <p>City: ${formData.contactCity}</p>
      <p>State: ${formData.contactState}</p>
      <p>Zip: ${formData.contactZip}</p>
      <p>Country: ${formData.contactCountry}</p>
      <p>Email: ${formData.contactEmail}</p>
      <p>Alt Reference: ${formData.refNo || ''}</p>

      <br>

      <h2><u>Owner Information:</u></h2>
      <p>First Name: ${formData.ownerFirstName}</p>
      <p>Middle Name: ${formData.ownerMiddleName}</p>
      <p>Last Name: ${formData.ownerLastName}</p>
      <p>Company Name: ${formData.ownerCompany}</p>
      <p>Address 1: ${formData.ownerAddress1}</p>
      <p>Address 2: ${formData.ownerAddress2}</p>
      <p>City: ${formData.ownerCity}</p>
      <p>State: ${formData.ownerState}</p>
      <p>Zip: ${formData.ownerZip}</p>
      <p>Country: ${formData.ownerCountry}</p>
      <p>Email: ${formData.ownerEmail}</p>

      <br>

      <h2><u>Trademark Information:</u></h2>
      <p>Type: ${markType}</p>
      <p>Mark: ${mark}</p>
      <ul>
        ${formData.classBlocks && formData.classBlocks.length > 0 ?
                formData.classBlocks.map(block => `
          <li>
            International Class: ${block.classNo}<br>
            Description: ${block.descriptions ? block.descriptions.map(desc => desc.core).join(', ') : block.description}
          </li>`).join('') :
                '<li>No class information available.</li>'
            }
      </ul>
      <p>Amount Paid: ${totalFee}</p>
    </body>
    </html>
  `;
    };

    // Trigger PDF generation and download
    const generateFormPDF = async (htmlContent) => {

        const url = `${process.env.REACT_APP_DOMAIN}/api/generate-form-pdf`;

        // Convert the designMarkFile to a base64 string
        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        let base64DesignMark;

        if (formData.applicationType !== 'word' && formData.designMarkFile) {
            base64DesignMark = await getBase64(formData.designMarkFile);
        }

        console.log('base64DesignMark:', base64DesignMark);

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                html: htmlContent,
                designMarkFile: base64DesignMark,
                signature: {
                    text: '',
                    font: "Lucida Handwriting"
                },
                dateTime: '',
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to generate PDF');
        }

        const pdfBuffer = await response.arrayBuffer();

        // Convert PDF buffer to File and save it to formData
        const formPDF = new File([pdfBuffer], 'Submission Information.pdf', { type: 'application/pdf' });

        // return await response.arrayBuffer();
        return formPDF;
    };

    const reportUserEmail = async (formPDF, pdfFile) => {
        const emailHtml = createClientEmail(formData, 'a new trademark application'); // Generate HTML on the front-end

        const emailData = new FormData();
        emailData.append('emailHtml', emailHtml);
        emailData.append('formPDF', formPDF);
        emailData.append('pdfFile', pdfFile);
        emailData.append('recipient', formData.contactEmail);

        // // Calculate and log the size of the FormData
        // const formDataSize = await calculateFormDataSize(emailData);
        // console.log('emailData size:', formDataSize, 'bytes');

        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-confirmation-email`, {
                method: 'POST',
                body: emailData,
            });
            const result = await response.json();
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const calculateFormDataSize = async (formData) => {
        const formDataEntries = Array.from(formData.entries());
        const body = new Blob(formDataEntries.map(([key, value]) => {
            if (value instanceof Blob) {
                return new Uint8Array([key.length, ...key.split('').map(c => c.charCodeAt(0)), ...new Uint8Array(value.size)]);
            } else {
                return new Uint8Array([key.length, ...key.split('').map(c => c.charCodeAt(0)), ...new Uint8Array(value.length)]);
            }
        }));
        return body.size;
    };


    return (
        <div className="test-modal">
            {showSearchModal && (
                <SearchModal
                    showModal={showSearchModal}
                    setShowModal={setShowSearchModal}
                    onAddClassDescription={handleAddClassDescription}
                />
            )}
            <div className='intake-header'>
                <StepIndicator currentStep={currentStep} totalSteps={6} />
            </div>

            {currentStep === 1 && <div>
                <div>
                    <h1>Mark Information</h1>
                    <h2>Tell us more about your mark</h2>
                </div>
                <AppType
                    formData={formData}
                    onUpdate={handleAppTypeUpdate}
                    onFormDataChange={handleFormDataChange}
                    onValidationChange={handleValidationChange}
                    attemptedSubmit={attemptedSubmit}
                /></div>}

            {currentStep === 2 && <div>
                <div>
                    <h1>Goods/Services</h1>
                    <h2>What are you (or will you be) selling in connection with your mark?</h2>
                </div>
                <MarkClasses
                    classBlocks={formData.classBlocks}
                    formData={formData}
                    version={formData.version}
                    handleClassBlockChange={handleClassBlockChange}
                    onUpdateClassBlocks={updateClassBlockInfo}
                    setClassBlocks={setClassBlocks}
                    onAddClassBlock={addClassBlock}
                    onRemoveClassBlock={removeClassBlock}
                    onClassChange={handleClassChange}
                    handleAddClassDescription={handleAddClassDescription}
                    searchResults={searchResults}
                    performSearch={performSearch}
                    onSaveDescriptionInput={handleAddClassDescription}
                    handleAdditionalInputChange={handleAdditionalInputChange}
                    onUpdateDescription={updateDescriptionInFormData}
                    onRemoveDescription={removeDescription}
                    updateTeasType={handleTeasPlusEligibilityChange}
                    onValidationChange={handleValidationChange}
                    attemptedSubmit={attemptedSubmit}
                    setTotalFee={setTotalFee}
                /></div>}

            {currentStep === 3 && <div>
                <div>
                    <h1>Trademark Search</h1>
                </div>
                <SearchOption
                    formData={formData}
                    setFormData={setFormData}
                    onFormDataChange={handleFormDataChange}
                    onValidationChange={handleValidationChange}
                    attemptedSubmit={attemptedSubmit}
                />
            </div>}

            {currentStep === 4 && <div>
                <div>
                    <h1>Ready to Move Forward?</h1>
                    <h2>Let's get your account set up so that you'll have immediate access to your file in our client portal.</h2>
                </div>
                <UserRegistration
                    formData={formData}
                    setFormData={setFormData}
                    setCurrentStep={setCurrentStep}
                    onFormDataChange={handleFormDataChange}
                    onValidationChange={handleValidationChange}
                    currentStep={currentStep}
                />
            </div>}

            {currentStep === 5 && <div>
                <div>
                    <h1>Owner Information</h1>
                    <h2>Who will be the owner of this application?</h2>
                </div>
                <OwnerContact
                    formData={formData}
                    setFormData={setFormData}
                    onFormDataChange={handleFormDataChange}
                    isTransferChecked={isTransferChecked}
                    onTransferChecked={setIsTransferChecked}
                    ownerType={ownerType}
                    onOwnerTypeChange={handleOwnerTypeChange}
                    onValidationChange={handleValidationChange}
                    attemptedSubmit={attemptedSubmit}
                />
            </div>}

            {currentStep === 6 && <div>
                <h1>Usage Information:</h1>
                <UsageInfo
                    formData={formData}
                    classBlocks={formData.classBlocks}
                    onUpdateClassBlocks={updateClassBlockInfo}
                    onUpdateSpecimens={onUpdateClassBlocks}
                    onFormDataChange={handleFormDataChange}
                    setFormData={setFormData}
                    onDeleteSpecimens={onDeleteSpecimens}
                    onValidationChange={handleValidationChange}
                    attemptedSubmit={attemptedSubmit}
                />
            </div>}

            {/* <div className="fee-display">
                <h3>Government Filing Fee: {formatCurrency(feeDetails.totalFilingFee)}</h3>
                <h3>Attorney Fee: {formatCurrency(formData.attyFee)}</h3>
                <h3>Total Estimated Fee: <span style={{ color: 'green' }}>{formatCurrency(totalEstimatedFee)}</span></h3>
            </div> */}

            <div className="fee-display">
                <h3>
                    Government Filing Fee:{" "}
                    {parseFloat(feeDetails.totalFilingFee) > 0
                        ? formatCurrency(feeDetails.totalFilingFee)
                        : "To Be Determined"}
                </h3>

                <h3>
                    Attorney Fee:{" "}
                    {parseFloat(formData.attyFee) > 0
                        ? formatCurrency(formData.attyFee)
                        : "To Be Determined"}
                </h3>

                <h3>
                    Total Estimated Fee:{" "}
                    <span style={{ color: "green" }}>
                        {parseFloat(totalEstimatedFee) > 0
                            ? formatCurrency(totalEstimatedFee)
                            : "To Be Determined"}
                    </span>
                </h3>
            </div>

            <div>
                <button onClick={previousStep} disabled={currentStep === 1} className='form-buttons'>Back</button>
                {currentStep !== 6 &&
                    <button onClick={nextStep} disabled={currentStep === 6} className={currentStep === 6 ? 'next-button-disabled' : 'next-button'}>Next</button>
                }
                {currentStep === 6 && <button className='form-buttons' onClick={submitForm}>Submit</button>}
                {/* <button onClick={nextStep} disabled={currentStep === 6} className={currentStep === 6 ? 'next-button-disabled' : 'next-button'}>Next</button> */}
                <div>
                    <button className='cancel-button' onClick={() => toggleForm(false)}>Cancel</button>
                </div>
                {/* <button id='refreshTokens' onClick={handleRefreshTokens}>Refresh API Token(s)</button> */}
                {/* <button id='sendEmail' onClick={() => sendEmail('tmApplication')} className='form-buttons'>Send Email</button> */}
                {/* {currentStep === 6 && <button className='form-buttons' onClick={submitForm}>Submit</button>} */}
                {/* {isFormSubmitted && <ClioContactQuery formData={formData} />} */}
                {showSubmissionStatus && (
                    <div className='submission-form'>
                        <SubmissionStatus
                            status={submissionStatus}
                            hasUploadedDocuments={hasUploadedDocuments()}
                            closeSubmission={closeSubmission}
                            formData={formData}
                            engagementLetterUrl={engagementLetterUrl}
                        />
                    </div>
                )}
                {showEngagementLetter && (
                    <EngagementLetter
                        formData={formData}
                        showEngagementLetter={showEngagementLetter}
                        onClose={() => setShowEngagementLetter(false)}
                        onSign={() => {
                            setIsEngagementLetterSigned(true);
                            setShowEngagementLetter(false);
                        }}
                        onAgree={handleAgree}
                        setEngagementContent={setEngagementContent}
                        setSignatureText={setSignatureText}
                        setSignatureTime={setSignatureTime}
                    // totalFee={totalFee}
                    />
                )}
                {showPaymentModal && (
                    <div className="modal payment-modal-container">
                        <div className="modal-content">
                            <PaymentPage
                                totalAmount={calculateTotalFee().totalFee}
                                // onClose={() => setShowPaymentModal(false)} 
                                setShowPaymentModal={setShowPaymentModal}
                                responseType="U.S. Trademark Application"
                                userEmail={formData.contactEmail}
                                setIsPaymentSuccessful={setIsPaymentSuccessful}
                            />
                            {/* <button onClick={() => setShowPaymentModal(false)}>Close</button> */}
                        </div>
                    </div>
                )}

                {showFormDownload && (
                    <FormDownload
                        formData={formData}
                        onClose={() => setShowFormDownload(false)}
                    />
                )}

            </div>
        </div>
    );
};

export default PricingCalculator;