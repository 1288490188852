import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import SignatureModal from './signatureModal.js';

const NewAppAttySig = ({ user, matter, markType, teasType, clientData, classBlocksCount, onClose, setSigPage, generateSignaturePDF, setIsWorking, isWorking }) => {
    const [signature, setSignature] = useState('');
    const [position, setPosition] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [wordMarkImageURL, setWordMarkImageURL] = useState(null);
    const signatureRef = useRef(null);
    

    const formatCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatDateTime = () => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZone: 'America/New_York',  // Set the time zone to Eastern Time
            timeZoneName: 'short'
        };
        const dateTimeString = new Intl.DateTimeFormat('en-US', options).format(new Date());
        return dateTimeString.replace(/(EDT|EST)/, 'ET');
    };

    const getUnderlinedText = (text, length) => {
        const textLength = text.length;
        const emptySpaceLength = length - textLength;
        const emptySpace = '\u00A0'.repeat(emptySpaceLength);
        return (
            <span style={{ display: 'inline-block', textDecoration: 'underline' }}>
                {text}
                <span style={{ textDecoration: 'none' }}>{emptySpace}</span>
            </span>
        );
    };

    const calculateFee = (teasType, classBlocksCount) => {
        const feePerClass = teasType === 9892024 ? 350 : 550;
        const totalFee = classBlocksCount * feePerClass;
        return totalFee.toLocaleString(); // Adds comma formatting for numbers over 1,000
    };

    let isProcessing = false;

    const handleSign = async () => {
        if (isProcessing) return;

        isProcessing = true;

        setIsWorking(true);
        document.body.style.cursor = 'wait';

        if (user) {
            setSignature(prevSignature => {
                const updatedSignature = `${user.user_metadata.first_name} ${user.user_metadata.middle_initial} ${user.user_metadata.last_name}`;
                setPosition(`Attorney of record, ${user.user_metadata.bar_state} bar member`);

                // Ensure DOM updates before capturing
                setTimeout(async () => {
                    await captureAndGeneratePDF(updatedSignature);
                }, 100);

                return updatedSignature;
            });
        }
    };

    const captureAndGeneratePDF = async (updatedSignature) => {
        if (signatureRef.current) {
            const content = signatureRef.current.outerHTML; // Capture rendered HTML with signature
            console.log("Captured Signature Content: ", content);
            await generateSignaturePDF(content); // Pass to parent for PDF generation
            // setSigPage(false);
        } else {
            console.error("signatureRef.current is null!");
        }
    };


    const generatedDateTime = formatDateTime();

    // Function to render word mark as an SVG image
    const generateWordMarkImage = (wordMark) => {
        if (!wordMark) return null;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const canvasWidth = 400; // Adjust width
        const canvasHeight = 80;  // Adjust height
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Set initial font properties
        let fontSize = 50;
        ctx.font = `${fontSize}px Times`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        // Reduce font size if text is too long
        while (ctx.measureText(wordMark).width > canvasWidth - 20 && fontSize > 10) {
            fontSize -= 2;
            ctx.font = `${fontSize}px Times`;
        }

        // Draw text in the center of the canvas
        ctx.fillText(wordMark, canvasWidth / 2, canvasHeight / 2);

        // Convert to Data URL (initial image)
        const initialImage = new Image();
        initialImage.src = canvas.toDataURL("image/png");

        return new Promise((resolve) => {
            initialImage.onload = () => {
                const trimCanvas = document.createElement("canvas");
                const trimCtx = trimCanvas.getContext("2d");

                trimCanvas.width = initialImage.width;
                trimCanvas.height = initialImage.height;
                trimCtx.drawImage(initialImage, 0, 0);

                const imageData = trimCtx.getImageData(0, 0, trimCanvas.width, trimCanvas.height);
                const pixels = imageData.data;

                let left = trimCanvas.width, right = 0, top = trimCanvas.height, bottom = 0;
                let foundContent = false;

                // Find the bounding box of non-transparent pixels
                for (let y = 0; y < trimCanvas.height; y++) {
                    for (let x = 0; x < trimCanvas.width; x++) {
                        const index = (y * trimCanvas.width + x) * 4;
                        if (pixels[index + 3] !== 0) { // Check alpha channel (transparency)
                            foundContent = true;
                            if (x < left) left = x;
                            if (x > right) right = x;
                            if (y < top) top = y;
                            if (y > bottom) bottom = y;
                        }
                    }
                }

                // Ensure the dimensions are valid
                if (!foundContent || right <= left || bottom <= top) {
                    console.warn("Trim failed: No visible content detected.");
                    resolve(initialImage.src); // Return original if trimming fails
                    return;
                }

                const trimmedWidth = right - left + 1;
                const trimmedHeight = bottom - top + 1;

                const finalCanvas = document.createElement("canvas");
                finalCanvas.width = trimmedWidth;
                finalCanvas.height = trimmedHeight;
                const finalCtx = finalCanvas.getContext("2d");

                finalCtx.drawImage(
                    initialImage,
                    left, top, trimmedWidth, trimmedHeight,
                    0, 0, trimmedWidth, trimmedHeight
                );

                resolve(finalCanvas.toDataURL("image/png")); // Return final trimmed image
            };
        });
    };


    // Extract word mark text
    const wordMark = matter?.custom_field_values.find(field => field.field_name === "Mark")?.value || '';

    // const wordMarkImageURL = wordMark ? generateWordMarkImage(wordMark) : null;

    useEffect(() => {
        if (wordMark) {
            generateWordMarkImage(wordMark).then(setWordMarkImageURL);
        }
    }, [wordMark]);

    return (
        <div>
            <div className='container' ref={signatureRef}>
                <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }} id='change-representation-container'>
                    <div className='text-left' style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '6pt', lineHeight: 'normal', padding: '0', marginBottom: '0.2rem', color:'lightgray' }}>
                        <p>PTO-1478</p>
                        <p>Approved for use through 08/31/2027. OMB 0651-0009</p>
                        <p>U.S. Patent and Trademark Office; U.S. DEPARTMENT OF COMMERCE</p>
                        <p>Under the Paperwork Reduction Act of 1995, no persons are required to respond to a collection of information unless it contains a valid
                            OMB control number.</p>
                    </div>
                    <div className='car-instr text-center' style={{ fontFamily: 'Times, serif', fontSize: '12pt' }}>
                        <br />
                        <p>You may sign this document but do not edit it. Make any necessary changes through Trademark Center then generate a new signature page.</p>
                    </div>
                    <div className='car-body-title text-center' style={{ textAlign: 'center', fontSize: '14pt', fontWeight: 'bold', lineHeight: 'normal', marginLeft: 'auto', marginRight: 'auto', marginBottom: '1rem' }}>
                        <p style={{padding:'0', margin:'0', fontSize:'14pt', fontFamily:'Times, serif', color:'gray'}}>Trademark application, Principal Register</p>
                        <br />
                        <p style={{padding:'0', margin:'0', fontSize:'14pt', fontFamily:'Times, serif'}}>Handwritten Signature or Digital Signature</p>
                        <br />
                        {/* <br /> */}
                    </div>
                    <div className='car-body' style={{ fontFamily: 'Times, serif', fontSize: '12pt' }}>
                        <p>
                            Review the complete filing details before signing. Preparers printing this form for handwriting signature should also print the&nbsp;
                            <span style={{ textDecoration: 'underline', color: 'blue' }}>filing details</span> for signatory review.
                        </p>
                        <p>A fee payment in the amount of ${calculateFee(teasType, classBlocksCount)} will be submitted with the application, representing payment for {classBlocksCount} class(es).</p>
                        <br />
                        <p><b>MARK: </b>{markType === 'Word Mark' ? `${wordMark} (STANDARD, see)` : '(DESIGN, see))'}</p>
                        <br />
                        <br />
                        <div className="text-left">
                            {wordMarkImageURL && (
                                <img src={wordMarkImageURL} alt="Word Mark" style={{ maxWidth: "100%", height: "auto", margin: '0', display: 'block' }} />
                            )}
                        </div>
                        <br />
                        <br />
                        <p><b>Applicant(s):</b> {clientData.name}</p>
                        <br />
                        <p style={{marginBottom:'1rem'}}><b>Correspondent email address: </b>uspto@sannovustrademark.com</p>
                        <br />
                        <p style={{textAlign:'center', marginBottom:'2rem'}}><b>Declaration</b></p>
                        <p style={{ lineHeight: '1', marginBottom: '0', paddingBottom: '0' }}><b>Basis:</b></p>
                        <p style={{ lineHeight: '1', marginTop: '0', paddingTop: '0' }}><b>If the applicant is filing the application based on use in commerce under 15 U.S.C. § 1051(a):</b></p>
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            <li style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingBottom: '12pt', paddingLeft: '5px', fontSize: '12pt', marginLeft:'12pt' }}>The signatory believes that the applicant is the owner of the trademark/service mark sought to be registered;</li>
                            <li style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingBottom: '12pt', paddingLeft: '5px', fontSize: '12pt', marginLeft:'12pt' }}>The mark is in use in commerce and was in use in commerce as of the filing date of the application on or in connection with the goods/services in the application;</li>
                            <li style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingBottom: '12pt', paddingLeft: '5px', fontSize: '12pt', marginLeft:'12pt' }}>The specimen(s) shows the mark as used on or in connection with the goods/services in the application; and</li>
                            <li style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: '5px', fontSize: '12pt', marginLeft:'12pt' }}>To the best of the signatory's knowledge and belief, the facts recited in the application are accurate.</li>
                        </ul>
                        <p style={{ lineHeight: '1', marginBottom: '0', paddingBottom: '0' }}><b>And/or</b></p>
                        <p style={{ lineHeight: '1', marginTop: '0', paddingTop: '0' }}><b>If the applicant is filing the application based on an intent to use the mark in commerce under 15 U.S.C. § 1051(b), § 1126(d), and/or § 1126(e):</b></p>
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            <li style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingBottom: '12pt', paddingLeft: '5px', fontSize: '12pt', marginLeft:'12pt' }}>The signatory believes that the applicant is entitled to use the mark in commerce;</li>
                            <li style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingBottom: '12pt', paddingLeft: '5px', fontSize: '12pt', marginLeft:'12pt' }}>The application has a bona fide intention to use the mark in commerce and had a bona fide intention to use the mark in commerce as of the application filing date on or in connection with the goods/services in the application; and</li>
                            <li style={{ listStyleType: 'disc', listStylePosition: 'outside', paddingLeft: '5px', fontSize: '12pt', marginLeft:'12pt' }}>To the best of the signatory's knowledge and belief, the facts recited in the application are accurate.</li>
                        </ul>
                        <p>To the best of the signatory's knowledge and belief, no other persons, except, if applicable, concurrent users, have the right to use the mark in commerce, either in the identical form
                            or in such near resemblance as to be likely, when used on or in connection with the goods/services of such other persons, to cause confusion or mistake, or to deceive.
                        </p>
                        <p>To the best of the signatory's knowledge, information, and belief, formed after an inquiry reasonable under the circumstances, the allegations and other factual contentions
                            made above have evidentiary support.
                        </p>
                        <p>The signatury being warned that willful false statements and the like are punishable by fine or imprisonment, or both, under 18 U.S.C. § 1001, and that such willful false statements and the like may
                            jeopardize the validity of the application or submission or any registration resulting therefrom, declares that all statements made of his/her own knowledge are true and all statements made on information
                            and belief are believed to be true.
                        </p>
                        <p ><b>Signature Section:</b></p>
                        <p>If your application includes joint applicants, only one applicant must sign. by signing, they swear to the truth of the information in the application.</p>
                        <p style={{ lineHeight: '1', marginBottom: '0', paddingBottom: '0' }}>Signature: {getUnderlinedText(signature ? `/${signature}/` : '', 60)}</p>
                        <p style={{ lineHeight: '1', marginTop: '0', paddingTop: '0', marginBottom: '0', paddingBottom: '0' }}>Signatory's Name: {signature}</p>
                        <p style={{ lineHeight: '1', marginTop: '0', paddingTop: '0', marginBottom: '0', paddingBottom: '0' }}>Signatory's Position: {position}</p>
                        <p style={{ lineHeight: '1', marginTop: '0', paddingTop: '0', marginBottom: '0', paddingBottom: '0' }}>Date: {getUnderlinedText(formatCurrentDate(), 25)}</p>
                        <p style={{ lineHeight: '1', marginTop: '0', paddingTop: '0', marginBottom: '0', paddingBottom: '0' }}>Document generated on {generatedDateTime}</p>
                    </div>
                </div>
            </div>
            <div>
                <div className='car-signature text-center my-3'>
                    <button className='btn btn-primary form-buttons' onClick={handleSign} disabled={isWorking}>Sign</button>
                </div>
            </div>
            {/* Close Modal Button */}
            <div className="text-center mt-3">
                <button className="btn btn-danger" onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default NewAppAttySig;

