//ourFirm.js
import React from "react";
import './App.css';

const OurFirm = () => {
    return (
        <div>
        {/* <Header /> */}
        <main>
          <div className='comp'>
            <span className='comp-name'>Our Firm</span>
            <span className='comp-descrip'>A Forward-Thinking, Virtual Law Firm Dedicated to Trademarks</span>
          </div>
          <div className='main-section-alt'>
            <p>San Novus Trademark is here to help you protect your brand at a low, fixed cost. Our attorneys bring decades of combined experience 
              in trademark law, ensuring that your registration process is handled with skill and precision. We have successfully completed thousands 
              of filings for clients just like you!</p>
          </div>
          <div className='main-section'>
            <h3>Above and Beyond Basic Filing Services</h3>
            <p>We are a law firm, not a generic filing service, meaning we are legally authorized to represent you before 
              the U.S. Patent and Trademark Office. Our approach is not just about filling out forms; it’s about providing expert guidance and advocacy to 
              successfully secure your trademark. Our specialization extends to offering comprehensive representation in trademark applications and trademark renewals across all 50 states, 
              as well as internationally. We provide a range of trademark-related services, from initial application filings to handling office actions and renewals. Our virtual 
              model is designed for modern convenience, offering our clients efficient and flexible legal services no matter where they are located. Whether you’re a startup or an established business, 
              our goal is to help you safeguard your brand’s identity, giving you peace of mind to focus on what you do best – running your business.</p>
          </div>
          <div className='main-section-alt'>
            <h3>The Ideal Middle Ground</h3>
            <p>We understand that DIY solutions are limited, and the cost of a traditional full-service law firm can be 
              prohibitive. San Novus Trademark fills this gap by offering comprehensive help without the excessive expense. We offer a perfect blend 
              of professional legal assistance and cost-effectiveness.</p>
          </div>
          <div className='main-section'>
            <h3>Staying Ahead with Technology and Knowledge</h3>
            <p>Our team is constantly updated on the latest practices at the U.S. Patent and Trademark Office and utilizes the 
              latest consumer technologies. This commitment ensures that your trademark experience is as seamless and effective as possible.</p>
          </div>
          <div className='main-section-alt'>
            <h3>Transparent Communication and Fixed-Rate Pricing</h3>
            <p>We believe in building relationships on trust and transparency. Our communication is straightforward and our 
              pricing model is fixed-rate, meaning no surprises or hidden costs for you.</p>
          </div>
          <div className='main-section'>
            <h3>Looking for a partner to protect your brand?</h3>
            <p>Contact <a href='/contact-us' style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">San Novus Trademark</a> for 
              expert trademark services that combine experience, efficiency, and affordability. Let us navigate the complexities of trademark 
              law, while you focus on growing your business.</p>
          </div>
        </main>
      </div>
    );
  };
  
  export default OurFirm;