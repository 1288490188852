// verificationLandingPage.js
import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo_final from './Final_Logo_no_design.png';

const VerificationLandingPage = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5); // Start from 5 seconds

    // Auto redirect after 5 seconds
    useEffect(() => {
        const timer = setInterval(() => {
          setCountdown(prev => {
            if (prev <= 1) {
              clearInterval(timer);
              navigate('/clientPortal');
            }
            return prev - 1;
          });
        }, 1000);
    
        return () => clearInterval(timer);
      }, [navigate]);

    const goToClientPortal = () => {
        navigate('/clientPortal'); // Or wherever you want to send the user next
    };

    return (
        <div className="email-verified-landing bg-light d-flex align-items-center justify-content-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6}>
                        <img
                            src={logo_final}
                            alt="San Novus Trademark Logo"
                            className="mb-4"
                            style={{ width: '500px' }}
                        />
                        <h2 className="mb-3">Welcome to San Novus Trademark!</h2>
                        <p className="mb-4 fs-5 text-muted">
                            Your email has been successfully verified. <br />
                            You will be automatically redirected to the client portal in <strong>{countdown}</strong> second{countdown !== 1 && 's'}.
                        </p>
                        <Button variant="primary" size="lg" onClick={goToClientPortal}>
                            Go to Client Portal
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default VerificationLandingPage;