import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const DenyApplicationModal = ({ show, handleClose, handleDeny }) => {
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [comments, setComments] = useState('');

    const denialReasons = [
        "Owner Contact Information",
        "Mark",
        "Description of Goods/Services",
        "Specimens",
        "Dates of First Use"
    ];

    const toggleReason = (reason) => {
        setSelectedReasons(prev =>
            prev.includes(reason)
                ? prev.filter(r => r !== reason) // Remove if already selected
                : [...prev, reason] // Add if not selected
        );
    };

    const handleSubmit = () => {
        handleDeny(selectedReasons, comments);
        handleClose();  // Ensure modal closes after submission
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '1.5rem' }}>Deny Application</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '1.5em' }}> {/* 1.5x the original size */}
                <p>Please select the reasons for denial:</p>
                <Form>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {denialReasons.map(reason => (
                            <Form.Check
                                key={reason}
                                type="checkbox"
                                label={reason}
                                checked={selectedReasons.includes(reason)}
                                onChange={() => toggleReason(reason)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px', // Small gap between checkbox and label
                                    fontSize: '1em' // Slightly larger text size for better readability
                                }}
                            />
                        ))}
                    </div>
                    <Form.Group className="mt-3">
                        <Form.Label style={{ fontSize: '1em' }}>Additional Comments</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={6}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            placeholder="Provide additional details..."
                            style={{ 
                                fontSize: '1em', 
                                minHeight: '150px',
                                resize: 'vertical',
                                textAlign: 'left',
                            }}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} style={{ fontSize: '1.2em' }}>Cancel</Button>
                <Button variant="danger" onClick={handleSubmit} style={{ fontSize: '1.2em' }}>Submit Denial</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DenyApplicationModal;
