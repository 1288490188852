import React, { useState, useEffect } from 'react';
import SignatureModal from './signatureModal.js';

const CAR = ({ formData, drawingURL, setCarContent, onSignatureChange }) => {
    const [signature, setSignature] = useState('');
    const [position, setPosition] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        onSignatureChange(!!signature); // Inform the parent component of the signature status
    }, [signature, onSignatureChange]);

    const formatDateTime = () => {
        const options = { 
            year: 'numeric', 
            month: 'long', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: true,
            timeZone: 'America/New_York',  // Set the time zone to Eastern Time
            timeZoneName: 'short'
        };
        const dateTimeString = new Intl.DateTimeFormat('en-US', options).format(new Date());
        return dateTimeString.replace(/(EDT|EST)/, 'ET');
    };
    
    const generatedDateTime = formatDateTime();

    const handleModalClose = () => setShowModal(false);
    
    const handleModalShow = () => {
        if (!formData.ownerCompany) {
            setSignature(`${formData.ownerFirstName} ${formData.ownerLastName}`);
            setPosition('Owner');
        }
        setShowModal(true);
    };

    const handleSaveSignature = (name, position) => {
        setSignature(name);
        setPosition(position);
        setShowModal(false);
        captureContent(name, position);  // Call captureContent after setting signature and position
    };

    const formatCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getUnderlinedText = (text, length) => {
        const formattedText = `${text}`;
        const textLength = formattedText.length;
        const emptySpaceLength = length - textLength;
        const emptySpace = '\u00A0'.repeat(emptySpaceLength); // \u00A0 is a non-breaking space

        return (
            <span style={{ display: 'inline-block', textDecoration: 'underline' }}>
                {formattedText}
                <span style={{ textDecoration: 'none' }}>{emptySpace}</span>
            </span>
        );
    };

    const currentDate = formatCurrentDate();

    const captureContent = (sig, pos) => {
        const content = `
            <div style="text-align: left; display: flex; flex-direction: column; margin-left: 5rem;">
                <div style="font-family: Arial, Helvetica, sans-serif; font-size: 6pt; line-height: normal;">
                    <p style="padding: 0; margin-bottom: 0.4rem;">PTO-2300</p>
                    <p style="padding: 0; margin-bottom: 0.4rem;">Approved for use through 07/31/2024. OMB 0651-0056</p>
                    <p style="padding: 0; margin-bottom: 0;">U.S. Patent and Trademark Office; U.S. DEPARTMENT OF COMMERCE</p>
                    <p style="padding: 0; margin-bottom: 0.4rem; line-height: 0.08;">Under the Paperwork Reduction Act of 1995, no persons are required to respond to a collection of information unless it contains a valid
                        OMB control number.</p>
                </div>
                <div style="font-family: 'Times New Roman', serif; font-size: 9.5pt;">
                    <p>You may sign this document but do not edit it. Make any necessary changes through TEAS, then generate a new signature page.</p>
                </div>
                <div style="font-family: 'Times New Roman', serif; text-align: center; font-size: 14pt; font-weight: bold; line-height: normal; margin: auto; margin-bottom: 1rem;">
                    <p style="padding: 0; margin: 0;">Change Address or Representation Form</p>
                    <p style="padding: 0; margin: 0; font-size: 16pt;">Handwritten Signature or Digital Signature</p>
                </div>
                <div style="font-family: 'Times New Roman', serif; font-size: 12pt;">
                    <p>Review the complete <span style="text-decoration: underline; color: blue;">filing details</span> before signing. Preparers printing this form for handwriting signature should also print the
                        filing details for signatory review.</p>
                    <p><b>MARK: </b>${formData.applicationType === 'word' ? formData.wordMark + ' (Standard Characters, see )' : '(Stylized and/or Design))'}</p>
                    <img src="${drawingURL}" alt="Trademark Logo" style="margin-left: 0; margin-bottom: 0; padding: 0; max-width: 160px; height: auto;" onError="this.onerror=null;this.src='';" />
                    <p style="margin-bottom: 0; padding-bottom: 0; line-height: 0.35;"><b>Correspondent: </b>Sean K. Enos.</p>
                    <p style="line-height: 0.35;"><b>Correspondent email address: </b>uspto@sannovustrademark.com</p>
                    <br />
                    <p><b>Requirement for Email and Electronic Filing: </b>I understand that a valid email address must be maintained by the owner/holder and the
                        owner's/holder's attorney, if appointed, and that all official trademark correspondence must be submitted via the Trademark Electronic Application
                        System (TEAS).</p>
                    <br />
                    <p><b>Signature Section:</b></p>
                    <p></p>
                    <p style="margin-bottom: 0; padding-bottom: 0; line-height: 0.35;">Signature: <span style="display: inline-block; text-decoration: underline;">${sig ? `/${sig}/` : ''}<span style="text-decoration: none;">${'\u00A0'.repeat(60 - (sig ? sig.length + 2 : 0))}</span></span></p>
                    <p style="margin-bottom: 0; padding-bottom: 0; line-height: 0.35;">Date: <span style="display: inline-block; text-decoration: underline;">${currentDate}<span style="text-decoration: none;">${'\u00A0'.repeat(25 - currentDate.length)}</span></span></p>
                    <p style="margin-bottom: 0; padding-bottom: 0; line-height: 0.35;">Signatory's Name: ${sig}</p>
                    <p style="margin-bottom: 0; padding-bottom: 0; line-height: 0.35;">Signatory's Position: ${pos}</p>
                    <p style="margin-bottom: 0; padding-bottom: 0; line-height: 0.35;">Signatory's Phone Number:</p>
                    <br />
                    <p>The signatory has confirmed that he/she is either: (1) the owner/holder; or (2) a person or persons with legal authority
                        to bind the owner/holder.
                    </p>
                    <p style="margin-bottom: 0; padding-bottom: 0; font-size: 8pt;">Document generated on ${generatedDateTime}</p>
                </div>
            </div>
        `;
        setCarContent(content);
    };

    return (
        <div className='container'>
            <div className='change-representation-container' id='change-representation-container'>
                <div className='car-header text-center'>
                    <p>PTO-2300</p>
                    <p>Approved for use through 07/31/2024. OMB 0651-0056</p>
                    <p>U.S. Patent and Trademark Office; U.S. DEPARTMENT OF COMMERCE</p>
                    <p>Under the Paperwork Reduction Act of 1995, no persons are required to respond to a collection of information unless it contains a valid 
                        OMB control number.</p>
                </div>
                <div className='car-instr text-center'>
                    <p>You may sign this document but do not edit it. Make any necessary changes through TEAS, then generate a new signature page.</p>
                </div>
                <div className='car-body-title text-center'>
                    <p>Change Address or Representation Form</p>
                    <p>Handwritten Signature or Digital Signature</p>
                </div>
                <div className='car-body'>
                    <p>Review the complete filing details before signing. Preparers printing this form for handwriting signature should also print the
                        filing details for signatory review.</p>
                    <p><b>MARK: </b>{formData.applicationType === 'word' ? formData.wordMark + ' (Standard Characters, see )' : '(Stylized and/or Design))'}</p>
                    <img src={drawingURL} alt="Trademark Logo" className="img-fluid car-logo" onError={(e) => { e.target.onerror = null; e.target.src = ''; }} />
                    <p><b>Correspondent: </b>Sean K. Enos.</p>
                    <p><b>Correspondent email address: </b>uspto@sannovustrademark.com</p>
                    <br />
                    <p><b>Requirement for Email and Electronic Filing: </b>I understand that a valid email address must be maintained by the owner/holder and the 
                        owner's/holder's attorney, if appointed, and that all official trademark correspondence must be submitted via the Trademark Electronic Application 
                        System (TEAS).</p>
                    <br />
                    <p><b>Signature Section:</b></p>
                    <p />
                    <p>Signature: {getUnderlinedText(signature ? `/${signature}/` : '', 60)}</p>
                    <p>Date: {getUnderlinedText(currentDate, 25)}</p>
                    <p>Signatory's Name: {signature}</p>
                    <p>Signatory's Position: {position}</p>
                    <p>Signatory's Phone Number:</p>
                    <br />
                    <p>Document generated on {generatedDateTime}</p>
                </div>
            </div>
            <div className='car-signature text-center my-3'>
                <button className='btn btn-primary form-buttons' onClick={handleModalShow}>Sign</button>
            </div>
            {formData.ownerCompany && (
                <SignatureModal 
                    show={showModal} 
                    handleClose={handleModalClose} 
                    handleSave={handleSaveSignature}
                    name={signature}
                    setName={setSignature}
                    position={position}
                    setPosition={setPosition} 
                />
            )}
        </div>
    );
}

export default CAR;