// HomePage.js
import React, { useState, useEffect } from "react";
import './App.css';
import RegisterTrademarkForm from './registerTrademarkForm.js';
import FAQs from "./FAQs.js";
import { Link } from 'react-router-dom';
import TestModal from './TestModal.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo_final from './Final_Logo_no_design.png';
import logo from './FullLogo6.png';

const HomePage = () => {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
    // setShowForm((prev) => !prev);
  };

  // Effect to log state changes
  useEffect(() => {
    console.log("showForm Value: ", showForm);
  }, [showForm]);

  const closeModal = (e) => {
    if (e.target.className === 'modal') {
      setShowForm(false);
    }
  };

  return (
    <div className="overall-gradient">
      {/* <Header /> */}
      <main>
        <div className="container-fluid">
          {/* Company Info Section */}
          <div className="text-center intro-section" style={{ marginBottom: '0 !important' }}>
            {/* <h1 className="comp-name">San Novus Trademark LLP</h1> */}
            <img src={logo_final} alt="San Novus Trademark Logo" style={{ height: '100px', width: 'auto', margin: '40px 0' }} />
            {/* <p className="comp-descrip">Protecting Your Brand with Expertise and Precision</p> */}
            <p className='intro-section-brief'><b><i>SIMPLE. AFFORDABLE. ATTORNEY-LED BRAND PROTECTION</i></b>.</p>
            {/* <p style={{marginLeft:'10%', marginRight:'10%', marginBottom:'3%'}}>Trademark attorneys dedicated to making trademark protection seamless, affordable, and attorney-led. 
              Our law firm will be with you every step of the way - from your initial application to renewals years down the road. We specialize in trademark registration, office actions, and renewals across the 
              U.S. and internationally, providing expert legal guidance with clear, fixed-rate pricing. Whether you're launching a brand or maintaining your portfolio, we offer the strategic counsel you need.
            </p> */}


            {/* <p className='intro-section-descrip-1'>Unlike generic filing services that vanish after submitting your application, our 
              law firm stays with you through every stage of examination - including complex refusals like likelihood of confusion. 
            </p>
            <p className='intro-section-descrip-2'>San Novus Trademark is a modern, virtual law firm led by experienced 
              attorneys. Our streamlined model delivers a responsive trademark experience that feels full-service - without the full-service price tag of traditional law firms. 
            </p> */}

            <p className='intro-section-descrip-1'>San Novus Trademark is a modern, virtual law firm led by experienced
              attorneys. Our streamlined model delivers a responsive trademark experience that feels full-service - without the full-service price tag of traditional law firms. Every filing performed
              by our firm is reviewed and signed by an experienced trademark attorney, which means <i>our</i> name is on the line, not just yours.
            </p>
            <p className='intro-section-descrip-2'>Unlike generic filing services, which are not law firms and cannot represent you before the United States Patent and Trademark Office,
              San Novus Trademark stays with you through every stage of examination - including complex refusals like likelihood of confusion.
            </p>

            {/* If your application encounters obstacles, filing services
            simply refer you elsewhere. We provide the legal representation and strategic guidance you need to keep your application moving forward. */}


            {/* <p style={{fontFamily:'Roboto, serif', fontSize: '2rem'}}><b>Expert legal representation. No hidden fees. Complete peace of mind.</b></p> */}
            <button className="form-button" onClick={toggleForm}>Start Your Application Now</button>
            <br />
          </div>

          {/* Our Services Section */}
          <div className="main-section-alt text-center p-4">
            <h2 className="comp-name" style={{ marginBottom: '2%' }}><i>What can we help you with?</i></h2>
            {/* <p className="comp-descrip">Discover what sets our trademark and renewal services apart</p> */}
            {/* <p className="comp-descrip">What trademark services do you need help with?</p> */}
            <div className="row">
              {/* Service Button Links */}
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-searches'>
                    <h3>Search for Existing Trademarks</h3>
                    <p>Ensuring unique and defensible branding for your mark</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-registration-applications'>
                    <h3>Apply For A New Trademark Application</h3>
                    <p>Comprehensive assistance with registration applications</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-oar'>
                    <h3>Respond to an Office Action</h3>
                    <p>Expert responses to application rejections</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-sou'>
                    <h3>Submit a Statement of Use</h3>
                    <p>Finalizing your registration with accurate usage declarations</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className="service-block">
                  <Link to='/trademark-renewals'>
                    <h3>File a Trademark Renewal</h3>
                    <p>Renew your registered trademark for ongoing protection</p>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                {/* <div className="service-block">
                  <Link to='/international-trademarks'>
                    <h3>International Coverage</h3>
                    <p>Extend your brand's reach with foreign trademark applications</p>
                  </Link>
                </div> */}
                <div className="service-block">
                  <Link to='/amazon-brand-registry'>
                    <h3>Amazon Brand Registry</h3>
                    <p>Protect your brand, empower your business</p>
                  </Link>
                </div>
              </div>
              {/* <div className="col-12 col-md-6 mb-3 mx-auto">
                <div className="service-block">
                  <Link to='/amazon-brand-registry'>
                    <h3>Amazon Brand Registry</h3>
                    <p>Protect your brand, empower your business</p>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>

          {/* Additional Sections */}
          {/* <div className="main-section my-4 text-center">
            <h2 className="comp-name"><i>Your Trusted Partner for Trademark Services</i></h2>
            <p>Our team of attorneys has decades of combined experience navigating the U.S. Trademark Office to help individuals and business owners register their trademarks.</p>
            <p>We are a law firm, not a filing service. Watch out for other “filing services” that will take your money and leave you to complete the process on your own. We stay up to date with the latest business technologies and practices to ensure our clients have the best representation possible.</p>
            <button className="form-button" onClick={toggleForm}>Start Your Application Now</button>
          </div>

          <div className="main-section-alt my-4 text-center">
            <h2 className="comp-name"><i>Pricing</i></h2>
            <p>Fixed rate billing guarantees no surprise charges. With our transparent pricing, you'll know your project budget every step of the way.</p>
          </div> */}

          <div className="d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6 mb-3 mx-auto reasons-why-us text-start">
              <h2 className="comp-name"><i>Why Us?</i></h2>
              <ul>
                <li>Competitive Fixed-Rate Pricing - Starting At <span style={{fontWeight:'800', fontStyle:'italic'}}>$265</span> Plus Government Filing Fees</li>
                <li>Representation By Team of Attorneys With Decades of Experience</li>
                <li>Custom Client Portal To View Status Of Application Anytime</li>
                <li>Innovative Application Processing Saves Time And Fees</li>
                <li>Virtual Model For Seamless Global Representation Wherever You Are</li>
              </ul>
              <button className="form-button alt-new-app-button" onClick={toggleForm}>
                Start Your Application Now
              </button>
              <hr className='section-divider'/>
            </div>
          </div>
          
          


          {/* <div className="main-section my-4 text-center"> */}
          <div className='reverse-gradient'>
            <div className="col-12 col-md-6 mb-3 mx-auto">
              <h2 className="comp-name"><i>Common Questions and Answers</i></h2>
              <FAQs tags="general" />
              <button className="form-button" onClick={toggleForm}>Start Your Application Now</button>
            </div>
          </div>

        </div>
        {showForm && (
          <div className="test-modal" onClick={closeModal}>
            <RegisterTrademarkForm toggleForm={toggleForm} />
          </div>
        )}
      </main>
    </div>
  );
};

export default HomePage;

