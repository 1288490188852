// HomePage.js
import React, { useState, useEffect } from "react";
import './App.css';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <div>
            <main>
                <div className='privacy-policy'>
                    <h2>Privacy Policy</h2>
                    <p>At San Novus Trademark, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and protect the information
                        you provide when using our website.
                    </p>
                    <ol>
                        <li>
                            <p><b>Information We Collect</b></p>
                            <p>We collect the following personal information when you engage our services:
                                <ul>
                                    <li>Contact information (such as name, mailing address, phone number, and email address)</li>
                                    <li>Trademark information (such as word/phrase or design logo information, examples of trademark usage, etc.)</li>
                                    <li>Financial information (such as debit/credit card information, expiration date, Card Verification Value ("CVV"), etc.)</li>
                                </ul>
                            </p>
                            <p>All contact information and trademark information is securely stored within our case management software. We do not retain any personal information on our web server.
                                All financial transactions are securely processed via Stripe, a PCI-compliant payment processor. We do not store or process credit card information directly on our servers.
                            </p>
                        </li>
                        <li>
                            <p><b>How We Use Your Information</b></p>
                            <p>Your personal information is collected solely for the purpose of providing legal services related to U.S. trademark prosecution and renewals. Specifically, we may use your information
                                to:
                                <ul>
                                    <li>Respond to your inquiries</li>
                                    <li>Process your trademark application and related legal matters</li>
                                    <li>Communicate with you about your case</li>
                                    <li>Comply with legal and regulatory obligations</li>
                                </ul>
                            </p>
                            <p>We will hold all information provided to us in complete privacy unless we are authorized by you to disclose it to the United States Patent and Trademark Office, or if we are
                                required by law to comply with a valid legal requirement including, but not limited to, subpoena, court order, or search warrant.
                            </p>
                        </li>
                        <li>
                            <p><b>How we Protect Your Information</b></p>
                            <p>We take appropriate security measures to ensure that your personal information is kept safe. We rely on robust security infrastructure to protect your information. Our website
                                does not store or process any personal information directly.
                            </p>
                        </li>
                        <li>
                            <p><b>Sharing of Information</b></p>
                            <p>We do not sell, trade, or otherwise provide your personal information to outside parties, except as required to provide legal services or comply with legal obligations. This
                                may include:
                                <ul>
                                    <li>Government agencies such as the United States Patent and Trademark Office (USPTO)</li>
                                    <li>Third parties as required to fulfull legal obligations</li>
                                </ul>
                            </p>
                            <p>We may share limited personal information with third party service providers to help us operate our business, such as cloud storage providers, email services, and payment
                                processors. These third parties are required to maintain confidentiality and security measures in accordance with applicable laws.
                            </p>
                            <p>Information submitted to the United States Patent and Trademark Office is considered public record and is therefore publicly accessible and searchable. Examples of public
                                information include, but are not limited to, trademark applicant/registrant name and mailing address. San Novus Trademark LLP's privacy policy does not cover actions by
                                governments or third parties.
                            </p>
                        </li>
                        <li>
                            <p><b>Cookies and Tracking</b></p>
                            <p>Our website may use essential cookies for secure authentication and website functionality. We may engage third-party advertising companies to distribute ads on our behalf.
                                Third party advertising companies may use cookies, tracking pixels, or similar technologies to measure advertising effectiveness and improve ad targeting. Any data
                                collected via these methods will be anonymous and will include personally identifiable information.
                            </p>
                        </li>
                        <li>
                            <p><b>Your Rights</b></p>
                            <p>You have the right to:</p>
                            <ul>
                                <li>Request access to the personal information we have collected</li>
                                <li>Request corrections or updates to your personal information</li>
                                <li>Request deletion of your personal data, subject to legal and ethical obligations</li>
                            </ul>
                            <p>To exercise any of these rights, please contact us at <a href='mailto:info@sannovustrademark.com'>info@sannovustrademark.com</a>.</p>
                            <p>If you are a resident of California or the European Union, you may have additional rights regarding your personal data, including the right to access, delete, or
                                restrict processing of your information. If you wish to exercise these rights, please contact us.
                            </p>
                        </li>
                        <li>
                            <p><b>Data Retention</b></p>
                            <p>We retain information for at least seven (7) years or as required by legal and regulatory obligations. Clients may request deletion of their data unless retention is
                                necessary for legal, compliance, or legitimate business purposes.
                            </p>
                        </li>
                        <li>
                            <p><b>Third-Party Links</b></p>
                            <p>Our website may contain links to third-party websites including, but not limited to, the website for the United States Patent and Trademark Office. We are not
                                responsible for the privacy practices of these third-party sites and encourage you to review their policies separately. If you elect to visit external sites,
                                San Novus Trademark LLP is not responsible for the privacy policies or content of those websites. It is your responsibility to understand those policies.
                            </p>
                        </li>
                        <li>
                            <p><b>Lost or Stolen Data</b></p>
                            <p>We have robust security measures in place to prevent loss, theft, misuse, destruction, and/or alteration of user provided data. We do not have any liability to you
                                or any third party due to any loss, theft, misuse, destruction, and/or alteration of the data that you provide to us.
                            </p>
                        </li>
                        <li>
                            <p><b>Updates to This Policy</b></p>
                            <p>We may update this Privacy Policy periodically. Any changes will be posted on this page and are effective as of the date they are posted. We encourage you to review
                                this policy regularly to stay informed.
                            </p>
                        </li>
                        <li>
                            <p><b>Contact Us</b></p>
                            <p>If you have any questions regarding this Privacy Policy, please contact us at:</p>
                            <ul>
                                <li><b>Email:</b> <a href='mailto:info@sannovustrademark.com'>info@sannovustrademark.com</a></li>
                                <li><b>Phone:</b> (866) 885-3893</li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </main>
        </div>
    );
};

export default PrivacyPolicy;