import React, { useState } from "react";
import './App.css';
import Header from './header.js';
import FAQs from "./FAQs.js";
import Footer from "./footer.js";
import GetTMApplication from './getTmApplication.js';
import HelpGuide from "./useHelpGuide.js";

const TrademarkOARsPage = () => {
  const [showForm, setShowForm] =   useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const closeModal = (e) => {
    if(e.target.className === 'modal') {
      setShowForm(false);
    }
  };        

  const toggleHelpGuide = () => {
    setIsModalOpen(!isModalOpen);
  };

  const ImageEnlargeView = ({ src, onClose }) => {
    if (!src) return null;

    return (
      <div className="image-enlarge-modal" onClick={onClose}>
        <img src={src} alt="Enlarged view" style={{ maxWidth: '90%', maxHeight: '80vh' }} />
      </div>
    );
  };

  return (
      <div className='trademark-oar' style={{ fontFamily: 'Arial, sans-serif'}}>
        <main>
          <div className='comp'>
            <span className='comp-name'>Trademark Office Action Responses</span>
            <span className='comp-descrip'>Overcoming Obstacles in Your Trademark Registration Journey</span>
          </div>
          <button className='form-button' onClick={toggleForm}>Respond to Office Action</button>
          <br/>
          <div className='main-section-alt'>
            <p>Received an office action for your federal trademark application? Don’t worry, San Novus Trademark is here to help get that sorted 
              out so that you can complete your federal trademark registration. Our team, with decades of combined experience working with U.S. 
              Trademark Office examining attorneys, is skilled in obtaining allowance and registration of trademark applications. We commonly address 
              rejections such as:</p>
          </div>
          <div className='main-section'>
            <h3>Likelihood of Confusion</h3>
            <p>The examining attorney found a conflicting mark that he or she believes consumers would confuse with another brand. 
                Let us help you collaborate with the examining attorney to clarify the distinction between your mark and any conflicting 
                registrations</p>
          </div>
          <div className='main-section-alt'>
            <h3>Merely Descriptive</h3>
            <p>The examining attorney believes that your mark is describing the goods and/or services that you’re offering, not serving as 
                an identifying source of your product(s). We’ll demonstrate how your trademark is doing more than just describing the goods 
                and/or services you’re offering</p>
          </div>
          <div className='main-section'>
            <h3>Objection to Description of Goods/Services</h3>
            <p>The examining attorney has required some additional detail about the description of goods and services listed in your 
                application. We can refine your description to meet those requirements</p>
          </div>
          <div className='main-section-alt'>
            <h3>Rejection of Specimen</h3>
            <p>The examining attorney objected to the evidence of commercial use that was submitted with your trademark application. 
                The U.S. Trademark Office has become extremely particular about the specimens that it accepts as bona fide commercial use. 
                We’ll help you select appropriate evidence of use to satisfy the filing requirements. More detailed information about trademark 
                specimens can be found by clicking <a href='#' onClick={toggleHelpGuide}>here</a> or in 
                our <a href='/FAQ#faq-9' target='_blank' rel='noopener noreferrer'>FAQ</a> section.</p>
          </div>
          <div className='main-section'>
            <h3>Prior Pending Application</h3>
            <p>Similar to a likelihood of confusion rejection. The examining attorney found another application that hasn’t registered yet, 
                but that has a filing date which predates your application filing date. If this conflicting application proceeds to registration, 
                the examining attorney will issue a subsequent office action based on a likelihood of confusion</p>
          </div>
            {/* <ul>
              <li><b>Likelihood of Confusion</b>
                  <ul>
                      <li>The examining attorney found a conflicting mark that he or she believes consumers would confuse with another brand. 
                          Let us help you collaborate with the examining attorney to clarify the distinction between your mark and any conflicting 
                          registrations.</li>
                  </ul>
              </li>
              <li><b>Merely Descriptive</b>
                  <ul>
                      <li>The examining attorney believes that your mark is describing the goods and/or services that you’re offering, not serving as 
                          an identifying source of your product(s). We’ll demonstrate how your trademark is doing more than just describing the goods 
                          and/or services you’re offering.</li>
                  </ul>
              </li>
              <li><b>Objection to Description of Goods/Services</b>
                  <ul>
                      <li>The examining attorney has required some additional detail about the description of goods and services listed in your 
                          application. We can refine your description to meet those requirements.</li>
                  </ul>
              </li>
              <li><b>Rejection of Specimen</b>
                  <ul>
                      <li>The examining attorney objected to the evidence of commercial use that was submitted with your trademark application. 
                          The U.S. Trademark Office has become extremely particular about the specimens that it accepts as bona fide commercial use. 
                          We’ll help you select appropriate evidence of use to satisfy the filing requirements. More detailed information about trademark 
                          specimens can be found in our <a href='#FAQ'>FAQ</a> section.</li>
                  </ul>
              </li>
              <li><b>Prior Pending Application</b>
                  <ul>
                      <li>Similar to a likelihood of confusion rejection. The examining attorney found another application that hasn’t registered yet, 
                          but that has a filing date which predates your application filing date. If this conflicting application proceeds to registration, 
                          the examining attorney will issue a subsequent office action based on a likelihood of confusion.</li>
                  </ul>
              </li>
            </ul>   */}
            <div className='main-section-alt' style={{fontStyle: 'italic'}}>
              <p>**This isn't a comprehensive list of all possible trademark rejections but does cover the most common types of rejection.</p>
            </div>
          <div style={{marginTop:'2rem'}}>
            <button className='form-button' onClick={toggleForm}>Respond to Office Action</button>   
          </div>
          <div className='main-section'>
            <h2 className='comp-name'><i>Common Questions and Answers</i></h2>
            <FAQs tags="oar" />
          </div>
          <HelpGuide
            isOpen={isModalOpen}
            onClose={toggleHelpGuide}
            toggleHelpGuide={toggleHelpGuide}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
          <ImageEnlargeView src={selectedImage} onClose={() => setSelectedImage(null)} />
           {showForm && (
              <div onClick={closeModal}>
                <GetTMApplication toggleForm={toggleForm}/>
              </div>
            )}
        </main>
      </div>
    );
  };
  
  export default TrademarkOARsPage;