import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import { useAuth } from './AuthContext.js';
import { supabase } from './supabase.js';
import NewAppAttySig from './newApplicationAttySig.js';
import { Modal } from 'react-bootstrap';
import { setSelectionRange } from '@testing-library/user-event/dist/utils/index.js';
import { send } from 'process';
import DenyApplicationModal from './denyApplicationModal.js';

const MattersList = () => {
  // const { user, loading: authLoading } = useAuth();
  const [user, setUser] = useState('');
  const [matter, setMatter] = useState(null);
  const [activeMatterId, setActiveMatterId] = useState('');
  const [editableFields, setEditableFields] = useState({});
  const [clientData, setClientData] = useState(null);
  const [documentUrls, setDocumentUrls] = useState({ designLogo: [], specimen: {} });
  const [markType, setMarkType] = useState("");
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [classBlocks, setClassBlocks] = useState([]);
  const [fieldStates, setFieldStates] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});
  const [finalFormData, setFinalFormData] = useState({});
  const [statusFieldId, setStatusFieldId] = useState('');
  const [reviewingAtty, setReviewingAtty] = useState('');
  const [reviewingAttyFieldId, setReviewingAttyFieldId] = useState('');
  const [reviewedOnFieldId, setReviewedOnFieldId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [step, setStep] = useState(1);
  const [userSession, setUserSession] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [resettingPassword, setResettingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [verifiedNewPassword, setVerifiedNewPassword] = useState('');
  const [userData, setUserData] = useState('');
  const inputRefs = useRef([]);
  const [sigPage, setSigPage] = useState(false);
  const [teasType, setTeasType] = useState('');
  const [classBlocksCount, setClassBlocksCount] = useState(0);
  const [pdfContent, setPdfContent] = useState(null);
  const [matterNo, setMatterNo] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const [isDeny, setIsDeny] = useState(false);


  // useEffect(() => {
  //   // Fetch data and initialize form data if user is available
  //   if (user && !authLoading) {
  //     const atty = formatEmailToName(user.email);
  //     setReviewingAtty(atty);
  //   }
  // }, [user, authLoading]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const navigationType = performance.getEntriesByType('navigation')[0].type;
      if (navigationType !== 'reload') {
        // Clear any existing authentication data from local storage
        localStorage.removeItem('sntmAuth');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Check for existing session on initial load
    const checkSession = async () => {
      const authData = localStorage.getItem('sntmAttyAuth');
      if (authData) {
        const parsedData = JSON.parse(authData);

        const user = parsedData.data?.user;
        const session = parsedData.data?.session;

        if (session && session.access_token) {
          const isValidSession = await verifySession(session.access_token);
          if (isValidSession) {
            setUserData(user);
            setUser(user);
            setUserSession(session);
          } else {
            localStorage.removeItem('sntmAttyAuth');
          }
        } else {
          console.error('Session or access token is undefined');
        }
      } else {
        console.log('Did not find sntmAttyAuth cookie!');
      }
    };

    checkSession();

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const verifySession = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/verify-session`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      return response.ok;
    } catch (error) {
      return false;
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Move to the next input box if available
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);

    try {
      const otpString = otp.join('');
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/verify-atty-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, otp: otpString })
      });

      const responseText = await response.text(); // Get the raw response text

      if (!response.ok) {
        throw new Error('Invalid OTP');
      }

      // Parse the JSON response
      const responseData = JSON.parse(responseText);

      try {
        localStorage.setItem('sntmAttyAuth', JSON.stringify(responseData));
        console.log('Successfully set sntmAttyAuth cookie:', JSON.stringify(responseData));
      } catch (error) {
        console.log('Unexpected error setting sntmAuth cookie:', error);
      }

      const parsedUser = responseData.data.user;
      const parsedSession = responseData.data.session;

      if (resettingPassword) {

        setStep(3); // Proceed to password reset

      } else {
        setUserData(parsedUser);
        setUser(parsedUser);
        setUserSession(parsedSession);
        setStep(1); // Proceed to attorney review page after login

      }

      // Clear OTP state after successful verification
      setOtp(new Array(6).fill(''));
    } catch (error) {
      setErrorMessage(error.message);
      console.error('Error in handleVerifyOtp:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleLogin = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);

    await supabase.auth.signOut();
    localStorage.removeItem('sntmAttyAuth');
    sessionStorage.clear();

    setOtp(new Array(6).fill('')); // Reset OTP state to empty array

    // Clear any existing authentication data from local storage
    localStorage.removeItem('sntmAttyAuth');

    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/attyPortalPasswordLogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (!response.ok) {
        throw new Error('Invalid email or password');
      }
      // // Send OTP
      // const otpResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-otp`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ email })
      // });

      // if (!otpResponse.ok) {
      //   throw new Error('Failed to send OTP');
      // }
      // Parse the JSON response

      const responseText = await response.text(); // Get the raw response text

      const responseData = JSON.parse(responseText);

      try {
        localStorage.setItem('sntmAttyAuth', JSON.stringify(responseData));
        console.log('Successfully set sntmAttyAuth cookie:', JSON.stringify(responseData));
      } catch (error) {
        console.log('Unexpected error setting sntmAuth cookie:', error);
      }

      const parsedUser = responseData.data.user;
      const parsedSession = responseData.data.session;

      setUser(parsedUser);
      setUserSession(parsedSession);

      setStep(4);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    // Clear any existing authentication data from local storage
    localStorage.removeItem('sntmAuth');

    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/clientPortalSignOut`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Error Signing Out');
      }

      //Reset state
      setUserData(null);
      setUser(null);
      setUserSession(null);
      setStep(1);
      setOtp(new Array(6).fill('')); // Reset OTP state to empty array
    } catch (error) {
      setErrorMessage(error.message);
    }
  }

  const handleForgotPassword = async () => {
    if (!email) {
      setErrorMessage('Please enter email address associated with account');
      return;
    }
    setLoading(true);
    setResettingPassword(true);
    setErrorMessage('');
    try {
      const otpResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-atty-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (!otpResponse.ok) {
        throw new Error('Failed to send OTP');
      }

      setStep(2); // Move to OTP verification step
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);

    // Check if the passwords match
    if (newPassword !== verifiedNewPassword) {
      setErrorMessage('Passwords do not match');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/reset-atty-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, newPassword })
      });

      if (!response.ok) {
        throw new Error('Failed to reset password. Please try again.');
      }

      alert('Password reset successfully. Please log in with your new password.');
      setStep(1); // Move back to login step
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
      setResettingPassword(false);
    }
  };

  const formatEmailToName = (email) => {
    // Extract the part before '@'
    const username = email.split('@')[0];

    // Split the username into first character and remaining characters
    const firstCharacter = username.charAt(0).toUpperCase();
    const remainingCharacters = username.slice(1).toLowerCase();

    // Capitalize the first letter of remainingCharacters
    const formattedRemainingCharacters = remainingCharacters.charAt(0).toUpperCase() + remainingCharacters.slice(1);


    // Combine to form "F. Last"
    return `${firstCharacter}. ${formattedRemainingCharacters}`;
  };

  //Exclude Clio fields unneccessary for attorney application review
  const excludeFields = [
    'Application Filing Date', 'Registration Date', 'Application Review Date',
    'Register Type', 'Trademark Status', 'Application Serial Number', 'Registration Number', 'Application Reviewed By'
  ];

  //Clio field identifiers for listed types
  const markTypeMapping = {
    '9417019': 'Word Mark',
    '9417034': 'Design Logo',
    '9417049': 'Sound Mark'
  };

  //useEffect to get type of trademark (word, design, sound) then create array of class blocks.
  useEffect(() => {
    if (matter) {
      const markTypeField = matter.custom_field_values.find(field => field.field_name === "Mark Type");
      if (markTypeField) {
        const type = markTypeMapping[markTypeField.value.toString()] || "";
        setMarkType(type);
      }

      const blocks = {};
      matter.custom_field_values.forEach(field => {
        const match = field.field_name.match(/\d+$/);
        if (match) {
          const id = match[0];
          blocks[id] = blocks[id] || {};
          blocks[id][field.field_name.replace(/\s+\d+$/, '')] = field;
        }
      });

      setClassBlocks(Object.values(blocks));
    }
  }, [matter]);

  //Function to reset all states
  const resetStates = () => {
    setMatter(null);
    setEditableFields({});
    setClientData(null);
    setDocumentUrls({ designLogo: [], specimen: {} });
    setMarkType("");
    setEnlargedImage(null);
    setClassBlocks([]);
    setFieldStates({});
    setLoading(false);
    setStatusFieldId('');
    setActiveMatterId('');
    setReviewingAttyFieldId('');
    setReviewedOnFieldId('');
  };

  const handleMatterNoChange = (event) => {
    setMatterNo(event.target.value);
  };

  const handleMatterSearch = async () => {
    if (!matterNo.trim()) {
      console.error('Please enter a valid matter number');
      return;
    }

    console.log('Now searching for: ', matterNo);

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/attyMatterSearch?query=${matterNo}`);
      const data = await response.json();

      if (data.data && data.data.length > 0) {
        const foundMatter = data.data[0]; // Assuming first match is the desired one
        console.log('Found the matter!: ', foundMatter.display_number);
        setMatter(foundMatter);

        if (foundMatter.id) {
          const trademarkStatusField = foundMatter.custom_field_values.find(field => field.field_name === "Trademark Status");
          const trademarkReviewingAtty = foundMatter.custom_field_values.find(field => field.field_name === "Application Reviewed By");
          const reviewedOn = foundMatter.custom_field_values.find(field => field.field_name === "Application Reviewed On");

          const trademarkStatusFieldId = trademarkStatusField ? trademarkStatusField.id : null;
          const trademarkReviewingAttyId = trademarkReviewingAtty ? trademarkReviewingAtty.id : null;
          const reviewedOnId = reviewedOn ? reviewedOn.id : null;

          const teasTypeField = foundMatter.custom_field_values.find(field => field.field_name === "TEAS Type");
          const teasType = teasTypeField ? teasTypeField.value : "N/A";

          // Count number of class blocks
          const classBlocksCount = foundMatter.custom_field_values.filter(field =>
            field.field_name.startsWith("Class")
          ).length;

          setStatusFieldId(trademarkStatusFieldId);
          setActiveMatterId(foundMatter.id);
          setReviewingAttyFieldId(trademarkReviewingAttyId);
          setReviewedOnFieldId(reviewedOnId);
          setTeasType(teasType);
          console.log('teasType: ', teasType);
          setClassBlocksCount(classBlocksCount);

          if (trademarkStatusFieldId) {
            // await matterUnderReview(foundMatter.id, trademarkStatusFieldId); //Sends PATCH command to Clio to update status of file within Clio. Intent here is to prevent multiple attorneys from reviewing the same application
          } else {
            console.error('Trademark Status field ID not found');
          }

          await fetchRelatedContacts(foundMatter.id); //Gets trademark owner contact information from Clio
          await fetchDocs(foundMatter.id); //Gets any specimen files (images) from Clio
        } else {
          console.error('Matter ID is undefined');
        }

        const initialFields = {};
        foundMatter.custom_field_values.forEach((field) => {
          if (field.value) {
            initialFields[field.field_name] = field.value;
          }
        });

      } else {
        setMatter(null);
      }
    } catch (error) {
      console.error('Error fetching matter:', error);
    }

    setLoading(false);
  };

  //Function to fetch singular matter from Clio, first making sure that all states are cleared so that information from previous application(s)
  // do not erroneously populate with current application information
  const fetchMatter = async () => {
    resetStates();
    setLoading(true); // To provide a visual indication to the user that the computer is working
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/attyMatterReview`); // back end endpoint for fetching matters from Clio
      const data = await response.json();
      if (data.data && data.data.length > 0) {
        const firstMatter = data.data[0];
        setMatter(firstMatter); // gets only the first result

        //Populate all viewable fields with information from the first retrieved matter
        if (firstMatter.id) {

          const trademarkStatusField = firstMatter.custom_field_values.find(field => field.field_name === "Trademark Status");
          const trademarkReviewingAtty = firstMatter.custom_field_values.find(field => field.field_name === "Application Reviewed By");
          const reviewedOn = firstMatter.custom_field_values.find(field => field.field_name === "Application Reviewed On");

          const trademarkStatusFieldId = trademarkStatusField ? trademarkStatusField.id : null;
          const trademarkReviewingAttyId = trademarkReviewingAtty ? trademarkReviewingAtty.id : null;
          const reviewedOnId = reviewedOn ? reviewedOn.id : null;

          const teasTypeField = firstMatter.custom_field_values.find(field => field.field_name === "TEAS Type");
          const teasType = teasTypeField ? teasTypeField.value : "N/A";

          // Count number of class blocks
          const classBlocksCount = firstMatter.custom_field_values.filter(field =>
            field.field_name.startsWith("Class")
          ).length;

          setStatusFieldId(trademarkStatusFieldId);
          setActiveMatterId(firstMatter.id);
          setReviewingAttyFieldId(trademarkReviewingAttyId);
          setReviewedOnFieldId(reviewedOnId);
          setTeasType(teasType);
          setClassBlocksCount(classBlocksCount);

          if (trademarkStatusFieldId) {
            await matterUnderReview(firstMatter.id, trademarkStatusFieldId); //Sends PATCH command to Clio to update status of file within Clio. Intent here is to prevent multiple attorneys from reviewing the same application
          } else {
            console.error('Trademark Status field ID not found');
          }

          await fetchRelatedContacts(firstMatter.id); //Gets trademark owner contact information from Clio
          await fetchDocs(firstMatter.id); //Gets any specimen files (images) from Clio
        } else {
          console.error('Matter ID is undefined');
        }

        const initialFields = {};
        firstMatter.custom_field_values.forEach((field) => {
          if (field.value) {
            initialFields[field.field_name] = field.value;
          }
        });
        setEditableFields(initialFields);
      } else {
        setMatter(null);
      }
    } catch (error) {
      console.error('Error fetching matters:', error);
    }
    setLoading(false);
  };

  //Function to update matter status within Clio - prevents multiple attorneys from reviewing the same matter and duplicating efforts
  const matterUnderReview = async (matterId, trademarkStatusFieldId) => {
    const url = `${process.env.REACT_APP_DOMAIN}/api/v4/matters/${matterId}/status`;
    const statusUpdate = {
      data: {
        "custom_field_values": [
          {
            "id": trademarkStatusFieldId,
            "custom_field": { "id": "16055884" },
            "value": "9744529" //Clio id for "Under Review" in combobox
          }
        ]
      }
    };

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(statusUpdate)
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
    } catch (error) {
      console.error('Error updating matter status:', error);
    }
  };

  useEffect(() => {
    console.log("sigPage state changed:", sigPage);
  }, [sigPage]);

  //Function to update matter status within Clio to indicate that application is ready to file
  const matterApproved = async () => {
    console.log("Approve button clicked, setting sigPage to true");
    setSigPage(true);
    // const url = `${process.env.REACT_APP_DOMAIN}/api/v4/matters/${activeMatterId}/status`;
    // const approvedDate = await formatDateTime();

    // const statusUpdate = {
    //   data: {
    //     "custom_field_values": [
    //       {
    //         "id": statusFieldId,
    //         "custom_field": { "id": "16055884" },
    //         "value": "9416689" //Clio ID for "Ready to File" status
    //       },
    //       {
    //         "id": reviewingAttyFieldId,
    //         "custom_field": { "id": "16055404" },
    //         "value": reviewingAtty //Inputs first inital and last name (e.g. "S. Adams") in appropriate field in Clio
    //       },
    //       {
    //         "id": reviewedOnFieldId,
    //         "custom_field": { "id": "18269225" },
    //         "value": approvedDate //Inputs date/time stamp in appropriate field in Clio
    //       }
    //     ]
    //   }
    // };

    // try {
    //   const response = await fetch(url, {
    //     method: 'PATCH',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(statusUpdate)
    //   });
    //   if (!response.ok) {
    //     throw new Error(`Network response was not ok: ${response.statusText}`);
    //   }
    //   const data = await response.json();
    // } catch (error) {
    //   console.error('Error updating matter status:', error);
    // }
  };

  //Function to change status of matter within Clio to 'Ready to File'
  const approvedMatterStatus = async () => {
    const url = `${process.env.REACT_APP_DOMAIN}/api/v4/matters/${activeMatterId}/status`;
    const approvedDate = await formatDateTime();

    const statusUpdate = {
      data: {
        "custom_field_values": [
          {
            "id": statusFieldId,
            "custom_field": { "id": "16055884" },
            "value": "9416689" //Clio ID for "Ready to File" status
          },
          {
            "id": reviewingAttyFieldId,
            "custom_field": { "id": "16055404" },
            "value": reviewingAtty //Inputs first inital and last name (e.g. "S. Adams") in appropriate field in Clio
          },
          {
            "id": reviewedOnFieldId,
            "custom_field": { "id": "18269225" },
            "value": approvedDate //Inputs date/time stamp in appropriate field in Clio
          }
        ]
      }
    };

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(statusUpdate)
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
    } catch (error) {
      console.error('Error updating matter status:', error);
    }
  };

  //Function to change status of matter within Clio to 'Ready to File'
  const deniedMatterStatus = async () => {
    const url = `${process.env.REACT_APP_DOMAIN}/api/v4/matters/${activeMatterId}/status`;
    // const approvedDate = await formatDateTime();

    const statusUpdate = {
      data: {
        "custom_field_values": [
          {
            "id": statusFieldId,
            "custom_field": { "id": "16055884" },
            "value": "11264967" //Clio ID for "Ready to File" status
          },
          // {
          //   "id": reviewingAttyFieldId,
          //   "custom_field": { "id": "16055404" },
          //   "value": reviewingAtty //Inputs first inital and last name (e.g. "S. Adams") in appropriate field in Clio
          // },
          // {
          //   "id": reviewedOnFieldId,
          //   "custom_field": { "id": "18269225" },
          //   "value": approvedDate //Inputs date/time stamp in appropriate field in Clio
          // }
        ]
      }
    };

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(statusUpdate)
      });
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
    } catch (error) {
      console.error('Error updating matter status:', error);
    }
  };

  //Function to format date/time stamp for entry into Clio
  //Final result will be "MM/DD/YYYY"
  const formatDateTime = () => {
    const now = new Date();

    // Extract date components
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const year = now.getFullYear();

    // Extract time components
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    // Get timezone offset in hours and minutes
    const timezoneOffset = now.getTimezoneOffset();
    const timezoneHours = String(Math.abs(Math.floor(timezoneOffset / 60))).padStart(2, '0');
    const timezoneMinutes = String(Math.abs(timezoneOffset % 60)).padStart(2, '0');
    const timezoneSign = timezoneOffset > 0 ? '-' : '+';

    // Format the date and time string with "AZ TIME"
    // const formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}:${seconds} AZ TIME`;
    const formattedDateTime = `${month}/${day}/${year}`;

    return formattedDateTime;
  };

  // Example usage
  // console.log(formatDateTime());

  //Function to fetch client information for first retrieved matter
  const fetchClient = async (matterId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/matters/${matterId}/client`);
      const data = await response.json();
      // console.log('fetchClient:', data);
      setClientData(data.data);
      // console.log('clientData after fetchClient:', data.data);
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };

  //Function to fetch related contacts for first retrieved matter. This is necessary because the "client" might be a foreign law firm but the actual trademark owner might be 
  //that foreign law firm's client, which would be a "related contact" in Clio
  const fetchRelatedContacts = async (matterId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/matters/${matterId}/related_contacts`);
      const data = await response.json();
      const trademarkOwner = data.data.find(contact => contact.relationship && contact.relationship.description === "Trademark Owner");
      // console.log('fetchRelatedContacts:', data);
      if (trademarkOwner) {
        setClientData(trademarkOwner);
        // console.log('clientData after fetchRelatedContacts:', trademarkOwner);
      } else {
        await fetchClient(matterId);
      }
    } catch (error) {
      console.error('Error fetching related contacts:', error);
    }
  };

  //Function to fetch specimen files from Clio so that they reviewing attorney can review/approve it
  //Intent here is to create a series of requests and perform all queries at once, for efficiency. Each individual document will have a unique URL within Clio.
  const fetchDocs = async (matterId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/documents/${matterId}`);
      const data = await response.json();

      // console.log('All Retrieved Docs: ', data);

      if (!data.data || data.data.length === 0) {
        console.warn("No documents found for this matter.");
        return;
      }

      // data.data.forEach(doc => {
      //   console.log(`Doc ID: ${doc.id}, Name: ${doc.name}, Category: ${doc.document_category?.name || 'None'}`);
      // });

      const uniqueDocs = new Map();

      data.data.forEach(doc => {
        const isSpecimenByName = doc.name.toLowerCase().includes("specimen");
        const isDesignLogo = doc.document_category?.name === "Design Logo";
        const isSpecimenByCategory = doc.document_category?.name === "Specimen";

        if (isSpecimenByName || isSpecimenByCategory || isDesignLogo) {
          // console.log(`✅ Selected Doc for Processing: ${doc.name} (Category: ${doc.document_category?.name || 'None'})`);
          uniqueDocs.set(doc.id, doc);
        }
      });

      // console.log("Filtered Docs for Download:", Array.from(uniqueDocs.values()));

      const downloadPromises = Array.from(uniqueDocs.values()).map(doc => {
        const category = doc.document_category?.name?.toLowerCase().replace(" ", "") ||
          (doc.name.toLowerCase().includes("specimen") ? "specimen" : "unknown");

        const classBlockNumberMatch = doc.name.match(/(\d{3})/);
        const classBlockNumber = classBlockNumberMatch ? classBlockNumberMatch[1] : undefined;

        return downloadDocs(doc.id, category, classBlockNumber);
      });

      const downloadedDocs = await Promise.all(downloadPromises);

      const newDocumentUrls = downloadedDocs.reduce((acc, { category, url, classBlockNumber }) => {
        if (category === "designlogo") {
          acc.designLogo = [...new Set([...acc.designLogo, url])];
        } else if (category === "specimen" && classBlockNumber) {
          acc.specimen[classBlockNumber] = [...new Set([...(acc.specimen[classBlockNumber] || []), url])];
        }
        return acc;
      }, { designLogo: [], specimen: {} });

      // console.log("Final Document URLs:", newDocumentUrls);
      setDocumentUrls(newDocumentUrls);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
    setLoading(false);
  };


  // Function to download a document and return its URL
  const downloadDocs = async (docId, category, classBlockNumber) => {
    try {
      // console.log(`⬇️ Downloading Doc ID: ${docId}, Category: ${category}, Class Block: ${classBlockNumber}`);

      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/v4/documents/${docId}/download`);
      if (!response.ok) {
        throw new Error(`Download failed for Doc ID: ${docId}, Status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      // console.log(`✅ Successfully Downloaded: Doc ID: ${docId}, URL: ${url}`);
      return { category, url, classBlockNumber };
    } catch (error) {
      console.error(`❌ Error downloading document: ${docId}`, error);
      return { category, url: '', classBlockNumber };
    }
  };

  //Function no longer used - intent was originally to allow attorneys to update application information
  const handleFieldChange = (fieldName, value) => {
    setFieldStates(prevStates => ({
      ...prevStates,
      [fieldName]: {
        ...prevStates[fieldName],
        value: value
      }
    }));
  };

  //Function to selectively render certain fields depending on trademark type. For example, if mark is a "word mark" then there is no need to render the design logo field or the description of the logo
  const renderFieldsBasedOnMarkType = () => {
    let fieldsToDisplay = [];

    if (markType === "Word Mark") {
      fieldsToDisplay = [
        renderField("Mark Type"),
        renderField("Mark"),
      ];
    } else if (markType === "Design Logo") {
      fieldsToDisplay = [
        renderField("Mark Type"),
        renderDocuments("designLogo"),
        renderField("Literal Element"),
        renderField("Color Claim"),
      ];
    }

    return fieldsToDisplay;
  };

  //Function to render all fields containing application information on page
  const renderField = (fieldName) => {
    const field = matter.custom_field_values.find(f => f.field_name === fieldName);
    if (!field) return null;

    if (!fieldStates[fieldName]) {
      const initialValue = markTypeMapping[field.value] || field.value;
      fieldStates[fieldName] = { value: initialValue || '', isEditable: false };
    }

    const displayValue = fieldStates[fieldName].value;

    const handleBlur = () => {
      setFieldStates(prevStates => ({
        ...prevStates,
        [fieldName]: {
          ...prevStates[fieldName],
          isEditable: false
        }
      }));
    };

    return (
      <div key={fieldName} className='info-line mb-2'>
        <label className="form-label">{fieldName}:</label>
        <input
          type="text"
          className="form-control"
          value={displayValue || ''}
          onChange={(e) => handleFieldChange(fieldName, e.target.value)}
          readOnly={!fieldStates[fieldName].isEditable}
          onBlur={handleBlur}
        />
        {/* <button onClick={() => toggleEdit(fieldName)} className='btn btn-primary mt-1'>Edit</button> */}
      </div>
    );
  };

  //Function for displaying downloaded images/documents
  const renderDocuments = (category) => {
    // console.log(`Rendering documents for category: ${category}`, documentUrls[category]);

    if (!documentUrls[category] || Object.keys(documentUrls[category]).length === 0) {
      console.warn(`No documents found for category: ${category}`);
      return null;
    }

    return (
      <div className='info-line mb-3'>
        <div className="document-header">{category === "specimen" ? "Specimen(s):" : "Design Logo:"}</div>
        <div className="row">
          {Object.entries(documentUrls[category]).map(([classBlockNumber, urls]) =>
            urls.map((url, index) => {
              // console.log(`Rendering Image - Class: ${classBlockNumber}, URL: ${url}`);
              return (
                <div key={`${category}-${classBlockNumber}-${index}`} className="col-6 col-md-4 col-lg-3">
                  <img
                    src={url}
                    alt={`${category} Document ${index}`}
                    className='img-fluid'
                    onClick={() => setEnlargedImage(url)}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };


  const handleImageClick = (url) => {
    setEnlargedImage(url);
  };

  const handleCloseEnlargedImage = () => {
    setEnlargedImage(null);
  };

  const renderClassBlock = (block, index) => {
    // const classBlockNumber = String(index + 1).padStart(3, '0');
    const classBlockNumber = block["Class"]?.value?.padStart(3, '0');

    // console.log(`Rendering Class Block ${classBlockNumber} - Current documentUrls.specimen:`, documentUrls.specimen);

    // const specimensForBlock = documentUrls.specimen[classBlockNumber] || [];
    const specimensForBlock = classBlockNumber ? documentUrls.specimen[classBlockNumber] || [] : [];

    // console.log(`Rendering Class Block ${classBlockNumber} - Specimens:`, specimensForBlock);

    const fieldsOrder = [
      `Class ${classBlockNumber}`,
      `Description ${classBlockNumber}`
    ];

    const fieldsToRender = fieldsOrder.map(fieldName => {
      const fieldKey = fieldName.replace(/\s+\d+$/, ''); // Extract "Class" or "Description"
      const field = block[fieldKey];
      if (!field) return null;

      if (!fieldStates[fieldName]) {
        const initialValue = field.picklist_option ? field.picklist_option.option : field.value;
        setFieldStates(prevStates => ({
          ...prevStates,
          [fieldName]: { value: initialValue || '', isEditable: false }
        }));
      }

      const value = fieldStates[fieldName] ? fieldStates[fieldName].value : '';

      const handleBlur = () => {
        setFieldStates(prevStates => ({
          ...prevStates,
          [fieldName]: {
            ...prevStates[fieldName],
            isEditable: false
          }
        }));
      };

      return (
        <div key={fieldName} className='info-line mb-2 d-flex align-items-start'>
          <label className="form-label atty-review-class-label">{fieldName}:</label>
          <textarea
            type="text"
            className="form-control atty-review-class-description"
            value={value || ''}
            onChange={(e) => handleFieldChange(fieldName, e.target.value)}
            readOnly={!fieldStates[fieldName]?.isEditable}
            onBlur={handleBlur}
            rows={Math.max(1, Math.min(10, value.split(/\r\n|\r|\n/).length + 1))} 
            style={{resize: 'vertical', minHeight: '60px', width: '100%'}}
          />
          {/* Show the flag ONLY for "Description" fields */}
          {fieldKey === "Description" && (
            teasType === '9892024' ? (
              <span className='atty-review-description-flag-free-form'>This is a free-form description entry. Please review closely</span>
            ) : (
              <span className='atty-review-description-flag-teasPlus'>This entry matches the ID manual</span>
            )
          )}
          {/* <button onClick={() => toggleEdit(fieldName)} className='btn btn-primary mt-1'>Edit</button> */}
        </div>
      );
    });

    const dateOfFirstUseField = block["Date of First Use"];
    const dateOfFirstUseInCommerceField = block["Date of First Use in Commerce"];
    const hasFirstUseDates = (dateOfFirstUseField && dateOfFirstUseField.value) || (dateOfFirstUseInCommerceField && dateOfFirstUseInCommerceField.value);

    if (dateOfFirstUseField && dateOfFirstUseField.value) {
      fieldsToRender.push(
        <div key={`Date of First Use ${classBlockNumber}`} className='info-line mb-2'>
          <label className="form-label">Date of First Use:</label>
          <input
            type="text"
            className="form-control"
            value={dateOfFirstUseField.value || ''}
            readOnly
          />
        </div>
      );
    }

    if (dateOfFirstUseInCommerceField && dateOfFirstUseInCommerceField.value) {
      fieldsToRender.push(
        <div key={`Date of First Use in Commerce ${classBlockNumber}`} className='info-line mb-2'>
          <label className="form-label">Date of First Use in Commerce:</label>
          <input
            type="text"
            className="form-control"
            value={dateOfFirstUseInCommerceField.value || ''}
            readOnly
          />
        </div>
      );
    }

    return (
      <div key={index} className="app-review-class-block mb-3">
        {fieldsToRender}

        {!hasFirstUseDates && (
          <div className='info-line mb-2'>
            <label className="form-label">Use:</label>
            <span>Intent-to-Use</span>
          </div>
        )}

        {specimensForBlock.length > 0 && (
          <div className='specimen-container'>
            <div className="document-header">Specimen(s) for Class {classBlockNumber}:</div>
            <div className="row">
              {specimensForBlock.map((url, idx) => {
                const isValidUrl = url && typeof url === 'string';
                return isValidUrl ? (
                  <div key={`specimen-${classBlockNumber}-${idx}`} className="col-6 col-md-4 col-lg-3">
                    <img
                      src={url}
                      alt={`Specimen for Class ${classBlockNumber}`}
                      className='img-fluid'
                      onClick={() => setEnlargedImage(url)}
                    />
                  </div>
                ) : null;
              })}
            </div>
          </div>
        )}
      </div>
    );
  };


  //No longer used function. This was for an "edit" button to allow attorneys to update application information
  const toggleEdit = (fieldName) => {
    setFieldStates(prevStates => ({
      ...prevStates,
      [fieldName]: {
        ...prevStates[fieldName],
        isEditable: !prevStates[fieldName].isEditable
      }
    }));
  };

  let isGeneratingPDF = false;

  // Trigger PDF generation and download
  const generateSignaturePDF = async (pdfContent) => {

    if (isGeneratingPDF) return;
    isGeneratingPDF = true;

    console.log('Successfully hit generateSignaturePDF function!');
    console.log('generateSignaturePDF Matter: ', matter);

    if (!pdfContent) {
      console.error("Missing PDF content, unable to generate signature PDF page");
      return;
    }

    const matterId = matter?.id;
    console.log('matterId: ', matterId);

    if (!matterId) {
      console.error("Matter ID is not available, unable to generate PDF and upload to Clio");
      return;
    }

    const url = `${process.env.REACT_APP_DOMAIN}/api/generate-signature-pdf`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          html: pdfContent,
          signature: {
            text: '',
            font: "Lucida Handwriting"
          },
          dateTime: '',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate PDF');
      }

      const pdfBuffer = await response.arrayBuffer();

      // Convert PDF buffer to File and save it to formData
      const signatureFile = new File([pdfBuffer], 'Application Signature.pdf', { type: 'application/pdf' });

      //Upload the signature page to Clio
      await uploadPdfToClio(signatureFile, matterId);

      //Set matter status in Clio to 'Ready to File'
      await approvedMatterStatus();

      //Send confirmation email to admin about application approval
      await sendApprovalEmail("approve");

      document.body.style.cursor = 'default';
      setIsWorking(false);

      // alert("Signature Page Successfully Uploaded!");
      setSigPage(false);
      setMatterNo('');
      setMatter(null);

    } catch (error) {
      console.error("Error generating signature PDF: ", error);
    }
  };

  //Function to upload signature page to Clio and place in the "USPTO Filing" folder
  const uploadPdfToClio = async (pdfFile, matterId) => {
    if (!pdfFile || !matterId) {
      console.error("Missing PDF file or Matter ID");
      return;
    }

    const url = `${process.env.REACT_APP_DOMAIN}/api/create-and-upload-app-sig-page`;

    const formData = new FormData();
    formData.append("file", pdfFile);
    formData.append("matterId", matterId);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,  // Sending as multipart/form-data
      });

      if (!response.ok) {
        throw new Error('Failed to upload PDF to Clio');
      }

      console.log("PDF successfully uploaded to Clio");
    } catch (error) {
      console.error("Error uploading PDF to Clio:", error);
    }
  };

  const sendApprovalEmail = async (action) => {
    if (!user || !matter) {
      console.error("User or Matter data is missing.");
      return;
    }

    // Extract first initial and last name
    const firstInitial = user.user_metadata.first_name ? user.user_metadata.first_name.charAt(0) + "." : "";
    const lastName = user.user_metadata.last_name || "";

    // Define subject and message based on action
    const subject =
      action === "approve"
        ? `${matter.display_number}; Application Approved for Filing`
        : `${matter.display_number}; Application Denied - Requires Further Action`;

    const emailBody = `
        <p>${firstInitial} ${lastName} has ${action === "approve" ? "approved" : "denied"
      } matter no. <strong>${matter.display_number}</strong> for filing.</p>
        ${action === "approve"
        ? "<p>Please proceed with the application filing at your next availability.</p>"
        : "<p>The application requires further review. Please review the matter and take appropriate action.</p>"
      }
    `;

    const emailPayload = {
      subject: subject,
      html: emailBody,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/sendAttyReviewEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      });

      if (!response.ok) {
        throw new Error(`Failed to send email: ${response.statusText}`);
      }

      console.log("Approval email sent successfully!");
    } catch (error) {
      console.error("Error sending approval email:", error);
    }
  };

  const handleDeny = async (reasons, comments) => {
    document.body.style.cursor = 'wait';
    setIsWorking(true);

    try {

      await deniedMatterStatus();
      await sendDenialEmail(reasons, comments);

      // Clear matter data and close the modal after successful email sending
      setMatter(null);
      setMatterNo('');
      setIsDeny(false);

    } catch (error) {
      console.error("Error processing denial:", error);
    } finally {
      document.body.style.cursor = 'default';
      setIsWorking(false);
    }
  };

  const sendDenialEmail = async (reasons, comments) => {
    const sender = `${user.user_metadata.first_name.charAt(0)}. ${user.user_metadata.last_name}`;
    const subject = `${matter.display_number}: Application Denied`;

    const htmlBody = `
        <p><b>${sender}</b> has denied matter no. <b>${matter.display_number}</b> for the following reason(s):</p>
        <ul>
            ${reasons.map(reason => `<li>${reason}</li>`).join('')}
        </ul>
        ${comments ? `<p><b>Additional Comments:</b> ${comments}</p>` : ''}
        <p>Please address these issues before proceeding with the filing.</p>
    `;

    const url = `${process.env.REACT_APP_DOMAIN}/api/sendAttyReviewEmail`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subject, html: htmlBody }),
      });

      if (!response.ok) {
        throw new Error('Failed to send denial email');
      }

      // alert("Denial email sent successfully.");
    } catch (error) {
      console.error("Error sending denial email: ", error);
      throw error;  // Ensure calling function (handleDeny) catches the error
    }
  };

  // UI Components
  <div className='row justify-content-center'>
    <div className='col-12 app-review-buttons-container'>
      <button onClick={fetchMatter} className='btn btn-primary mb-3 atty-review-button'>Review Next Matter</button>
      <button onClick={() => setIsDeny(true)} className='btn btn-danger mb-3 atty-review-button atty-deny-button'>Deny</button>
      <button onClick={matterApproved} className='btn btn-primary mb-3 atty-review-button'>Approve</button>
    </div>
  </div>

  {
    isDeny && (
      <Modal show={isDeny} onHide={() => setIsDeny(false)} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Reasons for Denial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DenyApplicationModal
            setIsDeny={setIsDeny}
            handleClose={() => setIsDeny(false)}
            handleDeny={handleDeny}
          />
        </Modal.Body>
      </Modal>
    )
  }



  if (loading) {
    return <div className="text-center mt-5 atty-review-loading">Loading...</div>;
  }

  return (
    <div className="container mt-5 client-portal-container">
      {step === 1 && (
        <div className="container mt-5">
          {/* <h2>Attorney Login</h2> */}
          <form onSubmit={handleLogin}>
            <div className="row mb-3">
              <div className="col-12">
                <label htmlFor="emailInput" className="form-label">Email:</label>
                <input
                  type="email"
                  id="emailInput"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <label htmlFor="passwordInput" className="form-label">Password:</label>
                <input
                  type="password"
                  id="passwordInput"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            {errorMessage && <div className="row mb-3">
              <div className="col-12">
                <div className="alert alert-danger">{errorMessage}</div>
              </div>
            </div>}
            <div className="row">
              <div className="col-12 d-flex justify-content-between">
                <button type="submit" className={loading ? "btn btn-primary login-button client-portal-login-button-loading" : "btn btn-primary login-button client-portal-login-button"}>Login</button>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <span onClick={handleForgotPassword} className='forgot-password'>Forgot Password?</span>
              </div>
            </div>
          </form>
        </div>
      )}
      {step === 2 && (
        <form onSubmit={handleVerifyOtp}>
          <div className="col-12">
            <div><i>Please check your email and provide the six digit one time password to proceed</i></div>
          </div>
          <div className="row mb-3">
            <div className="col-12 d-flex justify-content-center">
              {otp.map((data, index) => {
                return (
                  <input
                    key={index}
                    type="text"
                    className="form-control otp-input mx-1"
                    maxLength="1"
                    value={data}
                    onChange={(e) => handleOtpChange(e.target, index)}
                    ref={el => inputRefs.current[index] = el}
                    style={{ width: '40px', textAlign: 'center' }}
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                );
              })}
            </div>
          </div>
          {errorMessage && (
            <div className="row mb-3">
              <div className="col-12">
                <div className="alert alert-danger">{errorMessage}</div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <button type="submit" className={loading ? "btn btn-primary login-button client-portal-login-button-loading" : "btn btn-primary login-button client-portal-login-button"}>Submit</button>
            </div>
          </div>
        </form>
      )}
      {step === 3 && (
        <form onSubmit={handlePasswordReset}>
          <div className="row mb-3">
            <div className="col-12">
              <label htmlFor="newPasswordInput" className="form-label">New Password:</label>
              <input
                type="password"
                id="newPasswordInput"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="col-12 mt-3">
              <label htmlFor="verifyNewPasswordInput" className="form-label">Verify New Password:</label>
              <input
                type="password"
                id="verifyNewPasswordInput"
                className="form-control"
                value={verifiedNewPassword}
                onChange={(e) => setVerifiedNewPassword(e.target.value)}
              />
            </div>
          </div>
          {errorMessage && <div className="row mb-3">
            <div className="col-12">
              <div className="alert alert-danger">{errorMessage}</div>
            </div>
          </div>}
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <button type="submit" className="btn btn-primary">Reset Password</button>
            </div>
          </div>
        </form>
      )}
      {step === 4 && (
        <div className='container mt-3 app-review-container'>
          <div className="row justify-content-center mb-3">
            <div className="col-auto d-flex align-items-center gap-2">
              <input
                type="text"
                id="matterNo"
                value={matterNo}
                onChange={handleMatterNoChange}
                placeholder="Enter Matter Number..."
                className="form-control atty-search-input"
              />
              <button
                className="btn btn-primary atty-search-button d-flex align-items-center"
                onClick={handleMatterSearch}
              >
                Search
              </button>
            </div>
          </div>
          <div className='row justify content-center'>
            <div className='col-12 app-review-buttons-container'>
              <button onClick={fetchMatter} className='btn btn-primary mb-3 atty-review-button'>Review Next Matter</button>
              <button onClick={() => setIsDeny(true)} className='btn btn-primary mb-3 atty-review-button atty-deny-button'>Deny</button>
              <button onClick={matterApproved} className='btn btn-primary mb-3 atty-review-button'>Approve</button>
            </div>
          </div>
          {matter && clientData ? (
            <div className='row'>
              <div className='col-12'>
                <div className='info-line mb-2'>
                  <label className="form-label">Matter Number:</label>
                  <span>{matter.display_number}</span>
                </div>
                <div className="client-review mb-3">
                  <div className='app-section-header mb-2'>
                    <label>Contact:</label>
                  </div>

                  <div className="info-line mb-2">
                    <label className="form-label">Name:</label>
                    <span>{clientData.name}</span>
                  </div>

                  {clientData.addresses?.length > 0 && (
                    <div className="info-line mb-2">
                      <label className="form-label">Address:</label>
                      <span>
                        {clientData.addresses[0].street}<br />
                        {`${clientData.addresses[0].city}, ${clientData.addresses[0].province} ${clientData.addresses[0].postal_code}`}<br />
                        {clientData.addresses[0].country}
                      </span>
                    </div>
                  )}

                  {clientData.email_addresses?.length > 0 && (
                    <div className="info-line mb-2">
                      <label className="form-label">Email:</label>
                      <span>{clientData.email_addresses[0].address}</span>
                    </div>
                  )}
                </div>

                <div className='app-section-header mb-2'>
                  <label>Mark:</label>
                </div>

                <div className='info-section mb-3'>
                  {renderFieldsBasedOnMarkType()}
                </div>

                <div className='app-section-header mb-2'>
                  <label>Goods/Services:</label>
                </div>

                <div className='info-section mb-3'>
                  {classBlocks.map(renderClassBlock)}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center mt-5">Please retrieve the matter and client info.</div>
          )}
          {/* </div> */}
          {enlargedImage && (
            <div className="enlarged-specimen" onClick={handleCloseEnlargedImage}>
              <span className="close-enlarged-specimen">&times;</span>
              <img className="enlarged-specimen-content img-fluid" src={enlargedImage} alt="Enlarged specimen" />
            </div>
          )}
          {sigPage && (
            <Modal show={sigPage} onHide={() => setSigPage(false)} centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Attorney Signature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <NewAppAttySig
                  user={user}
                  matter={matter}
                  markType={markType}
                  teasType={editableFields["TEAS Type"]}
                  clientData={clientData}
                  classBlocksCount={classBlocks.length}
                  onClose={() => setSigPage(false)}
                  setSigPage={setSigPage}
                  generateSignaturePDF={generateSignaturePDF}
                  isWorking={isWorking}
                  setIsWorking={setIsWorking}
                />
              </Modal.Body>
            </Modal>
          )}
          {isDeny && (
            <DenyApplicationModal
              show={isDeny}
              setIsDeny={setIsDeny}
              handleClose={() => setIsDeny(false)}
              handleDeny={handleDeny}
            />
          )}
        </div>
      )}
    </div>
  )
}
export default MattersList;
