//ownerContact.js
import React, { useEffect, useState } from 'react';
import StateCombobox from './stateCB.js';
import CountryCombobox from './countryCB.js';
import OwnerSelection from './ownerSelection.js';
import CompanyType from './companyType.js';
import Owner from './Owner.png';

const OwnerContact = ({ formData, onFormDataChange, isTransferChecked, ownerType, onOwnerTypeChange, setFormData, onValidationChange, attemptedSubmit }) => {
    // const [selectedOwnerType, setSelectedOwnerType] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCompanyState, setSelectedCompanyState] = useState('');
    const [selectedCompanyCountry, setSelectedCompanyCountry] = useState('');
    const [isFormValid, setIsFormValid] = useState('false');
    const [fieldValidity, setFieldValidity] = useState({
        ownerFirstName: true,
        ownerLastName: true,
        ownerCompany: true,
        ownerAddress1: true,
        ownerCity: true,
        ownerZip: true,
        ownerState: true, // Only necessary if country === United States
        ownerCountry: true,
        ownerEmail: true,
    });

    useEffect(() => {
        // Validate form data whenever there's a change
        const isValid = validateForm();
        setIsFormValid(isValid);
        onValidationChange(isValid);
    }, [formData, selectedState, selectedCountry]);

    const validateForm = () => {
        const hasValidOwnerID = formData.ownerID && formData.contactID;
        const hasValidOwnerCompanyID = formData.ownerCompanyID && formData.contactCompanyID;

        const newFieldValidity = {
            ownerFirstName: formData.ownerCompany ? true : !!formData.ownerFirstName,
            ownerLastName: formData.ownerCompany ? true : !!formData.ownerLastName,
            ownerCompany: formData.ownerFirstName && formData.ownerLastName ? true : !!formData.ownerCompany,
            ownerAddress1: !!formData.ownerAddress1,
            ownerCity: !!formData.ownerCity,
            ownerCountry: !!formData.ownerCountry,
            ownerState: formData.ownerCountry === "United States" ? !!formData.ownerState : true,
            ownerEmail: !!formData.ownerEmail && emailRegex.test(formData.ownerEmail),
        };
    
        setFieldValidity(newFieldValidity);
    
        const isValid = Object.values(newFieldValidity).every(Boolean) || hasValidOwnerID || hasValidOwnerCompanyID;
        // console.log('ownerContact isValid:', isValid);
        setIsFormValid(isValid);
        onValidationChange(isValid);
        return isValid; // Return the validity status
    };
    
    
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    const sanitizeEmail = (email) => {
        return emailRegex.test(email) ? email : ''; // Return empty string or show an error if invalid
    }

    const sanitizeInput = (value, type = 'text') => {
        let sanitizedValue = value;
        switch(type) {
          case 'email':
            sanitizedValue = value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ? value : '';
            break;
          case 'text':
          default:
            sanitizedValue = escape(value.trim());
        }
        return sanitizedValue;
      }

    // useEffect(() => {
    //     // console.log('ownerType:',ownerType);
    //     if (ownerType === 'previous') {
    //         const newFormData = {
    //             ...formData,
    //             ownerFirstName: formData.contactFirstName,
    //             ownerMiddleName: formData.contactMiddleName,
    //             ownerLastName: formData.contactLastName,
    //             ownerCompany: formData.contactCompany,
    //             ownerAddress1: formData.contactAddress1,
    //             ownerAddress2: formData.contactAddress2,
    //             ownerCity: formData.contactCity,
    //             ownerState: formData.contactState,
    //             ownerZip: formData.contactZip,
    //             ownerCountry: formData.contactCountry,
    //             ownerEmail: formData.contactEmail,
    //             ownerID: formData.contactID,
    //             ownerCompanyID: formData.contactCompanyID
    //         };
    //         setFormData(newFormData);
    //     } else if (ownerType === 'individual' || ownerType === 'business') {
    //         resetFormData();
    //     }
    // }, [ownerType]);

    useEffect(() => {
        if (ownerType === 'previous') {
            setFormData((prev) => ({
                ...prev,
                ownerFirstName: prev.contactFirstName,
                ownerMiddleName: prev.contactMiddleName,
                ownerLastName: prev.contactLastName,
                ownerCompany: prev.contactCompany,
                ownerAddress1: prev.contactAddress1,
                ownerAddress2: prev.contactAddress2,
                ownerCity: prev.contactCity,
                ownerState: prev.contactState,
                ownerZip: prev.contactZip,
                ownerCountry: prev.contactCountry,
                ownerEmail: prev.contactEmail,
                ownerID: prev.contactID || prev.ownerID,   // ✅ Preserve previous ownerID if contactID is null
                ownerCompanyID: prev.contactCompanyID || prev.ownerCompanyID,  // ✅ Preserve previous companyID
            }));
        } else if (ownerType === 'individual' || ownerType === 'business') {
            resetFormData();
        }
    }, [ownerType]);
    

    // const resetFormData = () => {
    //     setFormData({
    //         ...formData,
    //         ownerFirstName: '',
    //         ownerMiddleName: '',
    //         ownerLastName: '',
    //         ownerCompany: '',
    //         ownerAddress1: '',
    //         ownerAddress2: '',
    //         ownerCity: '',
    //         ownerState: '',
    //         ownerZip: '',
    //         ownerCountry: '',
    //         ownerEmail: '',
    //         ownerID: '',
    //         ownerCompanyID: ''
    //     });
    // };

    const resetFormData = () => {
        setFormData((prev) => ({
            ...prev,
            ownerFirstName: '',
            ownerMiddleName: '',
            ownerLastName: '',
            ownerCompany: '',
            ownerAddress1: '',
            ownerAddress2: '',
            ownerCity: '',
            ownerState: '',
            ownerZip: '',
            ownerCountry: '',
            ownerEmail: '',
            ownerID: prev.ownerID,
            ownerCompanyID: prev.ownerCompanyID
        }));
    };
    
    
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        // let validatedValue = value.trim();
        let validatedValue;

        if (id === 'ownerEmail') {
            validatedValue = emailRegex.test(validatedValue) ? validatedValue : '';
        }
        onFormDataChange({
            ...formData,
            [id]: value,
        });
    };

    const handleSelectChange = (selectedOption, id) => {
        // if (id === 'ownerSelection') {
        //     onOwnerTypeChange(selectedOption.value);
        // } else {
        //     // Handle state and country combobox changes
        //     handleInputChange({ target: { id, value: selectedOption ? selectedOption.value : '' } });
        // }
        // Update based on selection from dropdowns
        onFormDataChange({
            ...formData,
            [id]: selectedOption ? selectedOption.value : '',
        });
    };

    const handleStateChange = (selectedOption) => {
        // Update both local state for re-rendering and formData for submission
        setSelectedState(selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            setSelectedCountry("United States"); // Directly using the full name
            onFormDataChange({
                ...formData,
                ownerState: selectedOption.value,
                ownerCountry: "United States" // Ensure formData uses the full name
            });
        } else {
            onFormDataChange({
                ...formData,
                ownerState: '',
                ownerCountry: '' // Reset country if no state is selected
            });
        }
    };

    const handleCountryChange = (selectedOption) => {
        const newCountry = selectedOption ? selectedOption.label : ''; // Use the full country name
        const newStateValue = selectedOption && selectedOption.value !== "USX" ? '' : formData.ownerState;
    
        // Update formData directly with the new value
        onFormDataChange({
            ...formData,
            ownerCountry: newCountry, // Use the full country name
            ownerState: newStateValue,
        });
    
        // Update local state if necessary
        setSelectedCountry(newCountry); // Update to use the full country name
        if (selectedOption && selectedOption.value !== "USX") {
            setSelectedState('');
        }
    };

    const handleCompanyStateChange = (selectedOption) => {
        // Update both local state for re-rendering and formData for submission
        setSelectedCompanyState(selectedOption ? selectedOption.value : '');
        if (selectedOption) {
            setSelectedCompanyCountry("United States"); // Directly using the full name
            onFormDataChange({
                ...formData,
                ownerCompanyStateIncorporation: selectedOption.value,
                ownerCompanyCountry: "United States" // Ensure formData uses the full name
            });
        } else {
            onFormDataChange({
                ...formData,
                ownerCompanyStateIncorporation: '',
                ownerCompanyCountry: '' // Reset country if no state is selected
            });
        }
    };

    const handleCompanyCountryChange = (selectedOption) => {
        const newCountry = selectedOption ? selectedOption.label : ''; // Use the full country name
        const newStateValue = selectedOption && selectedOption.value !== "USX" ? '' : formData.ownerCompanyState;
    
        // Update formData directly with the new value
        onFormDataChange({
            ...formData,
            ownerCompanyCountry: newCountry, // Use the full country name
            ownerCompanyStateIncorporation: newStateValue,
        });
    
        // Update local state if necessary
        setSelectedCompanyCountry(newCountry); // Update to use the full country name
        if (selectedOption && selectedOption.value !== "USX") {
            setSelectedCompanyState('');
        }
    };
    
    const handleCompanyTypeChange = (selectedOption) => {       
        onFormDataChange({
            ...formData,
            ownerCompanyType: selectedOption ? selectedOption.value : '',
        });
    };
    
    return (
        <div className='container sub-modal'>
            <div className='form-background-container'>
                <div className='col-12 form-content'>
                    {attemptedSubmit && !isFormValid && (
                        <div className='alert alert-danger'>
                            Please complete the required fields before proceeding:
                        </div>
                    )}
                    <div className='row'>
                        <div className='col-12 col-md-6 ownerSelection-container'>
                            <OwnerSelection
                                value={ownerType}
                                onChange={onOwnerTypeChange}
                                className='form-control ownerSelection'
                                id='ownerSelection'
                            />
                        </div>
                    </div>

                    {(ownerType === 'individual' || ownerType === 'business') && (
                        <form>
                            <div>
                                {(ownerType === 'individual' || (ownerType === 'previous' && !formData.contactCompany)) && (
                                    <div className="row contactName-container">
                                        <div className="col-sm-4 px-custom name-group">
                                            <label htmlFor='ownerFirstName'>First Name*</label>
                                            <input 
                                                type='text' 
                                                id='ownerFirstName' 
                                                className={`form-control ${attemptedSubmit && !fieldValidity.ownerFirstName ? 'is-invalid' : ''}`} 
                                                value={ownerType === 'previous' ? formData.contactFirstName : formData.ownerFirstName} 
                                                onChange={handleInputChange} 
                                                maxLength={50}
                                            />
                                        </div>
                                        <div className="col-sm-4 px-custom name-group">
                                            <label htmlFor='ownerMiddleName'>Middle Name</label>
                                            <input 
                                                type='text' 
                                                id='ownerMiddleName' 
                                                className='form-control' 
                                                value={ownerType === 'previous' ? formData.contactMiddleName : formData.ownerMiddleName} 
                                                onChange={handleInputChange} 
                                                maxLength={50}
                                            />
                                        </div>
                                        <div className="col-sm-4 px-custom name-group">
                                            <label htmlFor='ownerLastName'>Last Name*</label>
                                            <input 
                                                type='text' 
                                                id='ownerLastName' 
                                                className={`form-control ${attemptedSubmit && !fieldValidity.ownerLastName ? 'is-invalid' : ''}`} 
                                                value={ownerType === 'previous' ? formData.contactLastName : formData.ownerLastName} 
                                                onChange={handleInputChange} 
                                                maxLength={50}
                                            />
                                        </div>
                                    </div>
                                )}
                                {(ownerType === 'business' || (ownerType === 'previous' && formData.contactCompany)) && (
                                    <div>
                                        <div className="row">
                                            <div className="col-12 px-custom address-group">
                                                <label htmlFor='ownerCompany'>Owner Company</label>
                                                <input 
                                                    type='text' 
                                                    id='ownerCompany' 
                                                    className={`form-control ${attemptedSubmit && !fieldValidity.ownerCompany ? 'is-invalid' : ''}`} 
                                                    value={ownerType === 'previous' ? formData.contactCompany : formData.ownerCompany} 
                                                    onChange={handleInputChange} 
                                                    maxLength={65}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-lg-3 col-md-6 px-custom entity-group">
                                                <label htmlFor='ownerCompanyState'>State of Incorporation</label>
                                                <StateCombobox 
                                                    className='form-control' 
                                                    id='ownerCompanyState' 
                                                    value={formData.ownerCompanyStateIncorporation} 
                                                    onChange={handleCompanyStateChange} 
                                                />
                                            </div>
                                            <div className="col-12 col-lg-3 col-md-6 px-custom entity-group">
                                                <label htmlFor='ownerCompanyCountry'>Country of Incorporation*</label>
                                                <CountryCombobox 
                                                    className='form-control' 
                                                    id='ownerCompanyCountry' 
                                                    value={formData.ownerCompanyCountry} 
                                                    onChange={handleCompanyCountryChange} 
                                                />
                                            </div>
                                            <div className="col-12 col-md-4 px-custom entity-group">
                                                <label htmlFor='ownerCompanyType'>Entity Type*</label>
                                                <CompanyType 
                                                    className='form-control' 
                                                    id='ownerCompanyType' 
                                                    value={formData.ownerCompanyType} 
                                                    onChange={handleCompanyTypeChange} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md-6 px-custom address-group">
                                        <label htmlFor='ownerAddress1'>Address 1*</label>
                                        <input 
                                            type='text' 
                                            id='ownerAddress1' 
                                            className={`form-control ${attemptedSubmit && !fieldValidity.ownerAddress1 ? 'is-invalid' : ''}`} 
                                            value={ownerType === 'previous' ? formData.contactAddress1 : formData.ownerAddress1} 
                                            onChange={handleInputChange} 
                                            maxLength={65}
                                        />
                                    </div>
                                    <div className="col-md-6 px-custom address-group">
                                        <label htmlFor='ownerAddress2'>Address 2</label>
                                        <input 
                                            type='text' 
                                            id='ownerAddress2' 
                                            className='form-control' 
                                            value={ownerType === 'previous' ? formData.contactAddress2 : formData.ownerAddress2} 
                                            onChange={handleInputChange} 
                                            maxLength={65}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-3 px-custom city-group">
                                        <label htmlFor='ownerCity'>City*</label>
                                        <input 
                                            type='text' 
                                            id='ownerCity' 
                                            className={`form-control ${attemptedSubmit && !fieldValidity.ownerCity ? 'is-invalid' : ''}`} 
                                            value={ownerType === 'previous' ? formData.contactCity : formData.ownerCity} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                    <div className="col-3 px-custom city-group">
                                        <label htmlFor='ownerState'>State</label>
                                        <StateCombobox 
                                            className='form-control' 
                                            id='ownerState' 
                                            value={ownerType === 'previous' ? formData.contactState : formData.ownerState} 
                                            onChange={handleStateChange} 
                                            isValid={fieldValidity.ownerState} 
                                            attemptedSubmit={attemptedSubmit} 
                                        />
                                    </div>
                                    <div className="col-3 px-custom city-group">
                                        <label htmlFor='ownerZip'>Zip Code*</label>
                                        <input 
                                            type='text' 
                                            id='ownerZip' 
                                            className='form-control' 
                                            value={ownerType === 'previous' ? formData.contactZip : formData.ownerZip} 
                                            onChange={handleInputChange} 
                                            maxLength={15}
                                        />
                                    </div>
                                    <div className="col-3 px-custom city-group">
                                        <label htmlFor='ownerCountry'>Country*</label>
                                        <CountryCombobox 
                                            className='form-control' 
                                            id='ownerCountry' 
                                            value={ownerType === 'previous' ? formData.contactCountry : formData.ownerCountry} 
                                            onChange={handleCountryChange} 
                                            isValid={fieldValidity.ownerCountry} 
                                            attemptedSubmit={attemptedSubmit} 
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 px-custom address-group">
                                        <label htmlFor='ownerEmail'>Email Address*</label>
                                        <input 
                                            type='text' 
                                            id='ownerEmail' 
                                            className={`form-control ${attemptedSubmit && !fieldValidity.ownerEmail ? 'is-invalid' : ''}`} 
                                            value={ownerType === 'previous' ? formData.contactEmail : formData.ownerEmail} 
                                            onChange={handleInputChange} 
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OwnerContact;      