// termsOfService.js
import React, { useState, useEffect } from "react";
import './App.css';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
    return (
        <div>
            <main>
                <div className='privacy-policy'>
                    <h2>Terms of Service</h2>
                    <p>Welcome to <b>San Novus Trademark LLP</b>. These Terms of Service ("Terms") govern your use of our website, services, and any related content (collectively, the "Services").
                    By accessing or using our website, you agree to be bound by these Terms.
                    </p>
                    <ol>
                        <li>
                            <p><b>Acceptance of Terms</b></p>
                            <p>By using our website, you acknowledge that you have read, understood, and agree to these Terms. If you do not agree, please discontinue use of our website immediately.</p>
                        </li>
                        <li>
                            <p><b>Legal Services Disclaimer</b></p>
                            <ul>
                                <li><b>No Attorney-Client Relationship:</b> Use of this website does not establish an attorney-client relationship. An attorney-client relationship is only formed upon 
                                written agreement and payment of any required fees.</li>
                                <li><b>Information Only:</b> The content on this website is provided for informational purposes only and should not be considered legal advice. You should consult with 
                                an attorney before making legal decisions.</li>
                            </ul>
                        </li>
                        <li>
                            <p><b>Services Provided</b></p>
                            <p>San Novus Tradmemark LLP provides legal services related to <b>U.S. federal trademark prosecution and renewals</b>. Our services include, but are not limited to:</p>
                            <ul>
                                <li>Trademark application preparation and filing</li>
                                <li>Trademark prosecution before the United States Patent and Trademark Office (USPTO)</li>
                                <li>Office action responses</li>
                                <li>Trademark renewals and maintenance</li>
                            </ul>
                            <p>We reserve the right to modify, discontinue, or change our services at any time without prior notice.</p>
                        </li>
                        <li>
                            <p><b>User responsibilities</b></p>
                            <p>By using this website, you agree:
                                <ul>
                                    <li>Not to misuse our Services, including hacking, scraping, or interfering with site functionality.</li>
                                    <li>Not to submit false, misleading, or fraudulent information.</li>
                                    <li>To comply with all applicable laws and regulations.</li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <p><b>Payments and Refunds</b></p>
                            <ul>
                                <li>All payments for legal services must be made in full before work begins unless otherwise agreed in writing.</li>
                                <li>Payments are processed securely though <b>Stripe</b>.</li>
                                <li><b>Refunds:</b> Due to the nature of legal services, refunds are generally <b>not available</b> once work has begun. However, refunds may be granted 
                                on a case-by-case basis at the sole discretion of San Novus Trademark LLP.</li>
                            </ul>
                        </li>
                        <li>
                            <p><b>Privacy and Data Protection</b></p>
                            <p>Your use of this website is subject to our <a href='/privacy-policy' style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">Privacy Policy</a>, 
                            which details how we collect, use, and store your personal information.</p>
                        </li>
                        <li>
                            <p><b>Intellectual Property</b></p>
                            <ul>
                                <li>All content on this website, including text, logos, and design, is owned by San Novus Trademark LLP and is protected by copyright and trademark laws.</li>
                                <li>You may not copy, reproduce, or distribute any content without express permission.</li>
                            </ul>
                        </li>
                        <li>
                            <p><b>Third-Party Links</b></p>
                            <p>Our website may contain links to third-party websites including, but not limited to, the website for the United States Patent and Trademark Office. We are not
                                responsible for content, privacy polices, or practices of these third-party sites.
                            </p>
                        </li>
                        <li>
                            <p><b>Limitation of Liability</b></p>
                            <p>To the fullest extent permitted by law, San Novus Trademark LLP is not liable for:</p>
                            <ul>
                                <li>Any direct, indirect, or incidental damages resulting from website use.</li>
                                <li>Errors, omissions, or inaccuracies in website content.</li>
                                <li>Unauthorized access to or use of personal information stored by third-party services.</li>
                            </ul>
                        </li>
                        <li>
                            <p><b>Governing Law</b></p>
                            <p>These Terms shall be governed and interpreted in accordance with the laws of the <b>State of Arizona</b> without regard to conflict of law principles.</p>
                        </li>
                        <li>
                            <p><b>Changes to These Terms</b></p>
                            <p>We reserve the right to modify these Terms at any time. Any updates will be posed and are effective as of the date they are posed. Continued use of our website 
                                constitutes acceptance of any changes.</p>
                        </li>
                        <li>
                            <p><b>Contact Us</b></p>
                            <p>If you have any questions about these Terms, please contact us:</p>
                            <ul>
                                <li><b>Email:</b> <a href='mailto:info@sannovustrademark.com'>info@sannovustrademark.com</a></li>
                                <li><b>Phone:</b> (866) 885-3893</li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </main>
        </div>
    );
};

export default TermsOfService;