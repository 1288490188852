import React, { useState, useEffect, useRef } from 'react';
import logo from './FullLogo6.png';
import { pricingData } from './pricingData.js';

const EngagementLetter = ({ formData, onClose, showEngagementLetter, onAgree, setEngagementContent, setSignatureText, setSignatureTime }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [signature, setSignature] = useState('');

  const checkBoxRef = useRef(null);
  const signatureRef = useRef(null);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(!isChecked);

    // Set the signature to user's name if checked, otherwise clear it
    if (checked) {
      setSignature(`${formData.contactFirstName} ${formData.contactLastName}`);
    } else {
      setSignature('');
    }
    setSignatureTime(new Date().toLocaleString());
  };

  const handleSignatureChange = (event) => {
    setSignature(event.target.value);
  };

  const handleSubmit = () => {
    if (!isChecked) {
      alert("Please acknowledge that you have read and understand the letter.");

      //Scroll to checkbox and add red border
      checkBoxRef.current.scrollIntoView({behavior: "smooth", block: "center"});
      checkBoxRef.current.style.outline = "2px solid red";
      checkBoxRef.current.style.padding = "10px";

      setTimeout(() => {
        checkBoxRef.current.style.outline = ""; 
        checkBoxRef.current.style.padding = "";
      }, 3000); //remove the red outline after 3 seconds
      return;
    }

    if (!signature) {
      alert("Please sign the letter.");

      //Scroll to checkbox and add red border
      signatureRef.current.scrollIntoView({behavior: "smooth", block: "center"});
      signatureRef.current.style.outline = "2px solid red";
      signatureRef.current.style.padding = "10px";

      setTimeout(() => {
        signatureRef.current.style.outline = ""; 
        signatureRef.current.style.padding = "";
      }, 3000); //remove the red outline after 3 seconds
      return;
    }
    captureContent();
    onAgree();
    //Generate PDF
    // generateAndDownloadPDF();
  };

  // //Function to caclute total estimated filing fee based on number of classes, and attorney fee based on selected search option
  // const calculateTotalFee = () => {
  //   let attyFee = parseFloat(formData.attyFee.replace('$', '')) || 0;
  //   let filingFeePerClass = 0;

  //   // Find the correct service fee based on the attorney fee amount
  //   const selectedService = pricingData.find(category => category.category === "Trademark Applications")?.services
  //     .find(service => formData.attyFee.includes("375") ? service.service.includes("Knockout Search") : service.service.includes("without search"));

  //   if (selectedService) {
  //     // Extract the numerical value from the government fee string (e.g., "$350 (per class)")
  //     filingFeePerClass = parseFloat(selectedService.govtFee.replace(/[^0-9.]/g, '')) || 0;
  //   }

  //   // Calculate the total filing fee including surcharges
  //   let totalFilingFee = formData.classBlocks.reduce((acc, block) => {
  //     let surcharge = parseFloat(block.surcharge) || 0;
  //     return acc + filingFeePerClass + surcharge;
  //   }, 0);

  //   // Total fee includes attorney fee + calculated filing fees
  //   let totalFee = attyFee + totalFilingFee;

  //   return {
  //     totalFee: totalFee.toFixed(2),
  //     totalFilingFee: totalFilingFee.toFixed(2),
  //     filingFeePerClass: filingFeePerClass.toFixed(2)
  //   };
  // };

  // Revised function to caclute total estimated filing fee based on number of classes, and attorney fee based on selected search option
    // This function will apply a surcharge to ALL class blocks if there is a surcharge applicable to ANY of the class blocks, per filing experience on 02/24/2025
    const calculateTotalFee = () => {
      let attyFee = parseFloat(formData.attyFee?.replace('$', '')) || 0;
      let filingFeePerClass = 0;
  
      // Find the correct service fee based on the attorney fee amount
      const attyFeeString = String(formData.attyFee || '');
      const selectedService = pricingData.find(category => category.category === "Trademark Applications")?.services
        .find(service => attyFeeString.includes("375") ? service.service.includes("Knockout Search") : service.service.includes("without search"));
  
      if (selectedService) {
        // Extract the numerical value from the government fee string (e.g., "$350 (per class)")
        filingFeePerClass = parseFloat(selectedService.govtFee.replace(/[^0-9.]/g, '')) || 0;
      }
  
      // Determine if a surcharge applies (if any class has a surcharge)
      const surchargeApplies = formData.classBlocks.some(block => parseFloat(block.surcharge) > 0);
      const surchargeAmount = surchargeApplies ? formData.classBlocks.reduce((max, block) => Math.max(max, parseFloat(block.surcharge) || 0), 0) : 0;
  
      // Apply the surcharge to ALL classes if applicable
      let totalFilingFee = formData.classBlocks.reduce((acc, block) => {
        return acc + filingFeePerClass + (surchargeApplies ? surchargeAmount : 0);
      }, 0);
  
      // Total fee includes attorney fee + calculated filing fees
      let totalFee = attyFee + totalFilingFee;
  
      return {
        totalFee: totalFee.toFixed(2),
        totalFilingFee: totalFilingFee.toFixed(2),
        filingFeePerClass: filingFeePerClass.toFixed(2),
        surchargeAmount: surchargeAmount.toFixed(2),
        surchargeApplies: surchargeApplies
      };
    };

  const { totalFee, totalFilingFee, filingFeePerClass } = calculateTotalFee();

  const captureContent = () => {
    const element = document.getElementById('engagement-letter-body');
    if (!element) return;

    setEngagementContent(element.outerHTML);
    setSignatureText(document.getElementById('signatureText').value);
  };

  const formatDescriptions = (descriptions) => {
    return descriptions.flatMap(desc => {
      // Initialize an array to hold the formatted strings
      let formattedArray = [];

      // Iterate over the core array and corresponding additional inputs together
      desc.core.forEach((coreItem, index) => {
        formattedArray.push(coreItem); // Add core description
        // Check if there's a corresponding additional input and add it
        if (desc.additionalInputs[index]) {
          formattedArray.push(desc.additionalInputs[index]);
        }
      });

      return formattedArray;
    }).join(' ');
  };

  useEffect(() => {
    if (showEngagementLetter) { // Assuming showEngagementLetter controls the visibility
      window.scrollTo(0, 0);
    }
  }, [showEngagementLetter]);

  const formatCurrency = (amount) => {
    const num = parseFloat(amount);
    if (isNaN(num)) return "$0.00";
    return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });
};

  return (
    <div className='engagement-letter'>
      <div className='engagement-letter-container' id='engagement-letter-container'>
        <div className='engagement-letter-header'>
          <img className='engagement-letter-logo' src={logo} alt='San Novus Trademark Logo' />
          <div className='engagement-letter-header-contact'>
            <span>info@sannovustrademark.com</span>
            <span>www.sannovustrademark.com</span>
          </div>
        </div>
        <div className='engagement-letter-body' id='engagement-letter-body'>
          <h2>Re: Letter of Engagement for Services to be Rendered</h2>
          <p>Dear {formData.contactFirstName},</p>
          <p>We very much appreciate the opportunity to represent you with respect to your trademark. This letter sets forth the terms of such engagement.
            Our objective is to obtain the results you desire in the most cost-effective manner possible. Our fees are estimated as follows:</p>
          <div className='engagement-description'>
            {/* <p><b>Preparation and Filing of a U.S. Trademark Application for the {formData.wordMark || formData.markLiteralElement || 'stylized design'} trademark: </b>
              Estimated fees of <b style={{color: 'red'}}>${calculateTotalFee()}</b>, which includes our attorney fee of ${(formData.ownerCompanyCountry === 'China' || formData.ownerCountry === 'China') ? '200' : '375'} and
              the government filing fee of ${formData.teasPlus ? 250 * formData.classBlocks.length : 350 * formData.classBlocks.length} (${formData.teasPlus ? '250' : '350'} per class). 
              The trademark application will be filed into the following {formData.classBlocks.length === 1 ? 'class' : 'classes'}:
            </p> */}
            <p><b>Preparation and Filing of a U.S. Trademark Application for the {formData.wordMark || formData.markLiteralElement || 'stylized design'} trademark: </b>
                  Estimated fees of <b style={{ color: 'red' }}>{formatCurrency(totalFee)}</b>, which includes our attorney fee of {formatCurrency(formData.attyFee)} and
                  the government filing fee of {formatCurrency(totalFilingFee)} ({formatCurrency(filingFeePerClass)} per class).
                  The trademark application will be filed into the following {formData.classBlocks.length === 1 ? 'class' : 'classes'}:
            </p>
            <ol>
              {formData.classBlocks.map((block) => (
                <div key={block.id}>
                  {block.descriptions.map((description) => (
                    <li key={description.id}><b>International Class {block.classNo}: </b>
                      {/* {description.core}
                        {description.additionalInputs && Object.values(description.additionalInputs).map((input, index) => (
                          <span key={index}> {input}</span>
                        ))} */}
                      {formatDescriptions(block.descriptions)}
                    </li>
                  ))}
                </div>
              ))}
            </ol>
          </div>
          {/* <p>An advance on fees in the amount of ${calculateTotalFee()} is required to begin work. Trust deposits can be made via e-check or with a debit/credit card online.
            This advance on fees will be held by us in a trust account and applied as costs are incurred and services rendered. Firm policy is not to complete
            filings without receipt of the fully quoted amount. This allows us to complete your filing as quickly as possible.</p> */}
            <p>An advance on fees in the amount of <b>{formatCurrency(totalFee)}</b> is required to begin work. You may submit trust deposits via e-check or with a debit/credit card online.
            This advance on fees will be held by us in a trust account and applied as costs are incurred and services rendered. Firm policy is not to complete
            filings without receipt of the fully quoted amount.</p>
          <p>Currently, our services for this engagement are provided on a flat rate basis. You will be responsible for our fee of {formatCurrency(formData.attyFee)} plus
            any applicable government filing fees. Based on the information you provided, the total government filing fee is estimated to be {formatCurrency(totalFilingFee)}.
            This amount is subject to adjustment if additional filing classes, or unforeseen work, arise based on future intructions from you.</p>
          <p>We will perform our professional services on your behalf to the best of our ability, though we cannot and have not made any guarantees regarding
            the outcome of your trademark application(s). Any discussions we have with you in this regard reflect our best professional evaluations only
            and are limited by our knowledge at the time they are made.</p>
          <p>Our firm maintains adheres to strict confidentiality standards and professional integrity. We will keep confidential all communications and information regarding
            your intellectual property. There may be times when you wish for us to communicate with outside parties regarding your files, but we will not do
            so without your specific authorization.</p>
          <p>Our firm has adopted a "paperless" operating strategy. We primarily utilize electronic communications and maintain all client files electronically,
            including the use of cloud-based electronic storage. We maintain reasonable safeguards for the purpose of protecting all client data and information.</p>
          <p>All original papers, records, documents, or other items you provide to us (if any) will be returned to you, with our office retaining a digital
            copy for your file(s). We reserve the right to securely dispose of any file or document we have maintained at our office pertaining to your matter(s) after a period
            of five (5) years after the engagement of our services has concluded, or as required by law, without any additional notice to you. All work papers, research, and work product
            produced by our firm, other than your original documents, remain the confidential property of San Novus Trademark LLP.</p>
          <p><b>Filing a tradeamrk application does not mean that you have a federally registered trademark. What you are filing is an application for your mark to be federally registered.
            Your trademark application will be thoroughly examined by a an examining attorney at the United States Patent and Trademark Office. Successful registration of trademarks is not 
            guaranteed. Based on our experience with the U.S. Trademark Office we anticipate that additional work will likely be needed. Specifically, preparing and filing at least one formal 
            response to a rejection. Such future work that is performed will require additional fees.</b></p>
          <p>Upon successful registration of a trademark, renewals will be required to be submitted to the U.S. Patent and Trademark Office in order to
            maintain the trademark. The first renewal will be due between the fifth and sixth year anniversary of the registration date of the trademark. The
            next renewal will be due between the ninth and tenth year anniversary of the registration date of the trademark. Subsequent renewals are then
            required every 10 years thereafter. Trademarks may be maintained indefinitely provided that each required renewal is timely filed.
          </p>
          <p>You will have the right to terminate our relationship at any time. Similarly, we also have the right to terminate our engagement at any time,
            subject to our providing you with reasonable notice so that you can arrange alternative representation.</p>
          <p>Please be on notice that you have not hired us as your attorney until the following conditions have been met:</p>
          <ol>
            <li>Both parties have agreed to the terms written herein (your signing of this letter indicating your agreement);</li>
            <li>An advance on fees has been offered and accepted; and</li>
            <li>The firm has performed a conflict check.</li>
          </ol>
          <p>If you wish to retain our services, an advance on fees is required as indicated above. If there is any disagreement concerning
            the fee charged hereunder, the parties agree to submit that disagreement to binding arbitration with the Fee Arbitration Committee
            of the State Bar of Arizona. The parties agree that the laws of the State of Arizona will be applied in the event of any fee
            dispute.</p>
          <p>By executing this Agreement, you acknowledge having read and understood the terms thereof, the enclosed disclosure material
            and having received a copy of the executed contract.</p>
        </div>
        <div className='engagement-signature-container'>
          <div className='acknowledgement' ref={checkBoxRef}>
            <input
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label>I acknowledge that I have read and understand the engagement letter</label>
          </div>
          <div className='signature' ref={signatureRef}>
            <span>/s/</span>
            <input
              type='text'
              value={signature}
              onChange={handleSignatureChange}
              placeholder='Type your name'
              id='signatureText'
            />
          </div>
        </div>
        <div className='close-engagement'>
          <button onClick={handleSubmit} className='close-submit-button'>I Agree</button>
          <button onClick={onClose} className='close-submit-button'>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EngagementLetter;
