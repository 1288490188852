import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import ReactDOM from "react-dom";
import DescriptionInput from "./descriptionInput.js";

const SearchModal = ({ 
  searchTerm, 
  showModal, 
  setShowModal, 
  onAddClassDescription, 
  searchResults, 
  performSearch, 
  onSaveDescriptionInput, 
  handleOpenDescriptionInput,
  descriptionInputContext,
  handleDescriptionInputContextChange,
  loading,
}) => {
    console.log('searchModal log:', showModal);
    const [localSearchTerm, setLocalSearchTerm] = useState('');
    const [loadingResults, setLoadingResults] = useState(false);
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedDescriptions, setSelectedDescriptions] = useState({});
    const [isDescriptionInputOpen, setIsDescriptionInputOpen] = useState(false);
    const [currentDescription, setCurrentDescription] = useState('');
    const [currentClassId, setCurrentClassId] = useState('');
    const [descriptionContext, setDescriptionContext] = useState({
      description: '',
      classId: null,
    });
    
  function highlightSearchTerm(text, searchTerm) {
    const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <strong key={index} className="highlighted-search-term">{part}</strong>
      ) : part
    );
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault(); // Prevent form from submitting
    setLoadingResults(true);
    e.stopPropagation();

    if (localSearchTerm.trim() === '') return;
  
    // Your search logic here
    await performSearch(localSearchTerm);
    setLoadingResults(false);
  };

  const handleSearchKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default action to avoid submitting the form (if any)
        setLoadingResults(true);
        await performSearch(localSearchTerm); // Trigger the search function
        setLoadingResults(false);
        // Consider closing the modal or any other actions after search
    }
  };

  const handleAddDescription = (class_id, description_tx, uniqueKey) => {

    // //This block will add description to MarkClasses and replace areas with required input with input boxes
    // onAddClassDescription(class_id, description_tx);
    // setSelectedDescriptions(prevSelected => ({
    //   ...prevSelected,
    //   [uniqueKey]: true // Mark as selected
    // }));
    // //End block

    if(description_tx.includes("{")) {
      setSelectedDescriptions(prevSelected => ({
        ...prevSelected,
        [uniqueKey]: true
      }));
      onAddClassDescription();
      setDescriptionContext({description: description_tx, classId: class_id, uniqueKey: uniqueKey });
      setIsDescriptionInputOpen(true);
    } else {
      onAddClassDescription(class_id, description_tx);
      setSelectedDescriptions(prevSelected => ({
        ...prevSelected,
        [uniqueKey]: true
      }));
    }
  };

  const handleSaveDescriptionInput = (classId, updatedDescription, inputs) => {
    onAddClassDescription(classId, updatedDescription, inputs);
  };
  
  useEffect(() => {
    // This ensures that the searchTerm prop is respected if it changes from the outside
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

    return showModal ? ReactDOM.createPortal(
      (
      // <Draggable>
        <div className={`test-search-modal ${showModal ? 'show' : ''}`} /* onClick={() => setShowModal(false)} */>
          <div className="search-modal-content">
            <button onClick={() => setShowModal(false)} className='search-button'>Search Complete</button>
            <h2>Goods/Services Search:</h2>
            <form onSubmit={(e) => e.preventDefault()} className='search-form'>
              <label htmlFor="searchQuery">Search Term(s):</label>
              <input
                type="text"
                id="searchQuery"
                className='search-box'
                value={localSearchTerm}
                onChange={(e) => setLocalSearchTerm(e.target.value)}
                onKeyDown={(e) => handleSearchKeyPress(e)}
                onKeyUp={handleSearchKeyPress}
              />
              <button type="submit" className='search-button' onClick={handleSearchSubmit}>Search</button>
            </form>
            {(loading || loadingResults) && <div>Retrieving search results...</div> }
            {(!loading && searchResults.length === 0) && <div className="no-results">No results found. Please search for another term.</div> }
            {!loading && searchResults.length > 0 && (
                <div className='scrollable-table-container'>
                    <table className='search-results-table'>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Class No.</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.map((result, index) => {
                              const uniqueKey = `${result.class_id}-${result.id}`; // Assuming result.id is unique
                              return (
                                  <tr key={uniqueKey}>
                                      <td>
                                          {selectedDescriptions[uniqueKey] ? (
                                              // Render checkmark or any indication of selection
                                              <span style={{ color: 'black' }}>✔</span>
                                          ) : (
                                              // Render the "Add" button if not yet selected
                                              <button onClick={() => handleAddDescription(result.class_id, result.description_tx, uniqueKey)} className='add-button'>
                                                  Add
                                              </button>
                                          )}
                                      </td>
                                      <td>{result.class_id}</td>
                                      <td>{highlightSearchTerm(result.description_tx, searchTerm)}</td>
                                  </tr>
                              );
                          })}
                        </tbody>
                    </table>
                </div>
            )}
            <button onClick={() => setShowModal(false)} className='search-button'>Search Complete</button>
          </div>
          {
            isDescriptionInputOpen && (
              <DescriptionInput
                isOpen={isDescriptionInputOpen}
                // description={currentDescription}
                onClose={() => setIsDescriptionInputOpen(false)}
                onSaveDescriptionInput={onSaveDescriptionInput}
                description={descriptionContext.description}
                classId={descriptionContext.classId}
                handleSaveDescriptionInput={handleSaveDescriptionInput}
              />
            )
          }
        </div>
      // </Draggable>
      ),
       document.body
    ) : null;
  };
  
  export default SearchModal;
