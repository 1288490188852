import React, { useEffect, useState } from 'react';
import appType from './AppType_2.png';

const AppType = ({ onUpdate, formData, onFormDataChange, onValidationChange, attemptedSubmit }) => {
    const [selectedType, setSelectedType] = useState('select');
    const [noColorClaim, setNoColorClaim] = useState(true);
    const [selectedDesignFile, setSelectedDesignFile] = useState(null);
    const [selectedSoundFile, setSelectedSoundFile] = useState(null);
    const [soundPreviewUrl, setSoundPreviewUrl] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [isFormValid, setIsFormValid] = useState('false');
    const [fieldValidity, setFieldValidity] = useState({
        appType: true,
        wordMark: true,
        designFile: true,
        soundDescription: true,
        soundFile: true,
    });

    const MAX_FILE_SIZE = 10 * 1024 * 1024;

    useEffect(() => {
        // Validate form data whenever there's a change
        const isValid = validateForm();
        setIsFormValid(isValid);
        onValidationChange(isValid);
    }, [formData, selectedType, selectedDesignFile, selectedSoundFile]);

    const validateForm = () => {
        let isValid = false;

        const hasSelectedAppType = ['word', 'design', 'sound'].includes(selectedType);
        const hasEnteredWordMark = selectedType === 'word' && formData.wordMark;
        const hasUploadedDesignFile = selectedType === 'design' && formData.designMarkFile;
        const hasEnteredSoundDescription = selectedType === 'sound' && formData.soundMarkDescription;
        const hasUploadedSoundFile = selectedType === 'sound' && formData.soundMarkFile;

        // Check for 'word' type validation
        if (selectedType === 'word') {
            isValid = hasEnteredWordMark;
        }
        // Check for 'design' type validation
        else if (selectedType === 'design') {
            isValid = hasUploadedDesignFile;
        }
        // Check for 'sound' type validation
        else if (selectedType === 'sound') {
            isValid = hasEnteredSoundDescription && hasUploadedSoundFile;
        }

        // Update the field validity states
        setFieldValidity({
            appType: hasSelectedAppType,
            wordMark: hasEnteredWordMark,
            designFile: hasUploadedDesignFile,
            soundDescription: hasEnteredSoundDescription,
            soundFile: hasUploadedSoundFile
        });

        // Update the form validity state
        setIsFormValid(isValid);
        onValidationChange(isValid);
        return isValid; // Return the validity status
    };

    const sanitizeInput = (value, type = 'text') => {
        let sanitizedValue = value;
        switch (type) {
            case 'email':
                sanitizedValue = value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ? value : '';
                break;
            case 'text':
            default:
                sanitizedValue = escape(value.trim());
        }
        return sanitizedValue;
    }

    useEffect(() => {
        if (selectedSoundFile) {
            const url = URL.createObjectURL(selectedSoundFile);
            setSoundPreviewUrl(url);

            //Cleanup: Revoke the object URL when it's no longer needed
            return () => URL.revokeObjectURL(url);
        }
    }, [selectedSoundFile]);

    const handleFileSelectClick = (event) => {
        event.stopPropagation(); // Prevent click event from propagating
        document.getElementById('markDesignFile').click(); // Open file dialog
    };


    const handleDesignFileChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.target.files[0];

        if (!file) return; //No file selected

        // Ensure file is an image or a PDF
        const validImageTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
        const isValidFileType = validImageTypes.includes(file.type) || file.type === "application/pdf";

        if (!isValidFileType) {
            alert("Unsupported file type. Please select an image or a PDF.");
            event.target.value = ""; // Clear input
            return;
        }

        // Check file size
        if (file.size > MAX_FILE_SIZE) {
            alert("File size exceeds 10MB. Please select a smaller file.");
            event.target.value = ""; // Clear input
            return;
        }

        // Get file extension safely
        const fileExtension = file.name.split('.').pop() || 'unknown';

        // Rename file to "Logo.<extension>"
        const newFile = new File([file], `Logo.${fileExtension}`, { type: file.type });

        // Initialize FileReader to read the uploaded file
        const reader = new FileReader();

        reader.onloadend = () => {
            setImagePreviewUrl(reader.result); // Set image preview
            console.log(newFile);
            setSelectedDesignFile(newFile);
            onUpdate({ designMarkFile: newFile });
        };

        // Read the file as a Data URL (base64)
        reader.readAsDataURL(newFile);
    };

const handleSoundFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.match('audio/mpeg') || file.type.match('audio/wav') || file.type.match('audio/ogg')) {
        // Initialize FileReader to read the uploaded file
        const reader = new FileReader();

        // Define what happens on file load
        reader.onloadend = () => {
            // Update the state with the image URL for preview
            setImagePreviewUrl(reader.result);
            console.log(file);
            setSelectedSoundFile(file);
            onUpdate({ soundMarkFile: file });
        };

        // Read the file as a Data URL (base64)
        reader.readAsDataURL(file);
    } else {
        alert("You have tried to provide an unsupported file type. Please select an audio file.");
    }
};

const handleSelectChange = (event) => {
    const type = event.target.value;
    setSelectedType(type);
    setImagePreviewUrl('');

    // Reset fields based on the selected application type
    if (type === 'word') {
        // If 'word' is selected, reset fields not related to 'word' type
        onUpdate({
            applicationType: 'word',
            wordMark: formData.wordMark,
            markLiteralElement: '',
            markColorClaim: '',
            designMarkFile: '',
            soundMarkDescription: '',
            soundMarkFile: '',
        });
    } else if (type === 'design') {
        // If 'design' is selected, reset fields not related to 'design' type
        onUpdate({
            applicationType: 'design',
            wordMark: '',
            markLiteralElement: formData.markLiteralElement,
            markColorClaim: formData.markColorClaim,
            designMarkFile: formData.designMarkFile,
            soundMarkDescription: '',
            soundMarkFile: '',
        });
    } else if (type === 'sound') {
        // If 'sound' is selected, reset fields not related to 'sound' type
        onUpdate({
            applicationType: 'sound',
            wordMark: '',
            markLiteralElement: '',
            markColorClaim: '',
            designMarkFile: '',
            soundMarkDescription: formData.soundMarkDescription,
            soundMarkFile: formData.soundMarkFile,
        });
    } else {
        // Handle 'select' or any other initial state if necessary
        onUpdate({
            applicationType: '',
            wordMark: '',
            markLiteralElement: '',
            markColorClaim: '',
            designMarkFile: '',
            soundMarkDescription: '',
            soundMarkFile: '',
        });
    }
};


const handleInputChange = (event) => {
    const { id, value } = event.target;
    // value = sanitizeInput(value.trim());
    onFormDataChange({ [event.target.id]: event.target.value });
};

const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setNoColorClaim(isChecked); // Update local state

    // If the box is checked, set markColorClaim to 'none', otherwise set it to an empty string or any previous value
    onUpdate({
        markColorClaim: isChecked ? '' : formData.markColorClaim
    });
};


// return (
//     <div>
//         <div className='appType-container'>
//             {(attemptedSubmit && !isFormValid) && (
//                 <div className='form-invalid-descrip'>
//                     <span>Please provide information about your mark before proceeding:</span>
//                 </div>
//             )}
//             <div className='appType-input'>
//                 <label htmlFor='applicationType'>What kind of mark is it? </label>
//                 <select 
//                     id='applicationType' 
//                     // name='applicationtype' 
//                     onChange={handleSelectChange} 
//                     value={selectedType}
//                     className={`applicationType ${attemptedSubmit && !fieldValidity.appType ? 'invalid-select' : ''}`}
//                 >
//                     <option value='select'>Select Mark Type</option>
//                     <option value='word'>Word Mark</option>
//                     <option value='design'>Design Mark</option>
//                     <option value='sound'>Sound Mark</option>
//                 </select>
//             </div>

// {selectedType === 'word' && (
//     <div className='appType-container'>
//         <div className='appType-input'>
//             <label htmlFor='wordMark'>What is your mark?</label>
//             <input 
//                 type="text" 
//                 id='wordMark' 
//                 className={`wordMark ${attemptedSubmit && !fieldValidity.wordMark ? 'invalid-input' : ''}`}
//                 defaultValue={formData.wordMark}
//                 onBlur={handleInputChange}
//                 // className={`name-input ${attemptedSubmit && !fieldValidity.ownerLastName ? 'invalid-field' : ''}`}
//             />
//         </div>
//     </div>
// )}

// {selectedType === 'design' && (
//     <div>
//         <div className='appType-container'>
//             <div className='appType-input'>
//                 <label htmlFor='markLiteralElement'>What words or letters are part of your design?</label>
//                 <input 
//                     type="text" 
//                     id='markLiteralElement' 
//                     name='markLiteralElement'
//                     defaultValue={formData.markLiteralElement}
//                     onBlur={handleInputChange}
//                 />
//             </div>
//         </div>
//         <div className='appType-container'>
//             <div className='appType-input'>
//                 <label htmlFor='markColorClaim'>Which colors do you want to claim as part of your trademark?</label>
//                 <input 
//                     type="text" 
//                     id='markColorClaim' 
//                     name='markColorClaim' 
//                     defaultValue={formData.markColorClaim}
//                     onBlur={handleInputChange}
//                     disabled={noColorClaim}
//                 />
//                 <input 
//                     id='noColorClaim' 
//                     type='checkbox' 
//                     name='noColorClaim' 
//                     // checked={noColorClaim} 
//                     checked={noColorClaim}
//                     onChange={handleCheckboxChange} 
//                     style={{marginLeft:'25px'}} 
//                 />
//                 <label htmlFor='noColorClaim'>I'm not claiming any colors</label>
//             </div>
//         </div>
//         <div className='appType-container'>
//             <div className='appType-input'>
//                 <label htmlFor='markDesignFile'>Please provide a copy of your design:</label>
//                 <button 
//                     id='fileSelectButton' 
//                     name='fileSelectButton' 
//                     onClick={() => document.getElementById('markDesignFile').click()}
//                     className={`fileSelectButton ${attemptedSubmit && !fieldValidity.designFile ? 'invalid-button' : ''}`}
//                 >Upload File</button>
//                 <input 
//                     type="file" 
//                     id='markDesignFile' 
//                     accept='image/*, application/pdf'
//                     name='fileSelect' 
//                     onChange={handleDesignFileChange} 
//                     style={{display:'none'}}
//                 />
//             </div>
//             <div>
//                 {imagePreviewUrl && (
//                         <img 
//                             src={imagePreviewUrl} 
//                             alt='design logo preview'
//                             style={{width: '100px', height:'100px'}}
//                         />
//                     )}
//             </div>
//         </div>
//     </div>
// )}

//             {selectedType === 'sound' && (
//                 <div>
//                 <div className='appType-container'>
//                     <div className='appType-input'>
// <label htmlFor='markSound'>Please describe the sound of your mark</label>
// <input 
//     type="text" 
//     id='soundDescription' 
//     className={`soundDescription ${attemptedSubmit && !fieldValidity.soundDescription ? 'invalid-input' : ''}`}  
//     style={{marginLeft:'25px'}}
//     defaultValue={formData.soundMarkDescription}
//     onBlur={handleInputChange}  
// />
//                     </div>
//                 </div>
// <div className='appType-container'>
//     <div className='appType-input'>
//         <label htmlFor='markSoundFile'>Please provide a copy of your sound mark:</label>
//         <button 
//             id='fileSelectButton' 
//             name='fileSelectButton' 
//             onClick={() => document.getElementById('markSoundFile').click()}
//             className={`fileSelectButton ${attemptedSubmit && !fieldValidity.soundFile ? 'invalid-button' : ''}`}
//         >Upload File</button>
//         <input 
//             type="file" 
//             id='markSoundFile' 
//             name='fileSelect' 
//             onChange={handleSoundFileChange} style={{display:'none'}}
//             accept='audio/mpeg, audio/wav, audio/ogg'
//         />
//     </div>
//     <div>
//         {soundPreviewUrl && (
//             <audio controls src={soundPreviewUrl} alt='sound mark preview'>Your browser does not support the audio element</audio>
//         )}
//     </div>
// </div>

//             </div>
//             )}
//         </div>
//         <div >
//             <img src={appType} alt='Application Type Form Decorative Image' className='appType-form-image' />
//         </div>
//     </div>
// );

return (
    <div className="container sub-modal">
        <div className="form-background-container">
            <div className="col-12 form-content">
                {attemptedSubmit && !isFormValid && (
                    <div className="alert alert-warning" role="alert">
                        Please provide information about your mark before proceeding.
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="applicationType">What kind of mark is it?</label>
                    <select
                        id="applicationType"
                        className={`form-control appTypeSelect ${attemptedSubmit && !fieldValidity.appType ? 'is-invalid' : ''}`}
                        onChange={handleSelectChange}
                        value={selectedType}
                    >
                        <option value="select">Select Mark Type</option>
                        <option value="word">Word Mark</option>
                        <option value="design">Design Mark</option>
                        <option value="sound">Sound Mark</option>
                    </select>
                </div>
                <form className="form-group">
                    {selectedType === 'word' && (
                        <div className='row appType-container'>
                            <label htmlFor='wordMark'>What is your mark?*</label>
                            <div className='col-12 appType-input'>
                                <input
                                    type="text"
                                    id='wordMark'
                                    className={`form-control wordmark ${attemptedSubmit && !fieldValidity.wordMark ? 'invalid-input' : ''}`}
                                    defaultValue={formData.wordMark}
                                    onBlur={handleInputChange}
                                    maxLength={150}
                                />
                            </div>
                        </div>
                    )}
                    {selectedType === 'design' && (
                        <div>
                            <div className='row appType-container'>
                                <label htmlFor='markLiteralElement' className='col-12'>What words or letters are part of your design?</label>
                                <div className=' col-12 appType-input'>
                                    <input
                                        type="text"
                                        id='markLiteralElement'
                                        // name='markLiteralElement'
                                        className={`form-control markLiteralElement ${attemptedSubmit && !fieldValidity.wordMark ? 'invalid-input' : ''}`}
                                        defaultValue={formData.markLiteralElement}
                                        onBlur={handleInputChange}
                                        maxLength={75}
                                    />
                                </div>
                            </div>
                            <div className='row appType-container'>
                                <label htmlFor='markColorClaim' className='col-12'>Which colors do you want to claim as part of your trademark?</label>
                                <div className='col-12 appType-input'>
                                    <input
                                        type="text"
                                        id='markColorClaim'
                                        className={`form-control markColorClaim ${attemptedSubmit && !fieldValidity.wordMark ? 'invalid-input' : ''}`}
                                        defaultValue={formData.markColorClaim}
                                        onBlur={handleInputChange}
                                        disabled={noColorClaim}
                                        maxLength={50}
                                    />
                                </div>
                                <div className='col-12 appType-input'>
                                    <input
                                        id='noColorClaim'
                                        type='checkbox'
                                        name='noColorClaim'
                                        checked={noColorClaim}
                                        onChange={handleCheckboxChange}
                                        style={{ marginTop: '0.1rem', marginBottom: '0.5rem' }}
                                    />
                                    <label htmlFor='noColorClaim' className='colorClaimCBLabel'>No specific colors</label>
                                </div>
                            </div>
                            <div className='appType-container'>
                                <div className='appType-input'>
                                    <label htmlFor='markDesignFile'>Please provide a copy of your design:*</label>
                                    <button
                                        type='button'
                                        id='fileSelectButton'
                                        name='fileSelectButton'
                                        onClick={handleFileSelectClick}
                                        className={`fileSelectButton ${attemptedSubmit && !fieldValidity.designFile ? 'invalid-button' : ''}`}
                                    >Upload File</button>
                                    <input
                                        type="file"
                                        id='markDesignFile'
                                        accept='image/*, application/pdf'
                                        name='fileSelect'
                                        onChange={handleDesignFileChange}
                                        style={{ display: 'none' }}
                                        onClick={e => e.stopPropagation()}
                                    />
                                    <span className='file-upload-size-limit'>10MB size limit</span>
                                </div>
                                <div>
                                    {imagePreviewUrl && (
                                        <img
                                            src={imagePreviewUrl}
                                            alt='design logo preview'
                                            style={{ width: '100px', height: '100px' }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {selectedType === 'sound' && (
                        <div>
                            <div className='row appType-container'>
                                <label htmlFor='markSound' className='col-12'>Please describe the sound of your mark*</label>
                                <div className='col-12 appType-input'>
                                    <input
                                        type="text"
                                        id='soundDescription'
                                        className={`form-control soundDescription ${attemptedSubmit && !fieldValidity.soundDescription ? 'invalid-input' : ''}`}
                                        defaultValue={formData.soundMarkDescription}
                                        onBlur={handleInputChange}
                                        maxLength={150}
                                    />
                                </div>
                            </div>
                            <div className='row appType-container'>
                                <div className='col-12 appType-input'>
                                    <label htmlFor='markSoundFile'>Please provide a copy of your sound mark:*</label>
                                    <button
                                        type='button'
                                        id='fileSelectButton'
                                        name='fileSelectButton'
                                        onClick={() => document.getElementById('markSoundFile').click()}
                                        className={`fileSelectButton ${attemptedSubmit && !fieldValidity.designFile ? 'invalid-button' : ''}`}
                                    >Upload File</button>
                                    <input
                                        type="file"
                                        id='markSoundFile'
                                        name='fileSelect'
                                        onChange={handleSoundFileChange}
                                        style={{ display: 'none' }}
                                        accept='audio/mpeg, audio/wav, audio/ogg'
                                        onClick={e => e.stopPropagation()}
                                    />
                                </div>
                                <div>
                                    {soundPreviewUrl && (
                                        <audio controls src={soundPreviewUrl} alt='sound mark preview'>Your browser does not support the audio element</audio>
                                    )}
                                </div>
                            </div>




                            {/* <div className='row appType-container'>
                                    <label htmlFor='markColorClaim' className='col-12'>Which colors do you want to claim as part of your trademark?</label>
                                    <div className='col-12 appType-input'>
                                        <input 
                                            type="text" 
                                            id='markColorClaim'
                                            className={`form-control markColorClaim ${attemptedSubmit && !fieldValidity.wordMark ? 'invalid-input' : ''}`}
                                            defaultValue={formData.markColorClaim}
                                            onBlur={handleInputChange}
                                            disabled={noColorClaim}
                                        />
                                    </div>
                                    <div className='col-12 appType-input'>
                                        <input 
                                            id='noColorClaim' 
                                            type='checkbox'
                                            name='noColorClaim'
                                            checked={noColorClaim}
                                            onChange={handleCheckboxChange}
                                            style={{ marginTop: '0.1rem', marginBottom: '0.5rem' }}
                                        />
                                        <label htmlFor='noColorClaim' className='colorClaimCBLabel'>No specific colors</label>
                                    </div>
                                </div>
                                <div className='appType-container'>
                                    <div className='appType-input'>
                                        <label htmlFor='markDesignFile'>Please provide a copy of your design:</label>
                                        <button 
                                            type='button'
                                            id='fileSelectButton' 
                                            name='fileSelectButton' 
                                            onClick={handleFileSelectClick}
                                            className={`fileSelectButton ${attemptedSubmit && !fieldValidity.designFile ? 'invalid-button' : ''}`}
                                        >Upload File</button>
                                        <input 
                                            type="file" 
                                            id='markDesignFile' 
                                            accept='image/*, application/pdf'
                                            name='fileSelect' 
                                            onChange={handleDesignFileChange} 
                                            style={{display:'none'}}
                                            onClick={e => e.stopPropagation()}
                                        />
                                    </div>
                                    <div>
                                        {imagePreviewUrl && (
                                                <img 
                                                    src={imagePreviewUrl} 
                                                    alt='design logo preview'
                                                    style={{width: '100px', height:'100px'}}
                                                />
                                            )}
                                    </div>
                                </div> */}
                        </div>
                    )}
                </form>
            </div>

            {/* Additional conditional rendering blocks for 'word', 'design', and 'sound' types go here */}
            {/* Use 'col-12' for full width on small devices and 'col-md-6' or similar for larger devices as needed */}

        </div>
    </div>
);
};

export default AppType;

