// // FAQPage.js
// import React from 'react';
// import Header from './header.js';
// import FAQs from './FAQs.js';

// const FAQPage = ({ tag }) => {
//     const tagsToShow = ["general", "usage", "application", "oar", "international", "renewal", "search"];

//   return (
//     <div className='faq' style={{ fontFamily: 'Arial, sans-serif'}}>
//       {/* <Header /> */}
//       <div className='comp'>
//         <span className='comp-name'>Frequently Asked Questions</span>
//         <span className='comp-descrip'>You Have Questions, We Have Answers</span>
//       </div>
//     <main>
//       <div className='main-section'>
//           <FAQs tags={tagsToShow} />
//       </div>
//     </main>
//     </div>
//   );
// };

// export default FAQPage;

// FAQPage.js
import React, { useState } from 'react';
import FAQs from './FAQs.js';
import RegisterTrademarkForm from './registerTrademarkForm.js';

const FAQPage = ({ tag }) => {
  const tagsToShow = ["general", "usage", "application", "oar", "international", "renewal", "search", "amazon"];
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
    // setShowForm((prev) => !prev);
  };

  const closeModal = (e) => {
    if (e.target.className === 'modal') {
      setShowForm(false);
    }
  };

  return (
    <div className='faq'>
      <div className='container text-center mt-5 mb-3 comp'>
        <h1 className='display-4 comp-name'>Frequently Asked Questions</h1>
        <p className='lead comp-descrip'>You Have Questions, We Have Answers</p>
      </div>
      <button className="form-button" onClick={toggleForm}>Start Your Application Now</button>
      <main className='container'>
        <FAQs tags={tagsToShow} />
        <button className="form-button" onClick={toggleForm}>Start Your Application Now</button>
        <br />
        {showForm && (
          <div className="test-modal" onClick={closeModal}>
            <RegisterTrademarkForm toggleForm={toggleForm} />
          </div>
        )}
      </main>
    </div>
  );
};

export default FAQPage;

