import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import UseSelection from './useBox.js';
import CountryCombobox from './countryCB.js';
import { pricingData } from './pricingData.js';

const ClientPortalUsageInfo = ({ selectedFile, selectedDeadline, setResponseType, groupGoodsServices, onFormSubmit, setTotalFee, setEmailBody }) => {
    const [classBlocks, setClassBlocks] = useState([]);
    const [selectedUse, setSelectedUse] = useState({});
    const [expandedBlockId, setExpandedBlockId] = useState(null);
    const [filePreviews, setFilePreviews] = useState([]);
    const [formData, setFormData] = useState(selectedFile.id);
    const [attyFee, setAttyFee] = useState(0);
    const [govtFeePerClass, setGovtFeePerClass] = useState(0);
    const [isFormValid, setIsFormValid] = useState(false);
    const filesMap = useRef({});
    const [loading, setLoading] = useState(false);

    setResponseType('SOU');

    useEffect(() => {
        if (selectedFile) {
            const blocks = groupGoodsServices(selectedFile.custom_field_values).map((block, index) => ({
                id: index,
                classNo: block.class || index + 1,
                descriptions: block.description,
                dateFirstUse: '',
                dateFirstUseInCommerce: '',
                url: '',
                specimens: [],
            }));
            setClassBlocks(blocks);
        }
    }, [selectedFile]);

    useEffect(() => {
        // Find the "Trademark Statement of Use" category
        const statementOfUseCategory = pricingData.find(
            (category) => category.category === 'Trademark Statement of Use'
        );

        if (statementOfUseCategory) {
            // Find the "Statement of Use" service within the category
            const statementOfUseService = statementOfUseCategory.services.find(
                (service) => service.service === 'Statement of Use'
            );

            if (statementOfUseService) {
                // Parse the attorney fee and government fee
                const parsedAttyFee = parseFloat(statementOfUseService.attyFee.replace('$', '').replace(',', ''));
                const parsedGovtFee = parseFloat(statementOfUseService.govtFee.replace('$', '').split(' ')[0]);

                setAttyFee(parsedAttyFee);
                setGovtFeePerClass(parsedGovtFee);

                
            }
        }
    }, []);

    useEffect(() => {
        const allBlocksComplete = classBlocks.every(block => isClassBlockComplete(block.id));
        setIsFormValid(allBlocksComplete);
    }, [classBlocks, selectedUse]);


    const totalClasses = classBlocks.length; // Number of classes
    const totalGovtFee = govtFeePerClass * totalClasses;
    const totalCost = attyFee + totalGovtFee;
    setTotalFee(attyFee + totalGovtFee);
    console.log('totalFee SHOULD be: ', attyFee + totalGovtFee);

    const isClassBlockComplete = (blockId) => {
        const block = classBlocks.find(block => block.id === blockId);
        const useValue = selectedUse[blockId];

        if (!useValue) {
            // UseSelection value is not selected yet
            return false;
        }

        if (!block.dateFirstUse || !block.dateFirstUseInCommerce) {
            // Dates of first use are missing
            return false;
        }

        if (useValue === 'web') {
            if (!block.url || block.url.trim() === '') {
                // URL is missing
                return false;
            }
            // All required fields are present
            return true;
        } else if (useValue === 'use') {
            if (!block.specimens || block.specimens.length === 0) {
                // No specimens uploaded
                return false;
            }
            // All required fields are present
            return true;
        } else {
            // For other use types, define your criteria or return false
            return false;
        }
    };


    const handleAccordionToggle = (blockId) => {
        setExpandedBlockId(prevBlockId => (prevBlockId === blockId ? null : blockId));
    };

    const handleUseSelectionChange = (blockId, value) => {
        setSelectedUse(prevUse => ({ ...prevUse, [blockId]: value }));

        setClassBlocks(prevBlocks =>
            prevBlocks.map(block => {
                if (block.id !== blockId) {
                    return block; // No change to other blocks
                }

                // Create a copy of the block to update
                const updatedBlock = { ...block };

                // Clear fields based on the new use selection
                if (value === 'web') {
                    // User selected 'Mark in use on website'
                    updatedBlock.specimens = []; // Clear specimens
                    // Clear file previews related to specimens
                    setFilePreviews(prevPreviews =>
                        prevPreviews.filter(preview => preview.blockId !== blockId)
                    );
                } else if (value === 'use') {
                    // User selected 'Mark used on product/packaging'
                    updatedBlock.url = ''; // Clear URL
                }

                // Retain dateFirstUse and dateFirstUseInCommerce
                // These fields are unchanged

                return updatedBlock;
            })
        );
    };


    const handleInputChange = (blockId, field, value) => {
        setClassBlocks(prevBlocks =>
            prevBlocks.map(block =>
                block.id === blockId ? { ...block, [field]: value } : block
            )
        );
    };

    const handleSpecimenFileChange = (blockId, files) => {
        const fileArray = Array.from(files);
        const updatedFilePreviews = [...filePreviews];

        const blockIndex = classBlocks.findIndex(block => block.id === blockId);
        if (blockIndex === -1) {
            console.error("Block not found for blockId:", blockId);
            return;
        }

        const block = classBlocks[blockIndex];

        fileArray.forEach((file, index) => {
            const standardizedFileName = `${block.classNo} - Specimen ${updatedFilePreviews.length + index + 1}.${file.name.split('.').pop()}`;

            // Create a unique ID for the file
            const fileId = `${standardizedFileName}-${Date.now()}`;

            // **Create a new File object with the standardized file name**
            const newFile = new File([file], standardizedFileName, { type: file.type });

            // Store the new File object in filesMap
            filesMap.current[fileId] = newFile;

            const reader = new FileReader();

            reader.onloadend = () => {
                const fileInfo = {
                    id: fileId,
                    blockId: blockId,
                    name: standardizedFileName,
                    url: reader.result,
                    // No need to include the File object here
                };

                updatedFilePreviews.push(fileInfo);
                setFilePreviews([...updatedFilePreviews]);

                // Update the corresponding class block with the new fileInfo
                setClassBlocks(prevBlocks =>
                    prevBlocks.map(block =>
                        block.id === blockId
                            ? { ...block, specimens: [...(block.specimens || []), fileInfo] }
                            : block
                    )
                );
            };

            reader.readAsDataURL(newFile);
        });
    };

    const removeFilePreview = (fileId) => {
        setFilePreviews(filePreviews.filter(preview => preview.id !== fileId));
        // Also remove from classBlocks
        setClassBlocks(prevBlocks =>
            prevBlocks.map(block => ({
                ...block,
                specimens: block.specimens.filter(specimen => specimen.id !== fileId),
            }))
        );
    };

    const getFieldIdForClassBlock = (blockIndex, fieldName) => {
        // Build the expected field name based on the block index (e.g., 'Date of First Use 1')
        const expectedFieldName = `${fieldName} ${blockIndex + 1}`; // blockIndex starts from 0

        // Find the custom field in selectedFile.custom_field_values that matches the expected field name
        const field = selectedFile.custom_field_values.find(
            (field) => field.field_name === expectedFieldName
        );

        return field ? field.id : null;
    };

    // Check for session token
    const checkSession = async () => {
        const authData = localStorage.getItem('sntmAuth');
        if (authData) {
            const parsedData = JSON.parse(authData);

            const user = parsedData.data?.user;
            const session = parsedData.data?.session;

            if (session && session.access_token) {
                const isValidSession = await verifySession(session.access_token);
                if (isValidSession) {
                    return session.access_token;
                } else {
                    alert('Session is expired!');
                }
            } else {
                console.error('Session or access token is undefined');
            }
        } else {
            console.log('Did not find sntmAuth cookie!');
        }
    };

    const verifySession = async (token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/verify-session`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.ok;
        } catch (error) {
            return false;
        }
    };

    const clioFileUpload = async (noaFolder, token) => {
        console.log(selectedDeadline);

        let uploadPromises = [];

        // Handle specimen files upload
        for (const block of classBlocks) {
            if (block.specimens && block.specimens.length > 0) {
                for (const specimenInfo of block.specimens) {
                    const fileId = specimenInfo.id;
                    const file = filesMap.current[fileId]; // Retrieve the File object

                    if (!file) {
                        console.error('File not found for ID:', fileId);
                        continue;
                    }

                    // Upload the specimen file to Clio
                    const uploadPromise = uploadSpecimenToClio(file, noaFolder, token);
                    uploadPromises.push(uploadPromise);
                }
            }
        }

        try {
            await Promise.all(uploadPromises);
        } catch (error) {
            console.log('Error uploading files to Clio:', error);
        }
    };


    const uploadSpecimenToClio = async (file, folderId, token) => {
        const url = `${process.env.REACT_APP_DOMAIN}/api/create-and-upload-clio-document`;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('folderId', folderId);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    // Do not set 'Content-Type'; the browser will set it automatically
                },
                body: formData,
            });

            if (!response.ok) throw new Error('Failed to upload specimen to Clio');

            const result = await response.json();
            console.log('Specimen uploaded successfully:', result);
        } catch (error) {
            console.error('Error uploading specimen to Clio:', error);
        }
    };

    const generateStatementOfUseEmail = (
        classBlocks,
        attyFee,
        totalGovtFee,
        filePreviews,
        setEmailBody
      ) => {
        let messageBody = `
          <html>
          <head>
            <style>
              body { font-family: Times, serif; line-height: 1.6; }
              h2 { color: #333; }
              table { width: 100%; border-collapse: collapse; margin-top: 10px; }
              th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
              th { background-color: #f2f2f2; }
              .highlight { font-weight: bold; color: #2c3e50; }
            </style>
          </head>
          <body>
            <h2>Statement of Use Authorization</h2>
            <p>Thank you for authorizing the filing of your Statement of Use. Below is a summary of the information you've provided.</p>
      
            <h3>Goods/Services and Dates of First Use</h3>
            <table>
              <tr>
                <th>Class</th>
                <th>Date of First Use</th>
                <th>Date of First Use in Commerce</th>
              </tr>`;
      
        // For each class block, insert a row with the relevant dates
        classBlocks.forEach((block) => {
          const dateFirstUse = block.dateFirstUse || 'N/A';
          const dateFirstUseInCommerce = block.dateFirstUseInCommerce || 'N/A';
      
          messageBody += `
              <tr>
                <td>${block.classNo}</td>
                <td>${dateFirstUse}</td>
                <td>${dateFirstUseInCommerce}</td>
              </tr>`;
        });
      
        messageBody += `
            </table>
      
            <h3>Specimens</h3>
            <table>
              <tr>
                <th>Class</th>
                <th>File Name</th>
              </tr>`;
      
        // Include any specimen files the user uploaded
        filePreviews.forEach(({ blockId, name }) => {
          messageBody += `
              <tr>
                <td>${blockId}</td>
                <td>${name}</td>
              </tr>`;
        });
      
        messageBody += `
            </table>
      
            <h3>Total Fees</h3>
            <p><strong>Attorney Fee:</strong> $${attyFee.toFixed(2)}</p>
            <p><strong>Government Fee:</strong> $${totalGovtFee.toFixed(2)}</p>
            <p class="highlight"><strong>Total Cost:</strong> $${(attyFee + totalGovtFee).toFixed(2)}</p>
      
          </body>
          </html>
        `;
      
        // Set the built HTML into the email body
        setEmailBody(messageBody);
    };
         

    const handleSubmit = async () => {
        if (isFormValid) {
            setLoading(true);
            const customFieldValues = [];
            let isSpecimens = false;

            classBlocks.forEach((block, index) => {
                // Map dateFirstUse
                const dateFirstUseFieldId = getFieldIdForClassBlock(index, 'Date of First Use');
                if (dateFirstUseFieldId) {
                    customFieldValues.push({
                        id: dateFirstUseFieldId,
                        value: block.dateFirstUse,
                    });
                }

                // Map dateFirstUseInCommerce
                const dateFirstUseInCommerceFieldId = getFieldIdForClassBlock(index, 'Date of First Use in Commerce');
                if (dateFirstUseInCommerceFieldId) {
                    customFieldValues.push({
                        id: dateFirstUseInCommerceFieldId,
                        value: block.dateFirstUseInCommerce,
                    });
                }

                // Map URL
                if (block.url) {
                    const urlFieldId = getFieldIdForClassBlock(index, 'URL');
                    if (urlFieldId) {
                        customFieldValues.push({
                            id: urlFieldId,
                            value: block.url,
                        });
                    }
                }

                if (block.specimens && block.specimens.length > 0) {
                    isSpecimens = true;
                }
            });

            // Get the session token for verification
            const token = await checkSession();

            if (isSpecimens) {
                try {
                    const matterFoldersResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/getMatterFolders?matterId=${selectedFile.id}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (!matterFoldersResponse.ok) {
                        throw new Error('Failed to fetch matter folders');
                    }

                    const folders = await matterFoldersResponse.json();
                    // const noaFolder = folders.data.find(folder => folder.name === "Notice of Allowance");

                    // Get folder ID from the selectedEvent's custom_properties
                    const noaFolderContainer = selectedDeadline.external_properties.find((folderId) => folderId.name === 'folderID');
                    const noaFolder = noaFolderContainer.value;

                    if (!noaFolder) {
                        throw new Error('Notice of Allowance folder not found');
                    }

                    await clioFileUpload(noaFolder, token);
                } catch (error) {
                    console.log(`Error fetching matter folders or uploading files: ${error}`);
                    alert('An error occurred while uploading specimens. Please try again later.');
                }
            }

            try {
                const authorizeSOUResponse = await fetch(`${process.env.REACT_APP_DOMAIN}/api/authorizeSOU`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ custom_field_values: customFieldValues, matterId: selectedFile.id }),
                });

                if (!authorizeSOUResponse.ok) {
                    throw new Error('Failed to send Statement of Use information');
                }

                const response = await authorizeSOUResponse.json();

                await generateStatementOfUseEmail(
                    classBlocks,
                    attyFee,
                    totalGovtFee,
                    filePreviews,
                    setEmailBody
                );

                // Reset state variables
                setClassBlocks([]);
                setSelectedUse({});
                setExpandedBlockId(null);
                setFilePreviews([]);
                setFormData('');
                setIsFormValid(false);

                // Call the callback function to close the modal
                if (onFormSubmit) {
                    onFormSubmit();
                }
            } catch (error) {
                console.log(`Error sending Statement of Use information: ${error}`);
                alert('An error occurred while submitting the form. Please try again later.');
            } finally {
                setLoading(false);
            }
        } else {
            alert('Please complete all required fields before submitting.');
        }
    };


    return (
        <div className="container-fluid">
            {classBlocks.map((block) => (
                <div key={block.id} className="accordion-item authorizeSOU-classBlock-header">
                    <div className="accordion-header" onClick={() => handleAccordionToggle(block.id)}>
                        <h2 >{`Class ${block.classNo}: ${block.descriptions}`}</h2>
                    </div>
                    {expandedBlockId === block.id && (
                        <div className="accordion-content authorizeSOU-classBlock-content">
                            <UseSelection
                                value={selectedUse[block.id]}
                                onChange={(value) => handleUseSelectionChange(block.id, value)}
                                excludeOptions={["itu", "foreignApp", "foreignReg"]}
                            />
                            {/* Conditional rendering based on selectedUse[block.id] */}
                            {selectedUse[block.id] === 'web' && (
                                <div className="form-container">
                                    <Row>
                                        <Col md={6}>
                                            <div className="form-group">
                                                <label>Date of First Use (anywhere):*</label>
                                                <input
                                                    type="date"
                                                    value={block.dateFirstUse || ''}
                                                    onChange={(e) => handleInputChange(block.id, 'dateFirstUse', e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-group">
                                                <label>Date of First Use in Commerce:*</label>
                                                <input
                                                    type="date"
                                                    value={block.dateFirstUseInCommerce || ''}
                                                    onChange={(e) => handleInputChange(block.id, 'dateFirstUseInCommerce', e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="form-group">
                                                <label>URL:*</label>
                                                <input
                                                    type="text"
                                                    value={block.url || ''}
                                                    onChange={(e) => handleInputChange(block.id, 'url', e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            {selectedUse[block.id] === 'use' && (
                                <div className="form-container">
                                    <Row>
                                        <Col md={6}>
                                            <div className="form-group">
                                                <label>Date of First Use (anywhere):*</label>
                                                <input
                                                    type="date"
                                                    value={block.dateFirstUse || ''}
                                                    onChange={(e) => handleInputChange(block.id, 'dateFirstUse', e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-group">
                                                <label>Date of First Use in Commerce:*</label>
                                                <input
                                                    type="date"
                                                    value={block.dateFirstUseInCommerce || ''}
                                                    onChange={(e) => handleInputChange(block.id, 'dateFirstUseInCommerce', e.target.value)}
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="form-group">
                                                <label>Upload Specimen(s):*</label>
                                                <div className="custom-file-input-wrapper">
                                                    <Button variant="secondary" onClick={() => document.getElementById(`specimenFileInput-${block.id}`).click()}>
                                                        Upload Files
                                                    </Button>
                                                    <input
                                                        type="file"
                                                        id={`specimenFileInput-${block.id}`}
                                                        multiple
                                                        onChange={(e) => handleSpecimenFileChange(block.id, e.target.files)}
                                                        className="hidden-file-input"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* Display file previews if any */}
                                    <Row>
                                        <Col md={12}>
                                            <div className="file-previews">
                                                {filePreviews
                                                    .filter(preview => preview.blockId === block.id)
                                                    .map(preview => (
                                                        <div key={preview.id} className="file-preview">
                                                            <img src={preview.url} alt={preview.name} className="img-thumbnail" />
                                                            <p>{preview.name}</p>
                                                            <Button variant="danger" size="sm" onClick={() => removeFilePreview(preview.id)}>
                                                                Remove
                                                            </Button>
                                                        </div>
                                                    ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            {/* Implement other cases similarly */}
                        </div>
                    )}
                </div>
            ))}
            {/* Cost Display */}
            <div className="cost-summary">
                <h3>Cost Summary</h3>
                <p>Number of Classes: {totalClasses}</p>
                <p>Attorney Fee: ${attyFee.toFixed(0)}</p>
                <p>Government Fee: ${govtFeePerClass.toFixed(0)} × {totalClasses} = ${totalGovtFee.toFixed(0)}</p>
                <h4>Total Cost: ${totalCost.toFixed(0)}</h4>
            </div>
            {/* <Button onClick={handleSubmit} disabled={!isFormValid} className={!isFormValid ? 'disabledSubmitButton' : 'enabledSubmitButton'}>Submit</Button> */}
            <div className={!isFormValid ? 'disabledButtonWrapper' : ''}>
                <Button
                    onClick={handleSubmit}
                    disabled={!isFormValid || loading}
                    className={!isFormValid ? 'disabledSubmitButton' : 'enabledSubmitButton'}
                >
                    {loading ? 'Submitting...' : 'Submit'}
                </Button>
            </div>
        </div>
    );
};

export default ClientPortalUsageInfo;