import React, { useEffect, useRef, useState, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const PaymentPage = ({ totalAmount, setShowPaymentModal, responseType, userEmail, handleSendEmail, setIsPaymentSuccessful }) => {
    const stripeCheckout = useRef(null);
    const isInitializing = useRef(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [isCheckoutInitialized, setIsCheckoutInitialized] = useState(false);

    const initializeCheckout = useCallback(async () => {
        if (isInitializing.current || isCheckoutInitialized) {
            console.warn('⚠️ Checkout already initialized or in progress.');
            return;
        }

        isInitializing.current = true; // Prevent duplicate initializations

        try {
            console.log('🛑 Unmounting any existing Stripe Checkout instance...');
            await unmountCheckout(); // Ensure cleanup before creating a new instance

            console.log('⚡ Loading Stripe...');
            const stripe = await loadStripe('pk_test_51QdKGvGhriqSx61wOLP0EbPc2MLfxmY4Y0tZwUSyvVmIkHyu9C8bMqdUoOE6IiFjKOsIVp9HPpnOrL9JYu9FPk9u00DZ9UleE2');

            const fetchClientSecret = async () => {
                const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/create-checkout-session`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ totalAmount, responseType, userEmail }),
                });

                if (!response.ok) throw new Error('Failed to fetch client secret');

                const { clientSecret } = await response.json();
                return clientSecret;
            };

            const clientSecret = await fetchClientSecret();

            console.log('⚡ Initializing Embedded Checkout...');
            stripeCheckout.current = await stripe.initEmbeddedCheckout({
                fetchClientSecret: () => Promise.resolve(clientSecret),
                onComplete: async () => {
                    console.log('✅ Checkout completed!');
                    setPaymentSuccess(true);
                    setIsPaymentSuccessful(true);
                    // try {
                    //     await handleSendEmail();
                    //     console.log("📩 Email successfully sent after payment.");
                    // } catch (emailError) {
                    //     console.error('⚠️ Error sending email:', emailError);
                    // }
                    // Only call handleSendEmail if it was passed as a prop
                    if (typeof handleSendEmail === "function") {
                        try {
                            await handleSendEmail();
                            console.log("📩 Email successfully sent after payment.");
                        } catch (emailError) {
                            console.error('⚠️ Error sending email:', emailError);
                        }
                    } else {
                        console.log("🚫 No email function provided, skipping email step.");
                    }
                    await unmountCheckout();
                    isInitializing.current = false;
                }
            });

            console.log('⚡ Mounting checkout...');
            await stripeCheckout.current.mount('#checkout');
            console.log('✅ Stripe Embedded Checkout mounted successfully.');

            setIsCheckoutInitialized(true); // Mark as initialized
            isInitializing.current = false; // Reset flag after success
        } catch (error) {
            console.error('Error initializing Stripe Checkout:', error);
            await unmountCheckout(); // Ensure cleanup on failure
            isInitializing.current = false;
        }
    }, [totalAmount, responseType, userEmail, setIsPaymentSuccessful, handleSendEmail]);

    const unmountCheckout = async () => {
        if (stripeCheckout.current) {
            console.warn('Unmounting existing Stripe Checkout instance...');
            try {
                // await stripeCheckout.current.unmount();
                await stripeCheckout.current.destroy();  // Full cleanup
            } catch (error) {
                console.error("⚠️ Error unmounting checkout:", error);
            }
            stripeCheckout.current = null;
            setIsCheckoutInitialized(false);
        }
    };

    const closeModal = async () => {
        console.warn('Closing payment modal & unmounting checkout...');
        await unmountCheckout();
        setShowPaymentModal(false);
        console.log("Payment modal closed.");
    };

    // Initialize Checkout ONLY when the modal is first shown
    // useEffect(() => {
    //     if (!isCheckoutInitialized) {
    //         initializeCheckout();
    //     }
    // }, [isCheckoutInitialized]);

    // Initialize Checkout ONLY when the modal is first shown
    useEffect(() => {
        initializeCheckout();
    }, [initializeCheckout]);
    

    return (
        <div className="payment-modal">
            <h2>Complete Your Payment</h2>
            {paymentSuccess ? (
                <div>
                    <h3>Payment Successful!</h3>
                </div>
            ) : (
                <>
                    <div id="checkout" style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}></div>
                    <button onClick={closeModal} style={{ marginTop: '20px', background: 'red', color: 'white' }}>Cancel Payment</button>
                </>
            )}
        </div>
    );
};

export default PaymentPage;