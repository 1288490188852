import React, { useState, useEffect, useRef } from "react";
import './App.css';
import { Modal, Button } from 'react-bootstrap';

const ClassBlock = ({
    classValue,
    descriptions = [],
    onRemove,
    additionalInputs,
    onUpdateDescription,
    formData,
    onRemoveDescription,
    updateTeasPlusEligibility,
}) => {
    const classes = [
        { name: "001", value: "001" },
        { name: "002", value: "002" },
        { name: "003", value: "003" },
        { name: "004", value: "004" },
        { name: "005", value: "005" },
        { name: "006", value: "006" },
        { name: "007", value: "007" },
        { name: "008", value: "008" },
        { name: "009", value: "009" },
        { name: "010", value: "010" },
        { name: "011", value: "011" },
        { name: "012", value: "012" },
        { name: "013", value: "013" },
        { name: "014", value: "014" },
        { name: "015", value: "015" },
        { name: "016", value: "016" },
        { name: "017", value: "017" },
        { name: "018", value: "018" },
        { name: "019", value: "019" },
        { name: "020", value: "020" },
        { name: "021", value: "021" },
        { name: "022", value: "022" },
        { name: "023", value: "023" },
        { name: "024", value: "024" },
        { name: "025", value: "025" },
        { name: "026", value: "026" },
        { name: "027", value: "027" },
        { name: "028", value: "028" },
        { name: "029", value: "029" },
        { name: "030", value: "030" },
        { name: "031", value: "031" },
        { name: "032", value: "032" },
        { name: "033", value: "033" },
        { name: "034", value: "034" },
        { name: "035", value: "035" },
        { name: "036", value: "036" },
        { name: "037", value: "037" },
        { name: "038", value: "038" },
        { name: "039", value: "039" },
        { name: "040", value: "040" },
        { name: "041", value: "041" },
        { name: "042", value: "042" },
        { name: "043", value: "043" },
        { name: "044", value: "044" },
        { name: "045", value: "045" },
    ];

    // Add a state to track the dynamic input values
    const [inputValues, setInputValues] = useState(additionalInputs || {});
    const [editMode, setEditMode] = useState({});
    const [editValues, setEditValues] = useState({}); //Stores current edit values
    const [textareaWidths, setTextareaWidths] = useState({});
    const [teasPlusEligible, setTeasPlusEligible] = useState(true);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [editingDescId, setEditingDescId] = useState(null);
    const [localDescriptions, setLocalDescriptions] = useState(descriptions);
    const editRefs = useRef(descriptions.map(() => React.createRef()));
    const [isProcessing, setIsProcessing] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentDescriptionId, setCurrentDescriptionId] = useState(null);

    // Initialize the useRef hook at the top level of the React component
    const measurementSpanRef = useRef(null);
    const textareaRef = useRef(null);
    const textareaRefs = useRef([]);


    useEffect(() => {
        // Initialize the measurement span element for width calculations
        if (!measurementSpanRef.current) {
            const span = document.createElement('span');
            span.style.visibility = 'hidden';
            span.style.position = 'absolute';
            span.style.whiteSpace = 'pre-wrap';
            span.style.fontSize = '16pt';
            span.style.fontFamily = 'Roboto Serif';
            document.body.appendChild(span);
            measurementSpanRef.current = span;
        }

        // Cleanup function to remove the measurement span from the document
        return () => {
            if (measurementSpanRef.current) {
                document.body.removeChild(measurementSpanRef.current);
                measurementSpanRef.current = null;
            }
        };
    }, []);

    // Whenever additionalInputs changes, update inputValues accordingly
    useEffect(() => {
        setInputValues(additionalInputs || {});
    }, [additionalInputs]);

    useEffect(() => {
        setLocalDescriptions(descriptions); // Initialize with props.descriptions
    }, [descriptions]);

    const toggleEditMode = (descId) => {
        setEditMode(prev => {
            const isEditing = !prev[descId];
            if (isEditing) {
                // Entering edit mode for a "core" description.
                // Find the description and update editValues for that description only.
                const desc = descriptions.find(d => d.id === descId);
                setEditValues(editPrev => ({
                    ...editPrev,
                    [descId]: desc.text // Assuming desc.text contains the "core" description text.
                }));
            }
            // Important: This logic only toggles the edit mode state and updates the "core" description.
            // It should not affect the rendering or state of the additional input textareas.
            return { ...prev, [descId]: isEditing };
        });
    };

    const handleEditChange = (descId, newText) => {
        setEditValues(prev => ({
            ...prev,
            [descId]: newText
        }));
    };

    const handleInputChange = (index, value) => {
        const updatedDescriptions = [...localDescriptions];
        // Assuming `core` should be an array; adjust accordingly if it's not
        if (Array.isArray(updatedDescriptions[index].core)) {
            updatedDescriptions[index].core = [value]; // Replace the array with a new one containing the updated value
        } else {
            updatedDescriptions[index].core = value; // Directly update `core` if it's just a string
        }
        setLocalDescriptions(updatedDescriptions);
        console.log('handleInputChange Function - Updated Description:', updatedDescriptions);
    };

    const handleRemoveDescription = (classId, descId) => {
        setShowConfirmModal(true);
        setCurrentDescriptionId({ classId, descId });
    };

    const confirmDeletion = () => {
        setIsProcessing(true);
        // Simulate a deletion process
        setTimeout(() => {
            onRemoveDescription(currentDescriptionId.classId, currentDescriptionId.descId);
            setIsProcessing(false);
            setShowConfirmModal(false);
            setCurrentDescriptionId(null);
        }, 1000);
    };

    const cancelDeletion = () => {
        setShowConfirmModal(false);
        setCurrentDescriptionId(null);
    };

    const handleSave = () => {
        // Assuming onUpdateDescription needs the entire block of descriptions updated
        onUpdateDescription(classValue.id, localDescriptions);
        setEditMode({}); // Reset edit modes
    };

    const saveDescription = (classBlockId, descId) => {
        const classBlock = formData.classBlocks.find(block => block.id === classBlockId);
        const currentDescriptionIndex = classBlock.descriptions.findIndex(desc => desc.id === descId);
        const localDescription = localDescriptions.find(desc => desc.id === descId);

        if (currentDescriptionIndex === -1 || !localDescription) {
            console.error("Description not found.");
            return;
        }

        // Use spread operator to handle both single and multiple core descriptions
        let updatedCore = Array.isArray(localDescription.core) ?
            [...localDescription.core] :
            [localDescription.core];

        const updatedDescription = {
            ...classBlock.descriptions[currentDescriptionIndex],
            core: updatedCore.length === 1 ? updatedCore[0] : updatedCore, // Adjust based on whether a single or multiple cores
            additionalInputs: localDescription.additionalInputs,
        };

        // Create a new array with the updated description
        const updatedDescriptions = [
            ...classBlock.descriptions.slice(0, currentDescriptionIndex),
            updatedDescription,
            ...classBlock.descriptions.slice(currentDescriptionIndex + 1)
        ];

        // Update the class block with the new descriptions
        const updatedClassBlock = {
            ...classBlock,
            descriptions: updatedDescriptions
        };

        onUpdateDescription(classBlockId, updatedClassBlock);

        // Find the original goodsServices string for the class block
        const originalDescriptions = formData.classBlocks.find(block => block.id === classBlockId).goodsServices;

        // Check TEAS Plus eligibility
        const teasPlusEligible = checkTEASPlusEligibility(updatedCore, originalDescriptions);

        // Update formData for TEAS Plus eligibility and exit edit mode
        toggleEditMode(descId);
    };

    const handleBlur = (e, classBlockId, descId, inputKey) => {
        const inputValue = e.target.textContent.trimEnd(); // Trim and use the trimmed value

        // Re-adjust the height based on the trimmed value, if necessary
        adjustTextareaHeight(e.target);

        // Update local state for additionalInputs
        setInputValues(prev => ({
            ...prev,
            [descId]: {
                ...(prev[descId] || {}),
                [inputKey]: inputValue,
            },
        }));

        // Fetch the existing description from formData to ensure all other data remains intact
        const classBlock = formData.classBlocks.find(block => block.id === classBlockId);
        const existingDescription = classBlock.descriptions.find(desc => desc.id === descId);

        // Ensure the merging of additionalInputs keeps other entries
        const updatedAdditionalInputs = {
            ...existingDescription.additionalInputs,
            [inputKey]: inputValue,
        };

        const updatedDescription = {
            ...existingDescription, // Keep all other existing description data intact
            additionalInputs: updatedAdditionalInputs, // Only update additionalInputs with the merged result
        };

        // Call onUpdateDescription to update the formData state in the parent component
        onUpdateDescription(classBlockId, descId, updatedDescription);
    };

    const handleCoreChange = (descId, index, value, type) => {
        onUpdateDescription(descId, index, value, type);
    };

    // Function to check TEAS Plus eligibility based on core description edits
    const checkTEASPlusEligibility = (editedDescriptions, originalDescriptions) => {
        // Split original descriptions by semicolon and trim each entry
        const originalDescriptionsArray = originalDescriptions.split(';').map(desc => desc.trim());

        const editedDescriptionsArray = Array.isArray(editedDescriptions) ? editedDescriptions : [editedDescriptions];

        // Determine if the array of edited descriptions exactly matches the original descriptions array
        // This checks both the content and the order of descriptions
        const isEligible = editedDescriptionsArray.length === originalDescriptionsArray.length &&
            editedDescriptionsArray.every((desc, index) =>
                desc && originalDescriptionsArray[index] && desc.trim() === originalDescriptionsArray[index]
            );

        if (!isEligible) {
            updateTeasPlusEligibility(false);
        } else {
            updateTeasPlusEligibility(true);
        }

        return isEligible;
    };

    const generateDescriptionWithInputs = (descId, coreSegments, additionalInputs, isEditMode, measurementSpanRef) => {
        if (!measurementSpanRef.current) {
            console.warn('Measurement span is not ready');
            return null; // Or handle this case appropriately
        }

        const components = [];

        coreSegments.forEach((coreSegment, index) => {
            const coreRef = React.createRef();
            const additionalRef = React.createRef();

            components.push(
                <span
                    key={`${descId}-core-${index}`}
                    onBlur={(e) => handleInputChange(index, e.target.textContent)}
                    className={isEditMode ? 'core-goods-services-editable-input-box' : ''}
                    contentEditable={isEditMode}
                    style={{ display: 'inline-block', marginRight: '5px' }}
                    ref={coreRef}
                    suppressContentEditableWarning={true}
                >
                    {coreSegment}
                </span>
            );

            const additionalInputKey = Object.keys(additionalInputs)[index];
            const additionalInputValue = additionalInputs[additionalInputKey];

            if (additionalInputValue !== undefined) {
                components.push(
                    <span
                        key={`${descId}-additional-${additionalInputKey}`}
                        className='editable-input-box'
                        contentEditable='true'
                        style={{ display: 'inline-block' }}
                        onBlur={(e) => handleBlur(e, classValue.id, descId, additionalInputKey)}
                        ref={additionalRef}
                        suppressContentEditableWarning={true}
                    >
                        {additionalInputValue}
                    </span>
                );
            }
        });

        return <div style={{ display: 'flex', flexWrap: 'wrap' }}>{components}</div>;
    };

    // Function to adjust the width of a textarea based on its content
    const adjustTextareaWidth = (textareaId, content, measurementSpanRef) => {
        if (!measurementSpanRef.current) {
            console.warn('Measurement span is not ready');
            return null; // Or handle this case appropriately
        }
        const span = measurementSpanRef.current;
        if (span) {
            // Set the span's text to the textarea content
            span.textContent = content;

            // Measure the content width
            const contentWidth = span.offsetWidth + 10; // +10 for some padding

            // Find the textarea by ID and set its width
            const textarea = document.getElementById(textareaId);
            if (textarea) {
                textarea.style.width = `${Math.max(contentWidth, 200)}px`; // Ensure a minWidth if needed
            }
        }
    };

    const adjustTextareaHeight = (element) => {
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
    };

    const removeDescription = (classBlockId, descId) => {
        // Find the classBlock by ID
        const classBlock = formData.classBlocks.find(block => block.id === classBlockId);
        if (!classBlock) {
            console.error('ClassBlock not found');
            return;
        }

        // Filter out the description to remove
        const updatedDescriptions = classBlock.descriptions.filter(desc => desc.id !== descId);

        // Construct an updated classBlock object with the updated descriptions array
        const updatedClassBlock = {
            ...classBlock,
            descriptions: updatedDescriptions
        };

        // Assuming onUpdateDescription can handle an entire classBlock update,
        // call it with the classBlockId and the updatedClassBlock
        // This might require adjusting how onUpdateDescription works if it currently expects a different structure
        onUpdateDescription(classBlockId, updatedClassBlock);
    };

    const WarningModal = ({ isOpen, onCancel, onContinue }) => {
        if (!isOpen) return null;

        return (
            <div className='warning-modal'>
                <div className='warning-modal-content'>
                    <h2>WARNING:</h2>
                    <p>Editing the description(s) will result in a $200 government surcharge fee for ALL classes in the application.
                        As a result the filing fee will be $550 for EACH class instead of $350. If the edited description contains more than 1,000 characters (including punctuation and spaces) then
                        there will be an additional $200 government surcharge fee. Government surcharge fees are determined and collected by the United States Patent and Trademark Office, not 
                        San Novus Trademark.
                    </p>
                </div>
                <div className='warning-modal-buttons'>
                    <button onClick={onContinue} className='warning-modal-button'>Continue</button>
                    <button onClick={onCancel} className='warning-modal-button'>Cancel</button>
                </div>
            </div>
        );
    };

    const handleEditClick = (descId) => {
        setEditingDescId(descId); // Track which description is being edited
        setShowWarningModal(true); // Show the warning modal
    };

    const handleCancelEdit = () => {
        setShowWarningModal(false);
        setEditingDescId(null); // Clear the tracked editing description ID
    };

    const handleContinueEdit = () => {
        setShowWarningModal(false);
        toggleEditMode(editingDescId); // Enable edit mode for the tracked description
        setEditingDescId(null); // Clear the tracked editing description ID
    };

    // Normalize core segments and generate description components before the return statement
    const descriptionComponents = descriptions.map((desc) => {
        const coreSegmentsNormalized = Array.isArray(desc.core) ? desc.core : [desc.core || ''];
        const handleSaveClick = () => {
            if (editMode[desc.id]) {
                const editedCore = editValues[desc.id];
                const editedAdditionalInputs = inputValues[desc.id];
                saveDescription(classValue.id, desc.id, editedCore, editedAdditionalInputs);
            } else {
                // toggleEditMode(desc.id);
                handleEditClick(desc.id);
            }
        };

        return {
            component: generateDescriptionWithInputs(desc.id, coreSegmentsNormalized, desc.additionalInputs || {}, editMode[desc.id], measurementSpanRef),
            handleSaveClick: handleSaveClick,
            id: desc.id, // Ensure desc.id is included here if it's not already
            isEditMode: !!editMode[desc.id],
        };
    });

    return (
        <div>
            <div className='class-container'>
                <div className='label-container'>
                    <label htmlFor={`classNo-${classValue.id}`}>International Class:</label>
                </div>
                <div className='field-container'>
                    <label
                        id={`classNo-${classValue.id}`}
                        value={classValue.classNo}
                    >{classValue.classNo}
                    </label>
                </div>
            </div>

            <div className='class-container'>
                <div className='field-container'>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th colSpan='3'>Goods/Services:</th>
                                </tr>
                            </thead>
                            <tbody>
                                {descriptionComponents.map((item, index) => (
                                    <tr key={index}>
                                        <td className="description-cell">{item.component}</td>
                                        <td>
                                            <button onClick={item.handleSaveClick} className="btn btn-primary">
                                                {item.isEditMode ? 'Save' : 'Edit'}
                                            </button>
                                        </td>
                                        <td>
                                            <button className="btn btn-danger" onClick={() => handleRemoveDescription(classValue.id, item.id)}>Remove</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <WarningModal
                        isOpen={showWarningModal}
                        onCancel={handleCancelEdit}
                        onContinue={handleContinueEdit}
                    />
                </div>
            </div>

            <button onClick={() => onRemove(classValue.id)} className='btn btn-warning remove-class'>Remove Class</button>
            {showConfirmModal && (
                <Modal show={showConfirmModal} onHide={cancelDeletion}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this description?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={cancelDeletion}>Cancel</Button>
                        <Button variant="danger" onClick={confirmDeletion}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default ClassBlock;