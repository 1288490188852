import React, { useEffect, useRef, useState } from "react";
// import logo from './FullLogo6.png';
import logo from './Full_Logo_Final.png';
import './App.css';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Collapse } from "bootstrap";
import PricingCalculator from "./pricingCalculator.js";
import OurFirm from "./ourFirm.js";

const Header = () => {
    const location = useLocation();
    const navbarRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    const [showPricingCalculator, setShowPricingCalculator] = useState(false);
    const [showServicesDropdown, setShowServicesDropdown] = useState(false);
    const dropdownRef = useRef(null);


    const toggleForm = () => {
        setShowPricingCalculator(!showPricingCalculator);
    };

    useEffect(() => {
        const navbarCollapse = document.getElementById('navbarNavDropdown');
        const collapseInstance = new Collapse(navbarCollapse, {
            toggle: false
        });

        const handleNavbarToggle = () => {
            collapseInstance.toggle();
        };

        const handleClickOutside = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                collapseInstance.hide();
            }
        };

        const navbarToggler = document.getElementById('navbar-toggler');
        navbarToggler.addEventListener('click', handleNavbarToggle);
        document.addEventListener('click', handleClickOutside);

        // Ensure the navbar is collapsed when the route changes
        return () => {
            collapseInstance.hide();
            navbarToggler.removeEventListener('click', handleNavbarToggle);
            document.removeEventListener('click', handleClickOutside);
        };
    }, [location]);

    // const handleServicesClick = (e) => {
    //     if (isMobile) {
    //         e.preventDefault();
    //         const dropdown = document.getElementById('servicesDropdown');
    //         const dropdownInstance = Collapse.getOrCreateInstance(dropdown);
    //         dropdownInstance.toggle();
    //     }
    // };

    const handleServicesClick = (e) => {
        if (isMobile) {
            e.preventDefault(); // Prevent navigation
            setShowServicesDropdown(prev => !prev); // Toggle the dropdown
        }
    };

    // Close dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowServicesDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        setShowServicesDropdown(false); // collapse dropdown on route change
    }, [location]);

    useEffect(() => {
        const navbarCollapse = document.getElementById('navbarNavDropdown');
        const navbarToggler = document.getElementById('navbar-toggler');

        const collapseInstance = Collapse.getOrCreateInstance(navbarCollapse);

        const handleClickOutside = (event) => {
            if (
                isMobile &&
                navbarCollapse.classList.contains('show') && // only if expanded
                navbarRef.current &&
                !navbarRef.current.contains(event.target)
            ) {
                collapseInstance.hide(); // collapse the navbar
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isMobile]);


    return (
        <header className="header-container">
            <nav className="navbar navbar-expand-lg" ref={navbarRef}>
                <div className="container-fluid" style={{ height: '8rem', position: 'relative' }}>
                    {/* Toggler Button for Mobile View */}
                    <button
                        id="navbar-toggler"
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        // data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        style={{ position: 'absolute', left: '7rem', top: '2rem', zIndex: 1050 }}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {/* Brand Logo */}
                    <Link to='/' className="navbar-brand" style={{ position: 'absolute', right: '1rem', top: '1rem', zIndex: 1050 }}>
                        <img src={logo} alt="San Novus Trademark Logo" className="full-logo" />
                    </Link>
                </div>

                {/* Navbar Content */}
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item dropdown" ref={dropdownRef}>
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="servicesDropdown"
                                role="button"
                                // data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={(e) => {
                                    e.preventDefault(); // prevent navigation
                                    setShowServicesDropdown((prev) => !prev); // toggle
                                }}
                            >
                                Services
                            </a>
                            {/* <ul className="dropdown-menu show" aria-labelledby="servicesDropdown"> */}
                            <ul className={`dropdown-menu ${showServicesDropdown ? "show" : ""}`} aria-labelledby="servicesDropdown">
                                <li><Link to="/trademark-searches" className="dropdown-item" onClick={() => setShowServicesDropdown(false)}>Trademark Searches</Link></li>
                                <li><Link to="/trademark-registration-applications" className="dropdown-item" onClick={() => setShowServicesDropdown(false)}>Registration Applications</Link></li>
                                <li><Link to="/trademark-oar" className="dropdown-item" onClick={() => setShowServicesDropdown(false)}>Office Action Responses</Link></li>
                                <li><Link to="/trademark-sou" className="dropdown-item" onClick={() => setShowServicesDropdown(false)}>Statements of Use</Link></li>
                                <li><Link to="/trademark-renewals" className="dropdown-item" onClick={() => setShowServicesDropdown(false)}>Registration Renewals</Link></li>
                                <li><Link to="/international-trademarks" className="dropdown-item" onClick={() => setShowServicesDropdown(false)}>International Trademark Applications</Link></li>
                                <li><Link to="/amazon-brand-registry" className="dropdown-item" onClick={() => setShowServicesDropdown(false)}>Amazon Brand Registry</Link></li>
                            </ul>
                        </li>


                        {/* <li className="nav-item"><Link to="/about" className="nav-link">About</Link></li>
                        <li className="nav-item"><Link to="/why-us" className="nav-link">Why Us?</Link></li> */}
                        <li className="nav-item"><Link to="/our-firm" className="nav-link">Our Firm</Link></li>
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={toggleForm}>Pricing Calculator</a>
                        </li>
                        <li className="nav-item"><Link to="/faq" className="nav-link">FAQ</Link></li>
                        <li className="nav-item"><Link to="/pricing" className="nav-link">Pricing</Link></li>
                        <li className="nav-item"><Link to="/contact-us" className="nav-link">Contact Us</Link></li>
                        <li className="nav-item"><Link to="/clientPortal" className="nav-link">Client Portal</Link></li>
                    </ul>
                </div>
            </nav>

            {showPricingCalculator && (
                <div className="modal pricing-calculator-modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={toggleForm}>❌ Close</button>
                        <PricingCalculator
                            toggleForm={toggleForm}
                            onClose={toggleForm}
                        />
                    </div>
                </div>
            )}

        </header>
    );
};

export default Header;
