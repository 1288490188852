// pricingData.js
export const pricingData = [
    { 
        category: "Trademark Searches", services: [
            {service: "Comprehensive Search", attyFee: "$529", govtFee: "$ -"},
        ]
    },
    { 
        category: "Trademark Applications", services: [
            { service: "Trademark Registration Application (without search)", attyFee: "$265", govtFee: "$350 (per class)" },
            { service: "Trademark Registration Application (includes Knockout Search)", attyFee: "$375", govtFee: "$350 (per class)" },
        ]
    },
    {
        category: "Trademark Office Action", services: [
            { service: "Likelihood of Confusion", attyFee: "$500", govtFee: "$ -" },
            { service: "Merely Descriptive", attyFee: "$500", govtFee: "$ -" },
            { service: "Geographically Descriptive", attyFee: "$500", govtFee: "$ -" },
            { service: "Amendment to Supplemental Register", attyFee: "$200", govtFee: "$ - " },
            { service: "Objection to Description of Goods/Services", attyFee: "$100", govtFee: "$350 (per added class)" },
            { service: "Rejection of Specimen", attyFee: "$175", govtFee: "$ -" },
            { service: "Objection to Mark Description", attyFee: "$100", govtFee: "$ -" },
            { service: "Primarily a Surname", attyFee: "$100", govtFee: "$ -" },
            { service: "Disclaimer", attyFee: "$100", govtFee: "$ -" },
            { service: "Translation", attyFee: "$100", govtFee: "$ -" },
            { service: "Audit", attyFee: "$350", govtFee: "$ -" },
        ]
    },
    { 
        category: "Trademark Statement of Use", services: [
            {service: "Statement of Use", attyFee: "$250", govtFee: "$150 (per class)"},
        ]
    },
    {
        category: "Renewals", services: [
            { service: "Section 8 only", attyFee: "$175", govtFee: "$325" },
            { service: "Section 8 (grace period)", attyFee: "$175", govtFee: "$425" },
            { service: "Section 15 only", attyFee: "$150", govtFee: "$250" },
            { service: "Section 9 only", attyFee: "$175", govtFee: "$325" },
            { service: "Section 9 (grace period)", attyFee: "$175", govtFee: "$425" },
            { service: "Section 8 & 15", attyFee: "$250", govtFee: "$575" },
            { service: "Section 8 & 15 (grace period)", attyFee: "$250", govtFee: "$675" },
            { service: "Section 8 & 9", attyFee: "$250", govtFee: "$650" },
            { service: "Section 8 & 9 (grace period)", attyFee: "$250", govtFee: "$750" },
        ]
    },
    {
        category: "International Applications", services: [
            { service: "Madrid Protocol", attyFee: "$375", govtFee: "Varies by country" },
            { service: "Direct Filing", attyFee: "$100 per country", govtFee: "Varies by country" }
        ]
    },
];

