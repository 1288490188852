import React from "react";
import './App.css';

/* FAQ Question categories:
    general
    usage
    application
    oar
    international
    renewal
*/

const FAQList = [
        {
            id: 1,
            tags: ["general"],
            question: "What is the difference between a trademark and a copyright?",
            answer: [
                <div className='answer-container'>
                    <div className='answer-component'>
                        <span><b>Trademarks:</b></span>
                        <p>Trademarks are used to distinguish your goods or services from others, associating your specific brand with your mark.
                            They typically cover things like brand names, logos, and slogans.</p>
                    </div>
                    <div className='answer-component'>
                        <span><b>Copyrights:</b></span>
                        <p>Copyrights protect original works of authorship, such as books, paintings, music, and films. They give the creator 
                            exclusive rights to their work.</p>
                    </div>
                </div>
                // <p><b>Trademarks: </b>Trademarks are used to distinguish your goods or services from others, associating your specific brand with your mark.
                // They typically cover things like brand names, logos, and slogans.</p>,
                // <p><b>Copyrights: </b>Copyrights protect original works of authorship, such as books, paintings, music, and films. They give the creator 
                // exclusive rights to their work.</p>
            ]
        },
        {
            id: 27, 
            tags: ["general"],
            question: "My application has been filed, do I now have a registered trademark?",
            answer: [
                <p>Not yet. Filing a tradeamrk application does not mean that you have a federally registered trademark. What you are filing is an application for your mark to be federally registered.
                    Your trademark application will be thoroughly examined by a an examining attorney at the United States Patent and Trademark Office before it can be allowed for federal registration.</p>
            ]
        },
        {
            id: 28, 
            tags: ["general"],
            question: "My application has been filed, can I now use the \u00AE symbol?",
            answer: [
                <p>Not quite yet. The &reg; symbol is reserved for federally registered marks. If your trademark application has not yet completed the examination and registration process, you will 
                    need to mark your trademark with "TM" instead of the &reg; symbol. Most people use the "TM" marking as either a subscript or a superscript to their mark.
                </p>
            ]
        },
        {
            id: 2,
            tags: ["general"],
            question: "Why do I need a federally registered trademark?",
            answer: [
                <div className='answer-container'>
                    <p>Obtaining a federally registered trademark is invaluable for your business for several reasons:</p>
                    <div className='answer-component'>
                        <span><b>Protection Against Competitors:</b></span>
                        <p>It legally protects your brand, preventing others from using similar marks that could
                            cause confusion. This protection extends nationwide, giving you exclusive rights to use your mark in connection with your 
                            goods or services in all 50 states.</p>
                    </div>
                    <div className='answer-component'>
                        <span><b>Brand Recognition:</b></span>
                        <p>A registered trademark makes it easier for customers to identify and choose your products or services
                            over others. It serves as a symbol of quality and trust associated with your brand.</p>
                    </div>
                    <div className='answer-component'>
                        <span><b>Enhanced Business Value:</b></span>
                        <p>A trademark is an intangible asset that can significantly increase your company's worth. It not 
                            only bolsters brand recognition but can also be a key factor in business agreements, licensing, or even in the 
                            sale of the business.</p>
                    </div>
                    <p>A federally registered trademark is more than just a symbol; it's a key component of your brand's identity and business strategy.</p>
                    <p>Thinking about trademark registration or have questions? Contact <a href='/contact-us' style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">San Novus Trademark</a> for a comprehensive discussion on how a 
                        federal trademark registration can benefit your specific business.</p>
                </div>

                // <p>Obtaining a federally registered trademark is invaluable for your business for several reasons:</p>,
                // <p><b>Protection Against Competitors: </b> It legally protects your brand, preventing others from using similar marks that could
                // cause confusion. This protection extends nationwide, giving you exclusive rights to use your mark in connection with your goods
                // or services in all 50 states.</p>,
                // <p><b>Brand Recognition: </b>A registered trademark makes it easier for customers to identify and choose your products or services
                // over others. It serves as a symbol of quality and trust associated with your brand.</p>,
                // <p><b>Enhanced Business Value: </b>A trademark is an intangible asset that can significantly increase your company's worth. It not 
                // only bolsters brand recognition but can also be a key factor in business agreements, licensing, or even in the sale of the business.</p>,
                // <p>A federally registered trademark is more than just a symbol; it's a key component of your brand's identity and business strategy.</p>,
                // <p>Thinking about trademark registration or have questions? Contact <b>San Novus Trademark</b> for a comprehensive discussion on how a 
                // federal trademark registration can benefit your specific business.</p>
            ]
        },
        {
            id: 3,
            tags: ["general"],
            question: "Are trademark registrations expensive?",
            answer: [
                <p>While the cost of filing a federal trademark application can vary, it doesn’t necessarily have to be expensive. The overall cost 
                    is influenced by several factors, including the type of application, the number of goods and/or service classes included, and 
                    the specific details of your mark. Every client is unique.</p>,
                <p>At <b>San Novus Trademark</b>, we’re committed to providing competitive and transparent pricing. We understand the importance of budgeting, 
                    especially for small businesses and startups. Our aim is to help you strategize your trademark filing in a way that aligns with 
                    your business needs, ensuring you get the best value for your investment.</p>,
                <p>Concerned about costs or need a tailored filing plan? Reach out to us for a detailed discussion. We can provide you with a 
                    customized filing strategy and a clear understanding of our fee structure, which is designed to be straightforward and 
                    budget-friendly. An overview of our fee schedule can be viewed <a href='/pricing' style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">here</a>.</p>
            ]
        },
        {
            id: 4,
            tags: ["general"],
            question: "I’ve heard the terms “trademark” and “service mark.” How are these different?",
            answer: [
                <p>Both trademarks and service marks function as identifiers, but they apply to different aspects of a business:</p>,
                <p><b>Trademark: </b>This term is used to identify and distinguish goods or products offered for sale. For example, the 
                Nike<span style={{fontSize:'50%', verticalAlign:'super'}}>&reg;</span> “swoosh” on shoes is a trademark, as it identifies the source of the footwear.</p>,
                <p><b>Service Mark: </b>Different from a trademark, a service mark relates to services rather than goods. For instance, 
                the term FedEx<span style={{fontSize:'50%', verticalAlign:'super'}}>&reg;</span> used in relation to shipping and delivery services is a service mark, as it identifies the 
                source of these services.</p>,
                <p>Legally, both trademarks and service marks are registered and protected in similar ways, ensuring that businesses can safeguard 
                    their brand identity, whether it relates to products, services, or both.</p>,
                <p>Need more information or assistance in registering a trademark or service mark? Contact <a href='/pricing' style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">San Novus Trademark</a> for 
                    expert guidance tailored to your specific needs.</p>
            ]
        },
        {
            id: 5,
            tags: ["application"],
            question: "What is the difference between a TEAS Standard and a TEAS Plus application?",
            answer: [
                <p>The main difference between TEAS Standard and TEAS Plus applications lies in the descriptions used for goods and services 
                    and the associated filing fees charged by the Patent and Trademark office.</p>,
                <p><b>TEAS PLUS: </b>This option requires using pre-defined descriptions from the Trademark ID Manual. Choosing these specific 
                descriptions often accelerates the examination process and simplifies the examining attorney's work. As an incentive, the U.S. 
                Patent and Trademark Office offers a reduced filing fee of $250 per class for TEAS Plus applications, compared to the standard fee 
                of $350 per class. This option is cost-effective but requires strict adherence to the manual’s descriptions.</p>,
                <p><b>TEAS Standard: </b>If your trademark needs a more customized description — known as "free-form" — then a TEAS Standard 
                application is appropriate. This option offers more flexibility in describing your goods or services but comes with a higher filing 
                fee of $350 per class.</p>,
                <p>The choice between TEAS Standard and TEAS Plus depends on whether your goods or services can be accurately described using the 
                    manual’s pre-defined terms. Pre-defined terms can be viewed by searching the Trademark ID 
                    Manual <a href='https://idm-tmng.uspto.gov/id-master-list-public.html' target='_blank' rel='noopener noreferrer' style={{color:'blue'}}>
                        here</a>. Once you have navigated to the ID manual simply enter the word or phrase you would like to search for and click the 
                        magnifying glass icon to the right of the search bar. The ID Manual will display the results based on relevancy to the searched 
                        word or term.</p>,
                <p>Not sure which application type is right for you? Contact <b>San Novus Trademark</b> for guidance. We can help you 
                decide the best approach for your trademark application, considering both cost efficiency and the unique aspects of your brand.</p>
            ]
        },
        {
            id: 6,
            tags: ["application"],
            question: "Can San Novus Trademark help me determine an appropriate description of goods and services for my federal trademark application?",
            answer: [
                <p>Absolutely! San Novus Trademark is dedicated to helping our clients file the best quality and most cost-efficient applications 
                    possible. We work closely with you to develop descriptions that accurately and comprehensively represent your goods or services.</p>,
                <p>The process of selecting the right description is vital for ensuring robust trademark protection. While the Trademark ID Manual 
                    offers pre-defined descriptions, we recognize that these may not always suit the unique aspects of your mark. Our experienced 
                    team will leverage their knowledge in trademark prosecution to craft a description that perfectly aligns with your specific needs, 
                    ensuring optimal protection for your brand.</p>,
                <p><b>Need expert guidance on your trademark application?</b> Reach out to us for personalized assistance in developing a 
                description that best represents your goods or services, tailored to maximize the effectiveness of your trademark protection.</p>
            ]
        },
        {
            id: 7,
            tags: ["usage"],
            question: "What if my trademark application is allowed but I'm not selling my goods and/or services yet?",
            answer: [
                <p>This is not a problem. You will have six months from the date listed on the Notice of Allowance to file either the Statement of Use 
                    or an extension of time. Extensions of time are valid for six month increments, and you are allowed to request up to five extensions. 
                    This means that you will have up to three years from the date of the Notice of Allowance to submit the evidence of commercial use to the 
                    Trademark Office.</p>,
                <p><b>San Novus Trademark </b> can assist you with filing your extension of time so that you can focus on bringing your product or service 
                to market. Contact us today to get started!</p>
            ]
        },
        {
            id: 8,
            tags: ["usage"],
            question: "I've used all five of my extensions of time but I'm still not ready to submit my evidence of commercial use. What happens now?",
            answer: [
                <p>Unfortunately there are not additional options to extend the current application. You can, however, re-file your application to preserve your 
                    trademark rights. The re-filed application will be examined independently of the current application.</p>,
                <p><b>San Novus Trademark </b> can help you to quickly re-file your application so that your trademark rights are 
                    preserved. <a href='/contact-us' style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">Contact us</a> today for expert guidance with this situation!</p>
            ]
        },
        {
            id: 9,
            tags: ["usage"],
            question: "What kinds of evidence of commercial use is the U.S. Patent and Trademark Office looking for?",
            answer: [
                <p><b><u>For Goods</u></b></p>,
                <p style={{marginLeft:'25px'}}><u>Acceptable:</u></p>,
                <ul style={{marginLeft:'50px'}}>
                    <li>The mark on the product itself</li>
                    <li>The mark on product packaging</li>
                    <li>The mark on a website where consumers can order the product (must include an "Add to Cart" button or something similar)</li>
                    <li>The mark illustrated in an owner/user manual that is distributed with the product at the time of sale</li>
                    <li>The mark on a hang tag affixed to clothing articles. Please note that the mark printed on a clothing article itself will 
                        likely be considered "ornamental use" and rejected by the examining attorney.</li>
                    <li>A screenshot from a software application (software trademarks only)</li>
                </ul>,
                <p style={{marginLeft:'25px'}}><u>NOT Acceptable:</u></p>,
                <ul style={{marginLeft:'50px'}}>
                    <li>The mark on an invoice for the sale of a product</li>
                    <li>The mark on a website that merely describes the product, but where consumers cannot directly order or purchase the product</li>
                    <li>Shipping label for the product</li>
                    <li>Advertisements for the product</li>
                    <li>News article(s) about the product</li>
                    <li>The mark printed on a clothing article itself (this is considered to be "ornamental use" - not an identifying source of the 
                        trademark)</li>
                    <li>Anything related to pre-orders for a product</li>
                </ul>,
                <p style={{marginTop:'25px'}}><b><u>For Services</u></b></p>,
                <p style={{marginLeft:'25px'}}><u>Acceptable:</u></p>,
                <ul style={{marginLeft:'50px'}}>
                    <li>The mark on an advertisment for services being offered (mailers, description on a website, etc.)</li>
                    <li>The mark on brick-and-mortar store signage that shows the services being offered (road signs, billboards, 
                        banners/signage located inside or outside of physical store, etc.)</li>
                    <li>The mark on an invoice for sale of services that shows which services were sold</li>
                    <li>The mark located on a website where consumers can register or sign up for the services you are offering</li>
                    <li>The mark used on a business vehicle for advertising purposes, and that shows the services</li>
                </ul>,
                <p style={{marginLeft:'25px'}}><u>NOT Acceptable:</u></p>,
                <ul style={{marginLeft:'50px'}}>
                    <li>A PDF or computer image file that was created solely for the purpose of filing with the U.S. Patent and Trademark Office</li>
                    <li>The mark with no context given to the services being offered in connection with the mark</li>
                    <li>Recordings of radio advertisements for services (only allowed for sound marks)</li>
                </ul>,
            ]
        },
        {
            id: 10,
            tags: ["application"],
            question: "How long does it take for a trademark application to be examined and subsequently registered?",
            answer: [
                <p>There are multiple variables that can affect the prosecution time of a trademark application – the number of other 
                    applications the examining attorney is currently working on, how many applications are ahead of yours in the docket, etc. 
                    The U.S. Patent and Trademark Office strives to issue initial correspondence about new trademark applications within six months 
                    from the application filing date. This timeline, however, may be affected by current staffing at the Trademark Office or the 
                    volume of new applications currently under review. Current processing times can be viewed directly with the U.S. Patent and Trademark 
                    Office at <a href='https://www.uspto.gov/dashboard/trademarks/application-timeline.html' target="_blank" rel="noopener noreferrer" style={{color:'blue'}}>
                        https://www.uspto.gov/dashboard/trademarks/application-timeline.html</a>.</p>
            ]
        },
        {
            id: 11,
            tags: ["application"],
            question: "My federal trademark application has been filed. Now what happens?",
            answer: [
                <p>Once your trademark application is filed with the U.S. Patent and Trademark Office, it undergoes a detailed review 
                    process:</p>,
                <ul style={{marginLeft:'25px'}}>
                    <li><b>Digital File Wrapper: </b>The Trademark Office creates an online digital file for your application, allowing you to track 
                    its progress.</li>
                    <li><b>Assignment and Review: </b>An examining attorney is assigned to your application. They will thoroughly review it.</li>
                    <li><b>Examination for Conflicts: </b>The examining attorney searches for any existing trademarks or applications that might be 
                    similar to yours. They also check to ensure your application meets all formatting and description requirements.</li>
                    <li><b>Office Action (if necessary): </b>If any potential conflicts or issues are identified, the examining attorney will issue 
                    an 'Office Action', formally rejecting the application. This isn't the end! You'll have the opportunity to respond to these 
                    objections, and <b>San Novus Trademark</b> can assist in crafting a response aimed at overcoming any issues that might arise.</li>
                    <li><b>Publication for Opposition: </b>Once any objections are addressed, the application moves forward for public opposition. 
                    This is a 30-day period where others can oppose your trademark if they believe it infringes on their rights.</li>
                    <li><b>Final Steps: </b>If there’s no opposition, your trademark will either be registered directly, or a Notice of Allowance will 
                    be issued. This indicates that your mark will be registered once you provide evidence of commercial use.</li>
                </ul>,
                <p>Need assistance or have questions about the process? Contact <b>San Novus Trademark</b> for expert guidance every step of the way.</p>
            ]
        },
        {
            id: 12,
            tags: ["oar"],
            question: "How likely is it that an examining attorney will reject my trademark application?",
            answer: [
                <p>The likelihood of an examining attorney rejecting a trademark application can vary, depending on several factors:</p>,
                <ul style={{marginLeft:'25px'}}>
                    <li><b>Common Phrases and Terms: </b>If your mark contains commonly used phrases or terms, it may face more scrutiny.</li>
                    <li><b>Relevance to Goods/Services: </b>How your mark relates to the goods or services you offer is also a critical consideration.</li>
                </ul>,
                <p>At <b>San Novus Trademark</b>, we conduct a detailed search with the U.S. Trademark Office prior to filing your application. 
                This search helps us identify any directly conflicting marks. We then provide you with an informed assessment of your mark's 
                likelihood of successful registration. Our goal is to minimize the chances of rejection by ensuring your application is as strong as 
                possible right from the start.</p>,
                <p>Concerned about your trademark's registrability? Reach out to us for a customized evaluation and guidance on how best to proceed 
                    with your trademark application.</p>
            ]
        },
        {
            id: 13,
            tags: ["international"],
            question: "My trademark is registered in the U.S. Is it also covered internationally?",
            answer: [
                <p>Trademarks provide protection that is specific to each country. This means a trademark registered in the United States 
                    is protected in the U.S. but not automatically in other countries. For instance, a U.S. trademark does not grant you rights 
                    in Canada or Mexico.</p>,
                <p>If you're looking to protect your brand internationally, there are several pathways to consider, such as applying through the 
                    Madrid Protocol or filing directly in individual countries. Each option has its own processes and benefits, and the best choice 
                    can depend on your specific needs and business goals.</p>,
                <p>Planning to expand your brand globally? Reach out to <b>San Novus Trademark</b> for expert guidance on developing an effective 
                international trademark strategy. Our team can help you navigate the complexities of global trademark protection.</p>
            ]
        },
        {
            id: 14,
            tags: ["international"],
            question: "How do I obtain trademark protection outside of the U.S.?",
            answer: [
                <p>International trademark applications must be filed within six months of the U.S. filing date to claim the benefit of 
                    the original U.S. filing date. Foreign applications can technically be filed at any time, but it is best to be able to 
                    claim the earliest filing date possible. Unfortunately, there is not a singular “international trademark application” that will 
                    provide trademark coverage in every single country outside of the U.S., but international filings are available and can be 
                    completed in two ways:</p>,
                <ol style={{marginLeft:'25px'}}>
                    <li>An application filed through the Madrid Protocol; or</li>
                    <li style={{marginTop:'10px'}}>A direct filing into a foreign country.</li>
                </ol>,
                <p>The best filing strategy will depend upon your desired results. It is also important to note that not all countries participate 
                    under the Madrid Protocol, so this filing method may not be available in every situation. The major benefit of filing under the 
                    Madrid Protocol is a potential reduction of filing costs. Since our office is authorized to file international trademark 
                    applications with the World Intellectual Property Office (“WIPO”), filings completed under the Madrid Protocol would not incur 
                    attorney fees for countries where those filings can be completed. If you are seeking trademark protection in a country that does 
                    not participate under the Madrid Protocol then we would need to work with our network of associates in foreign countries to 
                    accomplish that filing, which would incur additional foreign attorney fees.</p>
            ]
        },
        {
            id: 15,
            tags: ["international"],
            question: "Can I file one international trademark application to cover all countries outside of the U.S.?",
            answer: [
                <p>No, each country has its own set of laws surrounding trademarks and trademark protection. Just like the U.S. has 
                    its own trademark filing process and laws, so do all other countries. Trademark applications must be filed individually in 
                    each country where you are seeking protection. The only exception to this is by filing into the European Union, which offers 
                    protection in all 27 member countries.</p>
            ]
        },
        {
            id: 16,
            tag: "renewal",
            question: "How long will my registered trademark be enforceable?",
            answer: [
                <p>Federal trademarks need to be renewed periodically to remain in force. If the trademark is renewed at the appropriate time, 
                    it can be maintained indefinitely. Trademarks must be renewed at the following intervals:</p>,
                <ul style={{marginLeft:'25px'}}>
                    <li>Between the fifth and sixth year from the date of registration of the trademark</li>
                    <li style={{marginTop:'10px'}}>Between the ninth and tenth year from the date of registration of the trademark</li>
                    <li style={{marginTop:'10px'}}>Every subsequent 10 years</li>
                </ul>
            ]
        },
        {
            id: 17,
            tags: ["renewal"],
            question: "How much does it cost to renew my trademark?",
            answer: [
                <p>The cost to renew your trademark primarily depends on the government filing fees, which vary for each class your 
                    trademark is registered under. These fees can change, so it's best to check the current rates on the<span>&nbsp;</span>
                    <a href='https://www.uspto.gov/learning-and-resources/fees-and-payment/uspto-fee-schedule#TM%20Post%20registration' style={{color:'blue'}}>
                        USPTO website</a>.</p>,
                <p>At <b>San Novus Trademark</b>, we pride ourselves on offering competitive pricing for trademark renewals. Our approach 
                ensures that you receive quality service at a cost-effective rate. For detailed information on our fees, please refer to our 
                fee structure.</p>,
                <p>Considering a trademark renewal and need a custom quote? Don’t hesitate to contact us. We can provide a personalized estimate 
                    based on your specific trademark renewal needs, ensuring clarity and affordability in the renewal process.</p>
            ]
        },
        {
            id: 18,
            tags: ["application"],
            question: "Can I file a trademark application before my business is officially running?",
            answer: [
                <p>Absolutely! When filing a trademark application with the U.S. Patent and Trademark Office, we will need to designate 
                    a "filing basis." The filing basis of "in use" (1A) indicates that you are currently using the mark to sell goods or services. 
                    A filing basis of "intent-to-use" (1B) indicates that you are not yet using the mark commercially, but that you intend to do so in the 
                    future. You will need to submit evidence of commercial use before the Trademark Office will complete the registration process, 
                    but you can file your application before you actually start using the mark in order to secure the rights to the mark.</p>,
            ]
        },
        {
            id: 19,
            tags: ["oar"],
            question: "What is an Office Action?",
            answer: [
                <p>An Office Action is a formal rejection by the examining attorney that is reviewing your trademark application. Trademark applications
                    can be rejected for mutliple reasons. The most common types of rejections are:
                    <ul style={{marginLeft:'25px'}}>
                        <li>Likelihood of Confusion</li>
                        <li>Merely Descriptive</li>
                        <li>Objection to Description of Goods/Services</li>
                        <li>Rejection of Specimen</li>
                        <li>Prior Pending Application</li>
                    </ul>
                </p>,
                <p><b>San Novus Trademark</b> has extensive experience responding to trademark office actions. Let us help you 
                work with the examining attorney to overcome any rejections so that your mark can register!</p>
            ]
        },
        {
            id: 20,
            tags: ["search"],
            question: "How important is it to conduct a search before filing a trademark application?",
            answer: [
                <p>Extremely important! Performing at least a Knockout Search will let you know if there are any identical, registered trademarks 
                    or pending applications on file out there that would prohibit you from registering your mark.</p>,
                <p><b>San Novus Trademark</b> has extensive experience searching for trademarks and providing registerability opinions. Contact us 
                today to get started on your search as the first step in your trademark application process!</p>
            ]
        },
        {
            id: 21,
            tags: ["search"],
            question: "While conducting a search, I found a mark that is similar to mine but is used for selling different goods than me. Can I still register my mark?",
            answer: [
                <p>Possibly. The examining attorney at the U.S. Patent and Trademark Office will conduct a search for identical or similar marks 
                    while reviewing your application. Part of this search involves looking at "coordinated classes." This means checking for 
                    similarly related goods and/or services to make sure that consumers won't confuse your mark with another already registered 
                    mark that is currently in the marketplace. It may be possible to convince the examining attorney that your mark is 
                    sufficiently different from other existing registrations that it is registerable.</p>,
                <p>The team at <b>San Novus Trademark</b> has decades of experience searching for trademarks and providing registerability opinions. Contact us 
                today to get an opinion on the registerability of your mark!</p>
            ]
        },
        {
            id: 22,
            tags: ["amazon"],
            question: "Do I need to be enrolled in the Amazon Brand Registry in order to sell my product on Amazon?",
            answer: [
                <p>No, enrollment in the Amazon Brand Registry is not a requirement to sell a product on Amazon, but it does 
                    offer several benefits related to protecting you against counterfeit sales and trademark infringement. More 
                    information can be found <a href="https://sell.amazon.com/blog/what-is-amazon-brand-registry" target="_blank" rel="noopener noreferrer" style={{color:'blue'}}>here</a>.</p>
            ]
        },
        {
            id: 23,
            tags: ["amazon"],
            question: "Is there a fee to enroll in the Amazon Brand Registry?",
            answer: [
                <p>No, there is no fee associated with enrollment in the Amazon Brand Registry. All that is required is an active seller account and either a pending 
                    trademark application or a registered trademark in the country for which you are selling goods through Amazon. However, 
                    There may be fees for additional services offered to Brand Registry users. </p>
            ]
        },
        {
            id: 24,
            tags: ["amazon"],
            question: "Does my trademark need to be fully registered before I can enroll in the Amazon Brand Registry?",
            answer: [
                <p>No, you can complete your enrollment in the Amazon Brand Registry with either a pending trademark application or 
                    a fully registered trademark.</p>
            ]
        },
        {
            id: 25,
            tags: ["amazon"],
            question: "Can San Novus Trademark complete my enrollment on the Amazon Brand Registry?",
            answer: [
                <p>Unfortunately we are not allowed to complete the application on your behalf, since it involves completion through your 
                    individual seller account. When your application to the Amazon Brand Registry is submitted, Amazon will search for your trademark 
                    directly with the United States Patent and Trademark Office. Once found, Amazon will contact the representative of record 
                    (For example, San Novus Trademark) with a verification code. In this example, San Novus Trademark would then forward the verification 
                    code to the owner of the mark. The owner (You) would then use this verification code to complete the registration. This process 
                    prevents anyone but the rightful owner from registering the mark with Amazon</p>,
                <p>San Novus Trademark closely monitors incoming correspondence from the United States Patent and Trademark Office so that we can 
                    forward any applicable notifications to you as soon as possible, usually within minutes. Our goal is to make sure that you have access to any records applicable to 
                    your case file when you need them - even after hours.</p>
            ]
        },
        {
            id: 26,
            tags: ["general", "usage"],
            question: "Can a mark cover both a good and a service at the same time, or just one or the other?",
            answer: [
                <p>A trademark registration can provide coverage for goods and services either in a single application, or in separate 
                    applications. Trademark applications are filed into classes, or categories of items, and an application can contain up to 
                    45 different classes. For example, it's possible to protect both "clothing, namely, shirts" and "dry cleaning services" in a 
                    single trademark registration. Alternatively, it's also possible to obtain separate trademark registrations for the shirts and 
                    the dry cleaning services.</p>,
                <p>Need help determining the best filing strategy for your situation? Contact <a href='/contact-us' style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">San Novus Trademark</a> to 
                    speak with one of our attorneys and develop a customized trademark plan for your needs.</p>
            ]
        },
    ];
  
  export default FAQList;