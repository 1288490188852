// Footer.js
import React from "react";
import logo from './FooterLogo.png';
import './App.css';

const Footer = () => {
  return (
    <footer /* className="footer bg-dark text-light py-4" */ className="footer">
      <div className="container">
        <div className="row align-items-center mb-3">
          {/* Footer Logo and Contact Info */}
          <div className="col-md-6 text-center text-md-start">
            <img src={logo} alt='Company Logo' className="footer-logo mb-2" />
            <p className="mb-0">Email: <a href='mailto:info@sannovustrademark.com' style={{ color: '#00AFEF' }}>info@sannovustrademark.com</a> | Phone: (866) 885-3893</p>
          </div>

          {/* Navigation Links */}
          <div className="col-md-6 text-center text-md-end">
            <ul className="list-inline mb-0">
              <li className="list-inline-item"><a href="/" className="footer-link">Home</a></li>
              <li className="list-inline-item"><a href="/services" className="footer-link">Services</a></li>
              <li className="list-inline-item"><a href="/about" className="footer-link">About</a></li>
              <li className="list-inline-item"><a href="/why-us" className="footer-link">Why Us?</a></li>
              <li className="list-inline-item"><a href="/faq" className="footer-link">FAQ</a></li>
              <li className="list-inline-item"><a href="/pricing" className="footer-link">Pricing</a></li>
            </ul>

            {/* <ul>
              <li className="list-inline-item"><a href="/refund-and-dispute-policy" className="footer-link">Refund and Dispute Policy</a></li>
            </ul>
            <ul>
              <li className="list-inline-item"><a href="/privacy-policy" className="footer-link">Privacy Policy</a></li>
            </ul>
            <ul>
              <li className="list-inline-item"><a href="/terms-of-service" className="footer-link">Terms of Service</a></li>
            </ul> */}
          </div>
          <div className='admin-items'>
            <div className="list-inline-item"><a href="/refund-and-dispute-policy" className="admin-item-link">Refund and Dispute Policy</a></div>
            <div className="list-inline-item"><a href="/privacy-policy" className="admin-item-link">Privacy Policy</a></div>
            <div className="list-inline-item"><a href="/terms-of-service" className="admin-item-link">Terms of Service</a></div>
          </div>

        </div>
      </div>
      <div className="col-md-6 text-center disclaimer-container" s>
            <div className="disclaimer">San Novus Trademark LLP is a law firm that is licensed as an Arizona Alternative Business Structure and is governed by the Arizona Rules of Professional Conduct. 
              The information contained on this site is merely information and does not constitute legal advice. Viewing the website does not create an attorney/client relationship. </div>
          </div>
    </footer>
  );
};

export default Footer;
