// HomePage.js
import React, { useState, useEffect } from "react";
import './App.css';
import { Link } from 'react-router-dom';

const RefundPolicy = () => {
    return (
        <div>
        <main>
          <div className='refund-policy'>
            <h2>Refund and Dispute Policy</h2>
            <p>At San Novus Trademark, we are committed to providing high-quality legal services for U.S. trademark prosecution and renewals. Please review our refund and dispute policy carefully 
                before engaging our services.
            </p>
            <h3>Refund Policy</h3>
            <ol>
                <li><b>Service Fees:</b> Once we begin work on a matter, all service fees are non-refundable. This includes fees related to trademark searches, application preparation, filing, and any 
                subsequent actions unless otherwise agreed in writing.</li>
                <li><b>Government Fees:</b> Fees paid to the U.S. Patent and Trademark Office (USPTO) or other governmental agencies are non-refundable under any circumstances. These fees are passed through 
                to the respective government agency and are not retained by our firm.</li>
                <li><b>Exceptional circumstances:</b> Refund requests due to exceptional circumstances (e.g., an error on our part) will be considered on a case-by-case basis. Please contact us immediately if 
                you believe a refund is warranted.</li>
            </ol>
            <h3>Dispute Resolution</h3>
            <ol>
                <li><b>Contact Us First:</b> If you are dissatisfied with our services, we encourage you to contact us directly at <a href='mailto:info@sannovustrademark.com'>info@sannovustrademark.com</a>. Most issues
                can be resolved quickly and amicably.</li>
                <li><b>Mediation:</b> If a dispute cannot be resolved informally, we may suggest mediation as an alternative method to resolve the matter.</li>
                <li><b>Jurisdiction:</b> All disputes arising out of, or in connection with, our services shall be governed by the laws of the State of Arizona.</li>
                <li><b>Chargebacks and Payment Disputes:</b> In the event of a chargeback or payment dispute, we reserve the right to suspend or terminate services until the dispute is resolved. Any costs associated with 
                resolving the dispute, including administrative fees, may be passed on to the client.</li>
            </ol>
            <h3>How to Request a Refund or Raise a Dispute</h3>
            <ol>
                <li>Submit your request or dispute in writing to <a href='mailto:info@sannovustrademark.com'>info@sannovustrademark.com</a>. Please include the following information:
                    <ul>
                        <li>Your name and contact information</li>
                        <li>The invoice or receipt number</li>
                        <li>A detailed description of your request or dispute</li>
                        <li>Supporting documentation, if applicable</li>
                    </ul>
                </li>
                <li>We will acknowledge receipt of your request or dispute within two business days and provide an initial response within five business days.</li>
                <li>Refunds, if approved, will be processed within 14 business days and returned to the original payment method.</li>
            </ol>
            <h3>Changes to This Policy</h3>
            <p>San Novus Trademark reserves the right to modify this policy at any time. Any changes will be posted on our website and become effective immediately. It is your responsibility to review this policy periodically.</p>
            <h3>Contact Us</h3>
            <p>If you have any questions about this policy, please contact us at <a href='mailto:info@sannovustrademark.com'>info@sannovustrademark.com</a>.</p>
          </div>
        </main>
      </div>
    );
  };
  
  export default RefundPolicy;